import {
    Component,
    createRef,
} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCrop.scss';
import {connect} from 'react-redux';
import {
    setColorOptions,
    setImitateCanvas,
    setCanvasDensity,
    removeColor,
    undoRemoveColor,
} from '../reducers/images';
import Spinner from './Spinner';
import {prepareCanvasImitationMask} from './draw-schema-utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faMagnifyingGlass,
    faChevronLeft,
    faChevronRight,
    faTrashCan,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';

import './SetupColors.scss';
import {
    stepBack,
    stepForward,
} from '../reducers/steps';

function getRandomElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

class SetupColors extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = createRef();
        this.canvasWrapperRef = createRef();
        const originalSetColorOptions = this.setColorOption;
        this.setColorOption = (prop, value) => {
            this.setState({
                [prop]: value,
            });
            this.timeoutRef && clearTimeout(this.timeoutRef);
            this.timeoutRef = setTimeout(originalSetColorOptions.bind(this, prop, value), 200);
        };
        this.state = {
            ...props.colorOptions,
            squareWidth: 20,
            scaleFactor: 0,
            canvasWrapperWidth: 0,
            canvasWrapperHeight: 0,
            scaleUpStep: 100,
            scaleDownStep: 100,
            wheelScaleUpStep: 20,
            wheelScaleDownStep: 30,
        };
    }
    
    async updateCanvas() {
        if (!this.canvasRef.current || !this.props.resampledImage || !this.props.squares)
            return;
        
        const canvas = this.canvasRef.current;
        
        canvas.width = this.props.squares.xLength * this.state.squareWidth;
        canvas.height = this.props.squares.yLength * this.state.squareWidth;
        const ctx = canvas.getContext('2d');
        
        const imitationMasks = this.props.imitationMasks.map(
            (mask) => prepareCanvasImitationMask(mask, this.state.squareWidth),
        );
        
        for (const {
            color,
            x,
            y,
        } of this.props.squares) {
            const [r, g, b] = color;
            ctx.fillStyle = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
            const xPx = x * this.state.squareWidth;
            const yPx = y * this.state.squareWidth;
            ctx.fillRect(xPx, yPx, this.state.squareWidth, this.state.squareWidth);
            
            if (this.props.imitateCanvas)
                ctx.drawImage(getRandomElement(imitationMasks), xPx, yPx);
        }
    }
    
    componentDidMount() {
        this.props.initSquares();
        this.updateCanvas();
        this.canvasWrapperRef.current.addEventListener('wheel', this.handleWheel.bind(this));
        this.setState({
            canvasWrapperWidth: this.canvasWrapperRef.current.getBoundingClientRect().width,
            canvasWrapperHeight: this.canvasWrapperRef.current.getBoundingClientRect().height,
        });
    }
    
    componentWillUnmount() {
        this.canvasWrapperRef.current.removeEventListener('wheel', this.handleWheel.bind(this));
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.squares !== this.props.squares || prevProps.imitateCanvas !== this.props.imitateCanvas)
            this.updateCanvas();
        
        if (prevProps.colorOptions !== this.props.colorOptions)
            this.setState(this.props.colorOptions);
    }
    
    setColorOption(prop, value) {
        this.props.setColorOptions({
            [prop]: value,
        });
        this.props.calculateSquares();
    }
    
    scaleUpCanvas() {
        if (this.canvasWrapperRef.current.clientHeight + this.state.scaleFactor < this.canvasRef.current.width) {
            this.setState({
                scaleFactor: this.state.scaleFactor + this.state.scaleUpStep,
            });
            this.canvasWrapperRef.current.scroll({
                top: this.canvasWrapperRef.current.scrollTop + this.state.scaleUpStep / 2,
                left: this.canvasWrapperRef.current.scrollLeft + this.state.scaleUpStep / 2,
            });
        } else {
            this.setState({
                scaleFactor: this.canvasRef.current.width,
            });
        }
    }
    
    scaleDownCanvas() {
        if (this.state.scaleFactor - this.state.scaleDownStep > 0) {
            this.setState({
                scaleFactor: this.state.scaleFactor - this.state.scaleDownStep,
            });
            this.canvasWrapperRef.current.scroll({
                top: this.canvasWrapperRef.current.scrollTop - this.state.scaleDownStep / 2,
                left: this.canvasWrapperRef.current.scrollLeft - this.state.scaleDownStep / 2,
            });
        } else {
            this.setState({
                scaleFactor: 0,
            });
        }
    }
    
    restoreCanvasOriginalSize() {
        this.setState({
            scaleFactor: 0,
        });
    }
    
    removeColor(color) {
        this.props.removeColor(color);
        this.props.calculateSquares();
    }
    
    undoRemoveColor(color) {
        this.props.undoRemoveColor(color);
        this.props.calculateSquares();
    }
    
    handleWheel(ev) {
        if (!ev.ctrlKey)
            return;
        
        ev.preventDefault();
        ev.stopPropagation();
        
        if (ev.deltaY < 0) {
            if (this.canvasWrapperRef.current.clientHeight + this.state.scaleFactor < this.canvasRef.current.width) {
                this.setState({
                    scaleFactor: this.state.scaleFactor + this.state.wheelScaleUpStep,
                });
                const yScrollOffset = this.state.wheelScaleUpStep * ((ev.pageY - this.canvasWrapperRef.current.offsetTop) / this.canvasWrapperRef.current.clientHeight);
                const xScrollOffset = this.state.wheelScaleUpStep * ((ev.pageX - this.canvasWrapperRef.current.offsetLeft) / this.canvasWrapperRef.current.clientWidth);
                this.canvasWrapperRef.current.scroll({
                    top: this.canvasWrapperRef.current.scrollTop + yScrollOffset,
                    left: this.canvasWrapperRef.current.scrollLeft + xScrollOffset,
                });
            } else {
                this.setState({
                    scaleFactor: this.canvasRef.current.width,
                });
            }
        } else {
            if (this.state.scaleFactor - this.state.wheelScaleDownStep > 0) {
                this.setState({
                    scaleFactor: this.state.scaleFactor - this.state.wheelScaleDownStep,
                });
                const yScrollOffset = this.state.wheelScaleDownStep * ((ev.pageY - this.canvasWrapperRef.current.offsetTop) / this.canvasWrapperRef.current.clientHeight);
                const xScrollOffset = this.state.wheelScaleDownStep * ((ev.pageX - this.canvasWrapperRef.current.offsetLeft) / this.canvasWrapperRef.current.clientWidth);
                this.canvasWrapperRef.current.scroll({
                    top: this.canvasWrapperRef.current.scrollTop - yScrollOffset,
                    left: this.canvasWrapperRef.current.scrollLeft - xScrollOffset,
                });
            } else {
                this.setState({
                    scaleFactor: 0,
                });
            }
        }
    }
    
    render() {
        const currentDensity = this.props.canvasDensities.find(({label}) => label === this.props.canvasDensity);
        
        let widthCm = 0;
        if (this.props.squares)
            widthCm = Math.round((10 * this.props.squares.xLength / currentDensity.crossesPer10cm));
        
        return (
            <div className={'setup-colors-container container-fluid'}>
                <div className={'working-area row no-gutters'}>
                    <div className={'col'}>
                        <div className={'simple-controls-wrapper'}>
                            <div className="form-group">
                                <label htmlFor="squares-x">Squares Count (Horizontally):</label>
                                <input type="number"
                                       id="squares-x"
                                       onChange={e => this.setColorOption('squaresX', e.target.value)}
                                       className="form-control"
                                       value={this.state.squaresX}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="colors-count">Colors Count:</label>
                                <input type="number"
                                       id="colors-count"
                                       onChange={e => this.setColorOption('colorsCount', e.target.value)}
                                       className="form-control"
                                       value={this.state.colorsCount}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="canvas-densities">Aida Cloth:</label>
                                <select
                                    className="form-control"
                                    id="canvas-densities"
                                    value={this.props.canvasDensity}
                                    onChange={(e) => this.props.setCanvasDensity(e.target.value)}>
                                    {this.props.canvasDensities.map(({
                                            key,
                                            label,
                                        }) =>
                                            <option key={key}>{label}</option>,
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="canvas-color-map">Thread Brand:</label>
                                <select
                                    className="form-control"
                                    id="canvas-color-map"
                                    value={this.state.threadColorMap}
                                    onChange={(e) => this.setColorOption('threadColorMap', e.target.value)}>
                                    {this.props.threadColorMaps.map((label) =>
                                        <option key={label}>{label}</option>,
                                    )}
                                </select>
                            </div>
                        </div>
                        <div style={{
                            marginTop: '20px',
                        }}>
                            <h6>
                                Colors ({this.props.colorsMap && Object.keys(this.props.colorsMap).length}):
                            </h6>
                            <table className="table position-relative">
                                <thead style={{
                                    width: '100%',
                                    display: 'inline-table',
                                    tableLayout: 'fixed',
                                }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Color</th>
                                    <th scope="col">Count</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody style={{
                                    height: '40vh',
                                    overflowY: 'auto',
                                    width: 'inherit',
                                }} className={'position-absolute'}>
                                {this.props.colorsMap && Object.entries(this.props.colorsMap)
                                    .sort(([, a], [, b]) => a.squaresCount - b.squaresCount)
                                    .map(([colorMapKey, {
                                            rgbColor: color,
                                            colorName,
                                            squaresCount,
                                            char,
                                        }], index) =>
                                            <tr key={colorName} style={{
                                                display: 'inline-table',
                                                tableLayout: 'fixed',
                                                width: '100%',
                                            }}>
                                                <th scope="row"><label>{index + 1})</label></th>
                                                <td style={{
                                                    display: 'table-cell',
                                                    verticalAlign: 'middle',
                                                }}>
                                                    <div style={{
                                                        background: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
                                                        width: '30px',
                                                        height: '30px',
                                                        margin: 'auto',
                                                    }}>
        
                                                    </div>
                                                </td>
                                                <td><label>({squaresCount})</label></td>
                                                <td>
                                                    <FontAwesomeIcon className={'btn remove-color'} onClick={this.removeColor.bind(this, color)}
                                                                     icon={faTrashCan}/>
                                                </td>
                                            </tr>,
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={'col-7 position-relative'}>
                        <div className="canvas-wrapper" ref={this.canvasWrapperRef} style={{
                            overflow: this.state.scaleFactor === 0 ? 'hidden' : 'scroll',
                        }}>
                            <canvas
                                className={'setup-colors-canvas'}
                                ref={this.canvasRef}
                                style={{
                                    maxWidth: this.state.canvasWrapperWidth + this.state.scaleFactor,
                                    maxHeight: this.state.canvasWrapperHeight + this.state.scaleFactor,
                                    aspectRatio: `${this.canvasRef.current && this.canvasRef.current.width} / ${this.canvasRef.current && this.canvasRef.current.height}`,
                                }}
                            />
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <FontAwesomeIcon className={'btn zoom-button'} onClick={this.scaleUpCanvas.bind(this)}
                                                 icon={faMagnifyingGlassPlus}/>
                                <FontAwesomeIcon className={'btn zoom-button'}
                                                 onClick={this.restoreCanvasOriginalSize.bind(this)}
                                                 icon={faMagnifyingGlass}/>
                                <FontAwesomeIcon className={'btn zoom-button'} onClick={this.scaleDownCanvas.bind(this)}
                                                 icon={faMagnifyingGlassMinus}/>
                                {
                                    widthCm > 0 && (
                                        <div className={'arrow'}>
                                            <label>{widthCm}cm</label>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {this.props.squaresImProgress && (
                            <Spinner className="spinner"/>
                        )}
                        <button type="button"
                                className="btn btn-outline-dark btn-rounded col navigate-button position-absolute bottom-0 start-0 translate-middle"
                                onClick={this.props.stepBack}>
                            <FontAwesomeIcon icon={faChevronLeft}/> Crop Image
                        </button>
                        <button type="button"
                                className="btn btn-outline-dark btn-rounded col navigate-button position-absolute bottom-0 start-100 translate-middle"
                                onClick={this.props.stepForward}>
                            Generate Schema <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                    <div className={'col'}>
                        <div className={'advanced-controls-wrapper'}>
                            <div className="form-group">
                                <label htmlFor="quantization-algorithm">Algorithm:</label>
                                <select
                                    className="form-control"
                                    id="quantization-algorithm"
                                    value={this.state.quantizationStrategy}
                                    onChange={(e) => this.setColorOption('quantizationStrategy', e.target.value)}>
                                    {this.props.quantizationStrategies.map((value) =>
                                        <option key={value}>{value}</option>,
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="dithering-method">Diher Method:</label>
                                <select
                                    className="form-control"
                                    id="dithering-method"
                                    value={this.state.ditherMethod}
                                    onChange={(e) => this.setColorOption('ditherMethod', e.target.value)}>
                                    {this.props.ditherMethods.map((value) =>
                                        <option key={value}>{value}</option>,
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="color-distance-formula">Color Perception Formula:</label>
                                <select
                                    className="form-control"
                                    id="color-distance-formula"
                                    value={this.state.colorDistanceFormula}
                                    onChange={(e) => this.setColorOption('colorDistanceFormula', e.target.value)}>
                                    {this.props.colorDistanceFormulas.map((value) =>
                                        <option key={value}>{value}</option>,
                                    )}
                                </select>
                            </div>
                            <div className="form-check form-switch imitate-canvas">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="imitate-canvas"
                                    checked={this.props.imitateCanvas}
                                    onChange={(e) => this.props.setImitateCanvas(e.target.checked)}
                                    style={{
                                        transform: 'scale(1.5)',
                                    }}
                                />
                                <label className="form-check-label" htmlFor="imitate-canvas">
                                    Imitate Canvas
                                </label>
                            </div>
                        </div>
                        <div style={{
                            marginTop: '20px',
                        }}>
                            <h6>
                                User Actions:
                            </h6>
                            <table className="table position-relative">
                                <thead style={{
                                    width: '100%',
                                    display: 'inline-table',
                                    tableLayout: 'fixed',
                                }}>
                                <tr>
                                    <th scope="col">Action</th>
                                    <th scope="col">Color</th>
                                    <th scope="col"/>
                                </tr>
                                </thead>
                                <tbody style={{
                                    height: '40vh',
                                    overflowY: 'auto',
                                    width: 'inherit',
                                }} className={'position-absolute'}>
                                {this.props.userActions && Object.entries(this.props.userActions.remove)
                                    .map(([colorMapKey, color], index) =>
                                            <tr key={colorMapKey} style={{
                                                display: 'inline-table',
                                                tableLayout: 'fixed',
                                                width: '100%',
                                            }}>
                                                <th scope="row"><label>remove</label></th>
                                                <td style={{
                                                    display: 'table-cell',
                                                    verticalAlign: 'middle',
                                                }}>
                                                    <div style={{
                                                        background: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
                                                        width: '20px',
                                                        height: '20px',
                                                        margin: 'auto',
                                                    }}>
                                                        
                                                    </div>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon className={'btn undo-user-action'} onClick={this.undoRemoveColor.bind(this, color)}
                                                                     icon={faXmark}/>
                                                </td>
                                            </tr>,
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    squares: state.images.squares,
    resampledImage: state.images.resampledImage,
    colorOptions: state.images.colorOptions,
    quantizationStrategies: state.images.quantizationStrategies,
    ditherMethods: state.images.ditherMethods,
    colorDistanceFormulas: state.images.colorDistanceFormulas,
    squaresImProgress: state.images.squaresImProgress,
    colorSchemas: state.images.colorSchemas,
    imitateCanvas: state.images.imitateCanvas,
    canvasDensity: state.images.canvasDensity,
    canvasDensities: state.images.canvasDensities,
    imitationMasks: state.images.imitationMasks,
    threadColorMaps: state.images.threadColorMaps,
    colorsMap: state.images.colorsMap,
    userActions: state.images.userActions,
});

const mapDispatchToProps = (dispatch) => {
    return {
        initSquares: () => dispatch({type: 'INIT_SQUARES'}),
        calculateSquares: () => dispatch({type: 'CALCULATE_SQUARES'}),
        setColorOptions: (options) => dispatch(setColorOptions(options)),
        setImitateCanvas: (options) => dispatch(setImitateCanvas(options)),
        setCanvasDensity: (options) => dispatch(setCanvasDensity(options)),
        stepBack: () => dispatch(stepBack()),
        stepForward: () => dispatch(stepForward()),
        removeColor: (options) => dispatch(removeColor(options)),
        undoRemoveColor: (options) => dispatch(undoRemoveColor(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupColors);
