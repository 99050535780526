import {Component} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCrop.scss';
import Spinner from './Spinner';
import {connect} from 'react-redux';
import {
    setCrop,
    setCroppedImage,
} from '../reducers/images';
import {
    stepBack,
    stepForward,
} from '../reducers/steps';

class ImageCrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cropChanged: false,
            cropInProgress: false,
        };
    }
    
    onImageLoaded(image) {
        this.setState({
            imageWidth: image.width,
            imageHeight: image.height,
        });
        this.imageRef = image;
        return false;
    };
    
    onCropComplete = (crop) => {
        this.setState({
            cropChanged: true,
        });
        this.setState({
            crop,
        });
    };
    
    async makeClientCrop(/*crop*/) {
        const crop = this.state.crop;
        
        if (!crop)
            return this.props.stepForward();
        
        this.setState({
            cropInProgress: true,
        });
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
            );
            this.props.setCroppedImage(croppedImageUrl);
        }
        this.setState({
            cropInProgress: false,
        });
        
        this.props.stepForward();
    }
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );
        
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        // reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                'image/jpeg',
                1,
            );
        });
    }
    
    resetCrop(e) {
        if (e.currentTarget !== e.target)
            return;
        
        this.setState({
            cropInProgress: false,
            cropChanged: false,
            crop: null,
        });
        this.props.setCrop(null);
    }
    
    render() {
        return (
            <div className={'image-crop-wrapper'} onClick={this.resetCrop.bind(this)}>
                <div className="sticky-lg-top">
                    <h1 onClick={this.resetCrop.bind(this)}>
                        Crop image
                    </h1>
                </div>
                <div className={'d-flex align-items-center flex-column'}
                     onClick={this.resetCrop.bind(this)}>
                    <div className={'d-flex justify-content-center'}
                         onClick={this.resetCrop.bind(this)}>
                        <div className={'h-75'}>
                            {this.props.sourceImage && (
                                <ReactCrop
                                    keepSelection={true}
                                    src={this.props.sourceImage}
                                    crop={this.props.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded.bind(this)}
                                    onComplete={this.onCropComplete.bind(this)}
                                    onChange={this.props.setCrop}
                                    style={{
                                        aspectRatio: `${this.state.imageWidth}/${this.state.imageHeight}`,
                                        maxHeight: '70vh',
                                    }}
                                />
                            )}
                            {this.state.cropInProgress && (
                                <Spinner className="spinner"/>
                            )}
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-dark btn-rounded"
                            onClick={this.makeClientCrop.bind(this)}>
                        {this.state.cropChanged ? 'Crop Image' : 'Skip Cropping'}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    crop: state.images.crop,
    sourceImage: state.images.sourceImage,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setCrop: (crop) => dispatch(setCrop(crop)),
        setCroppedImage: (image) => dispatch(setCroppedImage(image)),
        stepBack: () => dispatch(stepBack()),
        stepForward: () => dispatch(stepForward()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCrop);
