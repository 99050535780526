import {
    Component,
    createRef,
} from 'react';
import DownloadSchema from './DownloadSchema';
import {connect} from 'react-redux';
import './GenerateSchema.scss';
import {
    drawCharacterInSquare,
    drawSquare,
    drawSquareBackground,
    getSquareCoordinates,
    drawNumbers,
} from './draw-schema-utils';
import {setColoOptionChar} from '../reducers/images';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faMagnifyingGlass,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';

class GenerateSchema extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = createRef();
        this.canvasWrapperRef = createRef();
        this.state = {
            squaresSeparatorWidth: 1,
            blockSeparatorWidth: 5,
            squareWidth: 30,
            indent: 40,
            squaresPerBlock: 10,
            mode: 'color',
            scaleFactor: 1000,
            canvasWrapperWidth: 0,
            canvasWrapperHeight: 0,
            scaleUpStep: 500,
            scaleDownStep: 500,
            wheelScaleUpStep: 200,
            wheelScaleDownStep: 250,
            // mode: 'black',
        };
    }
    
    scaleUpCanvas() {
        if (this.canvasWrapperRef.current.clientHeight + this.state.scaleFactor < this.canvasRef.current.width) {
            this.setState({
                scaleFactor: this.state.scaleFactor + this.state.scaleUpStep,
            });
            this.canvasWrapperRef.current.scroll({
                top: this.canvasWrapperRef.current.scrollTop + this.state.scaleUpStep / 2,
                left: this.canvasWrapperRef.current.scrollLeft + this.state.scaleUpStep / 2,
            });
        } else {
            this.setState({
                scaleFactor: this.canvasRef.current.width,
            });
        }
    }
    
    scaleDownCanvas() {
        if (this.state.scaleFactor - this.state.scaleDownStep > 0) {
            this.setState({
                scaleFactor: this.state.scaleFactor - this.state.scaleDownStep,
            });
            this.canvasWrapperRef.current.scroll({
                top: this.canvasWrapperRef.current.scrollTop - this.state.scaleDownStep / 2,
                left: this.canvasWrapperRef.current.scrollLeft - this.state.scaleDownStep / 2,
            });
        } else {
            this.setState({
                scaleFactor: 0,
            });
        }
    }
    
    restoreCanvasOriginalSize() {
        this.setState({
            scaleFactor: 0,
        });
    }
    
    handleWheel(ev) {
        if (!ev.ctrlKey)
            return;
        
        ev.preventDefault();
        ev.stopPropagation();
        
        if (ev.deltaY < 0) {
            if (this.canvasWrapperRef.current.clientHeight + this.state.scaleFactor < this.canvasRef.current.width) {
                this.setState({
                    scaleFactor: this.state.scaleFactor + this.state.wheelScaleUpStep,
                });
                const yScrollOffset = this.state.wheelScaleUpStep * ((ev.pageY - this.canvasWrapperRef.current.offsetTop) / this.canvasWrapperRef.current.clientHeight);
                const xScrollOffset = this.state.wheelScaleUpStep * ((ev.pageX - this.canvasWrapperRef.current.offsetLeft) / this.canvasWrapperRef.current.clientWidth);
                this.canvasWrapperRef.current.scroll({
                    top: this.canvasWrapperRef.current.scrollTop + yScrollOffset,
                    left: this.canvasWrapperRef.current.scrollLeft + xScrollOffset,
                });
            } else {
                this.setState({
                    scaleFactor: this.canvasRef.current.width,
                });
            }
        } else {
            if (this.state.scaleFactor - this.state.wheelScaleDownStep > 0) {
                this.setState({
                    scaleFactor: this.state.scaleFactor - this.state.wheelScaleDownStep,
                });
                const yScrollOffset = this.state.wheelScaleDownStep * ((ev.pageY - this.canvasWrapperRef.current.offsetTop) / this.canvasWrapperRef.current.clientHeight);
                const xScrollOffset = this.state.wheelScaleDownStep * ((ev.pageX - this.canvasWrapperRef.current.offsetLeft) / this.canvasWrapperRef.current.clientWidth);
                this.canvasWrapperRef.current.scroll({
                    top: this.canvasWrapperRef.current.scrollTop - yScrollOffset,
                    left: this.canvasWrapperRef.current.scrollLeft - xScrollOffset,
                });
            } else {
                this.setState({
                    scaleFactor: 0,
                });
            }
        }
    }
    
    updateCanvas() {
        const canvas = this.canvasRef.current;
        
        if (!canvas || !this.props.squares || !this.props.colorsMap)
            return;
        
        const xSquares = this.props.squares.xLength;
        const ySquares = this.props.squares.yLength;
        const {
            squareWidth,
            blockSeparatorWidth,
            indent,
        } = this.state;
        
        if (squareWidth < 1)
            throw Error('too big image'); // todo! show something to user
        
        if (xSquares * squareWidth > 10000 || ySquares * squareWidth > 10000) {
            this.setState({
                squareWidth: squareWidth - 1,
            });
            return;
        }
        
        const {
            x: maxXCoordinate,
            y: maxYCoordinate,
        } = getSquareCoordinates(xSquares, ySquares, this.state);
        
        canvas.width = maxXCoordinate + squareWidth + blockSeparatorWidth + indent;
        canvas.height = maxYCoordinate + squareWidth + blockSeparatorWidth + indent;
        
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'black';
        ctx.fillRect(indent, indent, canvas.width - 2 * indent - squareWidth - blockSeparatorWidth, canvas.height - 2 * indent - squareWidth - blockSeparatorWidth);
        
        for (const {
            color,
            x,
            y,
        } of this.props.squares) {
            const {
                x: xCoordinate,
                y: yCoordinate,
            } = getSquareCoordinates(x, y, this.state);
            
            drawSquareBackground(ctx, color, xCoordinate, yCoordinate, squareWidth, this.state);
            drawSquare(ctx, color, xCoordinate, yCoordinate, squareWidth, this.state.mode);
            drawCharacterInSquare(ctx, color, xCoordinate, yCoordinate, this.state, this.props.colorsMap);
        }
        
        drawNumbers(ctx, xSquares, ySquares, this.state);
    }
    
    componentDidMount() {
        this.props.initSquares();
        this.updateCanvas();
        this.canvasWrapperRef.current.addEventListener('wheel', this.handleWheel.bind(this));
        this.setState({
            canvasWrapperWidth: this.canvasWrapperRef.current.getBoundingClientRect().width,
            canvasWrapperHeight: this.canvasWrapperRef.current.getBoundingClientRect().height,
        });
    }
    
    componentWillUnmount() {
        this.canvasWrapperRef.current.removeEventListener('wheel', this.handleWheel.bind(this));
    }
    
    componentDidUpdate() {
        this.updateCanvas();
    }
    
    setColorOptionChar(colorMapKey, char) {
        this.props.setColoOptionChar({
            colorMapKey,
            char,
        });
    }
    
    setMode(onlySymbols) {
        this.setState({
            mode: onlySymbols ? 'black' : 'color',
        });
    }
    
    render() {
        const usedSymbols = this.props.colorsMap ? Object.values(this.props.colorsMap).reduce((acc, {char}) => {
            acc += char;
            return acc;
        }, '') : '';
        let symbolsToChoose = usedSymbols ? this.props.symbols : '';
        if (symbolsToChoose) {
            for (const usedSymbol of usedSymbols) {
                symbolsToChoose = symbolsToChoose.replace(usedSymbol, '');
            }
        }
        return (
            <div className={'generate-schema'}>
                <div className={'schema-canvas-wrapper'} ref={this.canvasWrapperRef} style={{
                    overflow: this.state.scaleFactor === 0 ? 'hidden' : 'scroll',
                }}>
                    <canvas
                        className={'schema-canvas'}
                        ref={this.canvasRef}
                        style={{
                            maxWidth: this.state.canvasWrapperWidth + this.state.scaleFactor,
                            maxHeight: this.state.canvasWrapperHeight + this.state.scaleFactor,
                            aspectRatio: `${this.canvasRef.current && this.canvasRef.current.width} / ${this.canvasRef.current && this.canvasRef.current.height}`,
                        }}
                    />
                </div>
                <FontAwesomeIcon className={'btn zoom-button'} onClick={this.scaleUpCanvas.bind(this)}
                                 icon={faMagnifyingGlassPlus}/>
                <FontAwesomeIcon className={'btn zoom-button'} onClick={this.restoreCanvasOriginalSize.bind(this)}
                                 icon={faMagnifyingGlass}/>
                <FontAwesomeIcon className={'btn zoom-button'} onClick={this.scaleDownCanvas.bind(this)}
                                 icon={faMagnifyingGlassMinus}/>
                <div className={'all-colors'}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Color</th>
                            <th scope="col">Count</th>
                            <th scope="col">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.colorsMap && Object.entries(this.props.colorsMap).map(([colorMapKey, {
                                rgbColor: color,
                                colorName,
                                squaresCount,
                                char,
                            }], index) =>
                                <tr key={colorName}>
                                    <th scope="row"><label>{index + 1})</label></th>
                                    <td>
                                        <span
                                            style={{
                                                color: Math.sqrt(Math.pow(color[0], 2) + Math.pow(color[1], 2) + Math.pow(color[2], 2)) > 127 ? `black` : 'white',
                                            }}>
                                                <select
                                                    style={{
                                                        width: 36,
                                                        height: 30,
                                                        background: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
                                                        color: Math.sqrt(Math.pow(color[0], 2) + Math.pow(color[1], 2) + Math.pow(color[2], 2)) > 127 ? `black` : 'white',
                                                    }}
                                                    onChange={(e) => this.setColorOptionChar(colorMapKey, e.target.value)}
                                                >
                                                    <option>{char}</option>
                                                    {symbolsToChoose.split('').map((symbol) =>
                                                        <option key={symbol}>{symbol}</option>,
                                                    )}
                                                </select>
                                            </span>
                                    </td>
                                    <td><label>({squaresCount})</label></td>
                                    <td><label>{colorName}</label></td>
                                </tr>,
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="only-symbols-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="imitate-canvas"
                        checked={this.state.mode === 'black'}
                        onChange={(e) => this.setMode(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="imitate-canvas">
                        Only Symbols
                    </label>
                </div>
                <DownloadSchema
                    {...this.state}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    squares: state.images.squares,
    colorsMap: state.images.colorsMap,
    symbols: state.images.symbols,
});

const mapDispatchToProps = (dispatch) => {
    return {
        initSquares: () => dispatch({type: 'INIT_SQUARES'}),
        setColoOptionChar: (options) => dispatch(setColoOptionChar(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSchema);
