import {Component} from 'react';
import {connect} from 'react-redux';
import './DownloadSchema.scss';
import Spinner from './Spinner';
import {
    drawCharacterInSquare,
    drawSquare,
    getSquareCoordinates,
    drawNumbers,
    getRandomElement,
    prepareCanvasImitationMask,
    drawSquareBackground,
    drawPointer,
} from './draw-schema-utils';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.addVirtualFileSystem(pdfFonts);


async function getDataUrl(canvas) {
    const blob = await new Promise((res) => canvas.toBlob(res, 'image/jpeg'));
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onloadend = () => res(reader.result);
        reader.onerror = rej;
        reader.readAsDataURL(blob);
    });
}

class DownloadSchema extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfGenerationInProgress: false,
        };
    }
    
    drawPage(options) {
        const {
            maxSquaresWidth,
            maxSquaresHeight,
            totalXSquares,
            totalYSquares,
            indent,
            squareWidth,
            i,
            j,
        } = options;
        
        const {
            x: width,
            y: height,
        } = getSquareCoordinates(maxSquaresWidth, maxSquaresHeight, this.props);
        
        let xSquares = totalXSquares - i < maxSquaresWidth ? totalXSquares - i : maxSquaresWidth;
        let ySquares = totalYSquares - j < maxSquaresHeight ? totalYSquares - j : maxSquaresHeight;
        
        const canvas = document.createElement('canvas');
        canvas.width = width + indent;
        canvas.height = height + indent;
        const ctx = canvas.getContext('2d');
        
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        
        const {
            x: maxXCoordinate,
            y: maxYCoordinate,
        } = getSquareCoordinates(xSquares, ySquares, this.props);
        ctx.fillRect(indent, indent, maxXCoordinate - indent, maxYCoordinate - indent);
        
        const squares = this.props.squares.filter(({
            x,
            y,
        }) => x - i < xSquares && y - j < ySquares && x >= i && y >= j);
        
        for (const {
            color,
            x: xInput,
            y: yInput,
        } of squares) {
            let x = xInput - i;
            let y = yInput - j;
            
            let {
                x: xCoordinate,
                y: yCoordinate,
            } = getSquareCoordinates(x, y, this.props);
            
            drawSquareBackground(ctx, color, xCoordinate, yCoordinate, squareWidth, this.props);
            drawSquare(ctx, color, xCoordinate, yCoordinate, squareWidth, this.props.mode);
            drawCharacterInSquare(ctx, color, xCoordinate, yCoordinate, this.props, this.props.colorsMap);
        }
        
        drawNumbers(ctx, xSquares, ySquares, this.props, i, j, totalXSquares, totalYSquares);
        
        if (totalXSquares / 2 <= totalXSquares - i && totalXSquares / 2 >= totalXSquares - i - maxSquaresWidth) {
            const ctx = canvas.getContext('2d');
            drawPointer(ctx, totalXSquares / 2 - i, 0, 'top', this.props);
            drawPointer(ctx, totalXSquares / 2 - i, ySquares, 'bottom', this.props);
        }
        
        if (totalYSquares / 2 <= totalYSquares - j && totalYSquares / 2 >= totalYSquares - j - maxSquaresHeight) {
            const ctx = canvas.getContext('2d');
            drawPointer(ctx, 0, totalYSquares / 2 - j, 'left', this.props);
            drawPointer(ctx, xSquares, totalYSquares / 2 - j, 'right', this.props);
        }
        
        return canvas;
    }
    
    async createSchemaPages() {
        const {
            squareWidth,
            indent,
        } = this.props;
        
        const totalXSquares = this.props.squares.xLength;
        const totalYSquares = this.props.squares.yLength;
        const pages = [];
        let maxSquaresWidth = 100;
        let maxSquaresHeight = 140;
        
        const extraSquaresLeftX = totalXSquares % maxSquaresWidth;
        const extraSquaresLeftY = totalYSquares % maxSquaresHeight;
        if ((extraSquaresLeftX <= 10 && extraSquaresLeftX !== 0) || (extraSquaresLeftY <= 10 && extraSquaresLeftY !== 0)) {
            if ((totalXSquares % 90 > 10 || totalXSquares % 90 === 0) && (totalYSquares % 130 > 10 || totalYSquares % 130 === 0)) {
                maxSquaresWidth = 90;
                maxSquaresHeight = 130;
            }
            if ((totalXSquares % 110 > 10 || totalXSquares % 110 === 0) && (totalYSquares % 150 > 10 || totalYSquares % 150 === 0)) {
                maxSquaresWidth = 110;
                maxSquaresHeight = 150;
            }
        }
        
        for (let i = 0; i < totalXSquares; i = i + maxSquaresWidth) {
            for (let j = 0; j < totalYSquares; j = j + maxSquaresHeight) {
                const canvas = this.drawPage({
                    maxSquaresWidth,
                    maxSquaresHeight,
                    totalXSquares,
                    totalYSquares,
                    indent,
                    squareWidth,
                    i,
                    j,
                });
                
                pages.push(async () => ({
                    image: await getDataUrl(canvas),
                    // canvas.toDataURL('image/jpeg'),
                    width: 595 - 20,
                    fit: [
                        595 - 20,
                        842 - 20,
                    ],
                    style: {
                        alignment: 'center',
                    },
                }));
            }
        }
        
        return Promise.all(pages.map((p) => p()));
    }
    
    async createPreviewPage() {
        const width = this.props.squares.xLength * this.props.squareWidth;
        const height = this.props.squares.yLength * this.props.squareWidth;
        
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        const imitationMasks = this.props.imitationMasks.map(
            (mask) => prepareCanvasImitationMask(mask, this.props.squareWidth),
        );
        
        for (const {
            color,
            x,
            y,
        } of this.props.squares) {
            const [r, g, b] = color;
            ctx.fillStyle = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
            const xPx = x * this.props.squareWidth;
            const yPx = y * this.props.squareWidth;
            ctx.fillRect(xPx, yPx, this.props.squareWidth, this.props.squareWidth);
            ctx.drawImage(getRandomElement(imitationMasks), xPx, yPx);
        }
        
        const landscape = canvas.width > canvas.height;
        
        return {
            image: await getDataUrl(canvas),
            width: landscape ? 842 - 20 : 595 - 20,
            landscape,
            fit: [
                landscape ? 842 - 20 : 595 - 20,
                landscape ? 595 - 20 : 842 - 20,
            ],
            style: {
                alignment: 'center',
            },
        };
    }
    
    async createFlossPage() {
        const canvas = document.createElement('canvas');
        const {colorsMap, threadColorMap} = this.props;
        const squareSize = 70;
        canvas.width = squareSize;
        canvas.height = squareSize;
        const ctx = canvas.getContext('2d');
        const rows = [];
        for (const color of Object.values(colorsMap)) {
            drawSquare(ctx, color.rgbColor, 0, 0, squareSize, 'color');
            drawCharacterInSquare(ctx, color.rgbColor, 0, 0, {
                squareWidth: squareSize,
                mode: 'color',
            }, this.props.colorsMap);
            rows.push([
                {
                    image: await getDataUrl(canvas),
                    width: 20,
                },
                `(${color.squaresCount})`,
                color[`${threadColorMap}Number`],
                color.colorName,
            ]);
        }
        
        return {
            table: {
                headerRows: 1,
                body: [
                    ['Cross', 'Crosses Count', `${threadColorMap.toUpperCase()} Number`, `Color Name`],
                    ...rows,
                ],
            },
            fit: [
                595 - 20,
                842 - 20,
            ],
        };
    }
    
    async buildAndDownloadSchema() {
        if (this.state.pdfGenerationInProgress)
            return;
        
        // pdf specifications:
        // 595 x 842 pt
        this.setState({
            pdfGenerationInProgress: true,
        });
        
        setTimeout(async () => { // todo wtf?
            console.time('all');
            const [
                preview,
                pages,
                flosses,
            ] = await Promise.all([
                this.createPreviewPage(),
                this.createSchemaPages(),
                this.createFlossPage(),
            ]);
            console.timeEnd('all');
            
            const docDefinition = {
                pageSize: 'A4',
                pageOrientation: preview.landscape ? 'landscape' : 'portrait',
                pageMargins: [10, 10, 10, 10],
                content: [
                    preview,
                    {
                        ...pages[0],
                        ...preview.landscape && {
                            pageOrientation: 'portrait',
                            pageBreak: 'before',
                        },
                    },
                    ...pages.slice(1),
                    {
                        ...flosses,
                        pageBreak: 'before',
                    },
                ],
            };
            const pdf = pdfMake.createPdf(docDefinition);
            pdf.open();
            
            this.setState({
                pdfGenerationInProgress: false,
            });
        }, 15);
    }
    
    render() {
        return (
            <div>
                <button onClick={this.buildAndDownloadSchema.bind(this)}
                        className={'download-schema btn btn-secondary'}>
                    Download Schema
                </button>
                {this.state.pdfGenerationInProgress && (
                    <div className={'spinner-wrapper'}>
                        <Spinner className="spinner"/>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    squares: state.images.squares,
    colorsMap: state.images.colorsMap,
    threadColorMap: state.images.colorOptions.threadColorMap,
    imitationMasks: state.images.imitationMasks,
});

export default connect(mapStateToProps)(DownloadSchema);
