import {
    Component,
    createRef,
} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCrop.scss';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import './ApplyFilters.scss';
import {
    stepBack,
    stepForward,
} from '../reducers/steps';
import {
    setFilteredImage,
    setImageFilter,
} from '../reducers/images';


class ApplyFilters extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = createRef();
        this.canvasWrapperRef = createRef();
        this.state = {};
    }
    
    async getCanvasUrl(canvas) {
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        // reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = 'newFile.jpeg';
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                'image/jpeg',
                1,
            );
        });
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.currentImageFilter !== this.props.currentImageFilter)
            this.updateCanvas();
    }
    
    async updateCanvas() {
        if (!this.canvasRef.current || !this.props.croppedImage)
            return;
    
        this.setState({
            imageWidth: this.canvasWrapperRef.width,
            imageHeight: this.canvasWrapperRef.height,
        });
        
        const canvas = this.canvasRef.current;
        
        const image = new Image();
        
        image.onload = async () => {
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.filter = this.props.currentImageFilter;
            
            ctx.drawImage(image, 0, 0);
            
            const url = await this.getCanvasUrl(canvas);
            this.props.setFilteredImage(url);
        };
        image.src = this.props.croppedImage;
    }
    
    setImageFilter(value) {
        this.props.setImageFilter(value);
        this.updateCanvas();
    }
    
    componentDidMount() {
        this.updateCanvas();
    }
    
    render() {
        return (
            <div className={'setup-colors-container container-fluid'}>
                <div className="sticky-lg-top">
                    <h1>
                        Apply Filters
                    </h1>
                </div>
                <div className={'working-area row no-gutters'}>
                    <div className={'col'}>
                    </div>
                    <div className={'col-7 position-relative'}>
                        <canvas
                            className={'setup-colors-canvas apply-filter-canvas-wrapper'}
                            ref={this.canvasRef}
                            style={{
                                aspectRatio: `${this.state.imageWidth}/${this.state.imageHeight}`,
                                maxHeight: '70vh',
                            }}
                        />
                        {this.props.squaresImProgress && (
                            <Spinner className="spinner"/>
                        )}
                        <div className={'navigation'}>
                            <button type="button"
                                    className="btn btn-outline-dark btn-rounded col navigate-button"
                                    onClick={this.props.stepBack}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Crop Image
                            </button>
                            <button type="button"
                                    className="btn btn-outline-dark btn-rounded col navigate-button"
                                    onClick={this.props.stepForward}>
                                Generate Schema <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        </div>
                    </div>
                    <div className={'col'}>
                        <div className={'advanced-controls-wrapper'}>
                            <div className="form-group">
                                <label htmlFor="image-filter">Image Filter:</label>
                                <select
                                    className="form-control"
                                    id="image-filter"
                                    value={this.props.currentImageFilter}
                                    onChange={(e) => this.setImageFilter(e.target.value)}>
                                    {this.props.imageFilters.map((value) =>
                                        <option key={value}>{value}</option>,
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    croppedImage: state.images.croppedImage,
    imageFilters: state.images.imageFilters,
    currentImageFilter: state.images.currentImageFilter,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setFilteredImage: (image) => dispatch(setFilteredImage(image)),
        setImageFilter: (value) => dispatch(setImageFilter(value)),
        stepBack: () => dispatch(stepBack()),
        stepForward: () => dispatch(stepForward()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyFilters);
