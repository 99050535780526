import {
    createSlice,
    current,
} from '@reduxjs/toolkit';

import {pick} from 'ramda';

const needRecalculateSquares = (type) => [
    'images/setColorOptions',
    'images/undoRemoveColor',
    'images/removeColor',
].includes(type);

export const stepsSlice = createSlice({
    name: 'history',
    initialState: {
        history: [],
        currentIndex: null,
    },
    reducers: {
        setAction: (state, {payload}) => {
            const {action} = payload;
            const {type} = action;
            
            if (type === 'steps/setStep')
                state.history.push({
                    current: {
                        type: 'steps/setStep',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'steps/setStep',
                        payload: payload.currentState?.steps?.activeStep,
                    },
                });
            
            if (type === 'steps/stepForward')
                state.history.push({
                    current: {
                        type: 'steps/stepForward',
                    },
                    previous: {
                        type: 'steps/stepBack',
                    },
                });
            
            if (type === 'steps/stepBack')
                state.history.push({
                    current: {
                        type: 'steps/stepBack',
                    },
                    previous: {
                        type: 'steps/stepForward',
                    },
                });
            
            if (type === 'images/setColorOptions')
                state.history.push({
                    current: {
                        type: 'images/setColorOptions',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'images/setColorOptions',
                        payload: pick(Object.keys(action.payload), payload.currentState?.images?.colorOptions),
                    },
                });
            
            if (type === 'images/setImitateCanvas')
                state.history.push({
                    current: {
                        type: 'images/setImitateCanvas',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'images/setImitateCanvas',
                        payload: payload.currentState?.images?.imitateCanvas,
                    },
                });
            
            if (type === 'images/setCanvasDensity')
                state.history.push({
                    current: {
                        type: 'images/setCanvasDensity',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'images/setCanvasDensity',
                        payload: payload.currentState?.images?.canvasDensity,
                    },
                });
            
            if (type === 'images/removeColor')
                state.history.push({
                    current: {
                        type: 'images/removeColor',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'images/undoRemoveColor',
                        payload: action.payload,
                    },
                });
            
            if (type === 'images/undoRemoveColor')
                state.history.push({
                    current: {
                        type: 'images/undoRemoveColor',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'images/removeColor',
                        payload: action.payload,
                    },
                });
            
            if (type === 'images/setColoOptionChar')
                state.history.push({
                    current: {
                        type: 'images/setColoOptionChar',
                        payload: action.payload,
                    },
                    previous: {
                        type: 'images/setColoOptionChar',
                        payload: {
                            char: payload.currentState?.images?.colorsMap[action.payload.colorMapKey].char,
                            colorMapKey: action.payload.colorMapKey,
                        },
                    },
                });
            
            state.currentIndex = state.history.length - 1;
        },
        historyBack: (state, action) => {
            if (state.currentIndex < 0)
                return;
            
            action.asyncDispatch({
                ...current(state).history[state.currentIndex].previous,
                history: true,
            });
            
            if (needRecalculateSquares(state.history[state.currentIndex].previous.type))
                action.asyncDispatch({
                    type: 'CALCULATE_SQUARES',
                    history: true,
                });
            
            state.currentIndex -= 1;
        },
        historyForward: (state, action) => {
            if (state.currentIndex === state.history.length - 1)
                return;
            
            action.asyncDispatch({
                ...current(state).history[state.currentIndex + 1].current,
                history: true,
            });
            
            if (needRecalculateSquares(state.history[state.currentIndex + 1].current.type))
                action.asyncDispatch({
                    type: 'CALCULATE_SQUARES',
                    history: true,
                });
            
            state.currentIndex += 1;
        },
    },
});

export const {
    setAction,
    historyBack,
    historyForward,
} = stepsSlice.actions;

export default stepsSlice.reducer;
