import './App.scss';
import ImageLoader from './components/ImageLoader';
import ImageCrop from './components/ImageCrop';
import Stepper from './components/Stepper';
import SetupColors from './components/SetupColors';
import GenerateSchema from './components/GenerateSchema';
import ApplyFilters from './components/ApplyFilters';
import {Component} from 'react';
import {connect} from 'react-redux';
import {
    historyBack,
    historyForward,
} from './reducers/history';

class App extends Component {
    constructor(...args) {
        super(...args);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    
    handleKeyPress(event) {
        if (event.shiftKey && event.ctrlKey && event.code === 'KeyZ')
            return this.props.historyForward();
        
        if (event.ctrlKey && event.code === 'KeyZ')
            return this.props.historyBack();
    }
    
    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress);
    }
    
    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress);
    }
    
    render() {
        const {
            props: {
                activeStep,
            },
        } = this;
        
        const stepComponents = {
            loadImage: <ImageLoader/>,
            cropImage: <ImageCrop/>,
            setupColors: <SetupColors/>,
            generateSchema: <GenerateSchema/>,
            applyFilters: <ApplyFilters/>,
        };
        
        return (
            <div className="App">
                {stepComponents[activeStep] || stepComponents.loadImage}
                <Stepper/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activeStep: state.steps.activeStep,
});

const mapDispatchToProps = (dispatch) => {
    return {
        historyBack: () => dispatch(historyBack()),
        historyForward: () => dispatch(historyForward()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
