import hermite from './hermite';

async function reSampleImage(options) {
    const {
        canvas,
        height,
        width,
        resize = true,
        strategy = 'hermite',
    } = options;
    
    if (strategy === 'hermite')
        return await hermite(canvas, width, height, resize);
    
    throw Error(`strategy: "${strategy}" is not supported`);
}

export default reSampleImage;
