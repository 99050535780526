import {createSlice} from '@reduxjs/toolkit';

export const imagesSlice = createSlice({
    name: 'images',
    initialState: {
        userActions: {
            remove: {},
        },
        sourceImage: null,
        croppedImage: null,
        resampledImage: null,
        colorsMap: null,
        imitationMasks: [],
        reCrop: false,
        reFilter: false,
        canvasDensity: '14ct (Aida 54)',
        imitateCanvas: false,
        threadColorMaps: [
            'dmc',
            'anchor',
            'madeira',
            'gamma',
            'kirova',
            'mayFlower',
            'dimensions',
            'dome',
            'belka',
            'bucilla',
        ],
        colorOptions: {
            colorsCount: 19,
            squaresX: 200,
            dither: false,
            ditherMode: 4,
            ditherMethod: 'No',
            quantizationStrategy: 'k-means',
            colorDistanceFormula: 'color-metric',
            colorSchema: 'color',
            threadColorMap: 'dmc',
        },
        squaresImProgress: false,
        crop: {
            unit: '%',
        },
        quantizationStrategies: [
            'k-means',
            'neuquant',
            'neuquant-float',
            'wuquant',
            'rgbquant',
            'median-cut',
            'atcq',
            'naive',
        ],
        ditherMethods: [
            'No',
            'Bayer',
            'FloydSteinberg',
            'FalseFloydSteinberg',
            'Stucki',
            'Atkinson',
            'Jarvis',
            'Burkes',
            'Sierra',
            'TwoSierra',
            'SierraLite',
            'StevensonArce',
            'ShiauFan',
            'Chapel',
        ],
        colorDistanceFormulas: [
            'pngquant',
            'manhattan',
            'manhattan-bt709',
            'manhattan-nommyde',
            'color-metric',
            'euclidean',
            'euclidean-bt709-noalpha',
            'euclidean-bt709',
            // todo! next methods are too slow even to show them to users
            'cie94-textiles',
            'cie94-graphic-arts',
            'ciede2000',
        ],
        imageFilters: [
            'none',
            'sepia()',
            'grayscale(100%)',
            'hue-rotate(40deg)',
            'invert(100%)',
            'opacity(50%)',
            'blur(3px)',
            'blur(4px)',
            'blur(5px)',
            'blur(10px)',
            'brightness(50%)',
            'brightness(150%)',
            'contrast(50%)',
            'contrast(150%)',
        ],
        currentImageFilter: 'none',
        colorSchemas: [
            'color',
            'shadesOfGray',
        ],
        canvasDensities: [{
            key: 10,
            label: '10ct (Aida 40)',
            crossesPer10cm: 40,
            numberOfThreads: 6,
            needleSize: 22,
        }, {
            key: 11,
            label: '11ct (Aida 44)',
            crossesPer10cm: 44,
            numberOfThreads: 6,
            needleSize: [22, 24],
        }, {
            key: 14,
            label: '14ct (Aida 54)',
            crossesPer10cm: 54,
            numberOfThreads: 3,
            needleSize: 24,
        }, {
            key: 16,
            label: '16ct (Aida 64)',
            crossesPer10cm: 64,
            numberOfThreads: 2,
            needleSize: 26,
        }, {
            key: 18,
            label: '18ct (Aida 70)',
            crossesPer10cm: 70,
            numberOfThreads: 1,
            needleSize: 28,
        }],
        symbols: '☊☌☈⋈⊿⊘⊗∞⌖☾⚘⚭1♪2♦3☀4☂5★6☁7×8½9+✓0ø¬♥¼ã♣¾■*@a#♠b%c?de-fAgBhCiDjEkFlGmHnIoJpKqLrMsNtOuPvQwRxSyTzUVWXYZ',
    },
    reducers: {
        setSourceImage: (state, action) => {
            state.sourceImage = action.payload;
            state.croppedImage = action.payload;
            state.reCrop = true;
            state.reFilter = true;
            state.crop = {
                unit: '%',
            };
        },
        setCroppedImage: (state, action) => {
            state.croppedImage = action.payload;
            state.reCrop = true;
        },
        setResampledImage: (state, action) => {
            state.resampledImage = action.payload;
        },
        setFilteredImage: (state, action) => {
            state.filteredImage = action.payload;
            state.reFilter = true;
        },
        setImageFilter: (state, action) => {
            state.currentImageFilter = action.payload;
        },
        setCrop: (state, action) => {
            if (action.payload === null) {
                state.crop = {
                    unit: '%',
                };
                state.croppedImage = state.sourceImage;
                return;
            }
            
            state.crop = {
                ...state.crop,
                ...action.payload,
            };
        },
        setSquares: (state, action) => {
            state.squares = action.payload;
            state.reCrop = false;
            state.reFilter = false;
        },
        setColorsMap: (state, action) => {
            state.colorsMap = action.payload;
            for (const [index, color] of Object.entries(Object.values(state.colorsMap))) {
                color.char = state.symbols[index];
            }
        },
        setColorOptions: (state, action) => {
            state.colorOptions = {
                ...state.colorOptions,
                ...action.payload,
                dither: typeof action.payload.ditherMethod === 'undefined' ? state.colorOptions.dither : action.payload.ditherMethod !== 'No',
            };
        },
        setSquaresInProgress: (state, action) => {
            state.squaresImProgress = action.payload;
        },
        setImitateCanvas: (state, action) => {
            state.imitateCanvas = action.payload;
        },
        setCanvasDensity: (state, action) => {
            state.canvasDensity = action.payload;
        },
        setCanvasImitationMasks: (state, action) => {
            state.imitationMasks = action.payload;
        },
        setColoOptionChar: (state, action) => {
            const {
                colorMapKey,
                char,
            } = action.payload;
            state.colorsMap[colorMapKey].char = char;
        },
        removeColor: (state, action) => {
            const [x, y, z] = action.payload;
            state.userActions.remove[`${x},${y},${z}`] = action.payload;
        },
        undoRemoveColor: (state, action) => {
            const [x, y, z] = action.payload;
            delete state.userActions.remove[`${x},${y},${z}`];
        },
    },
});

export const {
    setSourceImage,
    setCroppedImage,
    setResampledImage,
    setCrop,
    setColorOptions,
    setSquares,
    setColorsMap,
    setSquaresInProgress,
    setImitateCanvas,
    setCanvasDensity,
    setCanvasImitationMasks,
    setColoOptionChar,
    removeColor,
    undoRemoveColor,
    setFilteredImage,
    setImageFilter,
} = imagesSlice.actions;

export default imagesSlice.reducer;
