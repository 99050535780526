import {toLabel} from '../features/palette';

const getColorBrightness = ([r, g, b]) => {
    return Math.sqrt(Math.pow(r, 2) + Math.pow(g, 2) + Math.pow(b, 2));
};

function drawCharacterInSquare(ctx, color, x, y, options, colorsMap) {
    const {
        squareWidth,
        mode,
    } = options;
    
    const colorBrightness = getColorBrightness(color);
    if (mode === 'color') {
        ctx.fillStyle = colorBrightness > 127 ? `black` : 'white';
    } else {
        ctx.fillStyle = 'black';
    }
    ctx.font = `${squareWidth * 4 / 5}px serif`;
    const char = colorsMap[toLabel(color)].char;
    ctx.fillText(char, x + squareWidth / 5, y + squareWidth * 4 / 5, squareWidth * 3 / 5);
}

function drawSquareBackground(ctx, color, x, y, squareWidth, options) {
    const {squaresSeparatorWidth} = options;
    
    const colorBrightness = getColorBrightness(color);
    if (colorBrightness > 127)
        return;
    
    ctx.fillStyle = 'white';
    ctx.fillRect(x - squaresSeparatorWidth, y - squaresSeparatorWidth, squareWidth + 2 * squaresSeparatorWidth, squareWidth + 2 * squaresSeparatorWidth);
}

function drawSquare(ctx, color, x, y, squareWidth, mode) {
    const [r, g, b] = color;
    if (mode === 'color') {
        ctx.fillStyle = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
    } else {
        ctx.fillStyle = 'white';
    }
    ctx.fillRect(x, y, squareWidth, squareWidth);
}

function drawSquareBorders(ctx, color, xCoordinate, yCoordinate, x, y, xSquares, ySquares, options) {
    const {
        squaresSeparatorWidth,
        blockSeparatorWidth,
        squareWidth,
        squaresPerBlock,
        mode,
    } = options;
    
    const colorBrightness = getColorBrightness(color);
    
    const fillColor = colorBrightness > 127 ? `black` : 'white';
    if (mode === 'color') {
        ctx.fillStyle = fillColor;
    } else {
        ctx.fillStyle = 'black';
    }
    
    // top line
    if (y === 0) {
        const isBlockStart = y % squaresPerBlock === 0;
        let separatorWidth = isBlockStart ? blockSeparatorWidth : squaresSeparatorWidth;
        if (isBlockStart)
            ctx.fillStyle = 'black';
        
        ctx.fillRect(
            xCoordinate - separatorWidth,
            yCoordinate - separatorWidth,
            squareWidth + 2 * separatorWidth,
            separatorWidth,
        );
    }
    
    // left line
    if (x === 0) {
        const isBlockStart = x % squaresPerBlock === 0;
        let separatorWidth = isBlockStart ? blockSeparatorWidth : squaresSeparatorWidth;
        if (isBlockStart) {
            ctx.fillStyle = 'black';
        } else {
            ctx.fillStyle = fillColor;
        }
        
        ctx.fillRect(
            xCoordinate - separatorWidth,
            yCoordinate - separatorWidth,
            separatorWidth,
            squareWidth + 2 * separatorWidth,
        );
    }
    
    // right line
    const isXBlockEnd = x % squaresPerBlock === (squaresPerBlock - 1) || x + 1 === xSquares;
    let xSeparatorWidth = isXBlockEnd ? blockSeparatorWidth : squaresSeparatorWidth;
    if (isXBlockEnd) {
        ctx.fillStyle = 'black';
    } else {
        ctx.fillStyle = fillColor;
    }
    
    ctx.fillRect(
        xCoordinate + squareWidth,
        yCoordinate,
        xSeparatorWidth,
        squareWidth,
    );
    
    // bottom line
    const isYBlockEnd = y % squaresPerBlock === (squaresPerBlock - 1) || y + 1 === ySquares;
    let ySeparatorWidth = isYBlockEnd ? blockSeparatorWidth : squaresSeparatorWidth;
    if (isYBlockEnd) {
        ctx.fillStyle = 'black';
    } else {
        ctx.fillStyle = fillColor;
    }
    
    ctx.fillRect(
        xCoordinate,
        yCoordinate + squareWidth,
        squareWidth + xSeparatorWidth,
        ySeparatorWidth,
    );
    
    // crutch to avoid redundant white line in the block separator
    if (isXBlockEnd && !isYBlockEnd && x + 1 !== xSquares) {
        ctx.fillStyle = 'black';
        ctx.fillRect(
            xCoordinate + squareWidth,
            yCoordinate + squareWidth,
            squareWidth + xSeparatorWidth + ySeparatorWidth,
            ySeparatorWidth,
        );
    }
}

function getSquareCoordinates(x, y, options) {
    const {
        squaresPerBlock,
        squaresSeparatorWidth,
        blockSeparatorWidth,
        indent,
        squareWidth,
    } = options;
    
    const linesBetweenXSquaresBefore = x - Math.floor(x / squaresPerBlock);
    const linesBetweenXBlocksBefore = Math.floor(x / squaresPerBlock) + 1;
    const xShift = indent + linesBetweenXSquaresBefore * squaresSeparatorWidth + linesBetweenXBlocksBefore * blockSeparatorWidth;
    const linesBetweenYSquaresBefore = y - Math.floor(y / squaresPerBlock);
    const linesBetweenYBlocksBefore = Math.floor(y / squaresPerBlock) + 1;
    const yShift = indent + linesBetweenYSquaresBefore * squaresSeparatorWidth + linesBetweenYBlocksBefore * blockSeparatorWidth;
    
    return {
        x: x * squareWidth + xShift,
        y: y * squareWidth + yShift,
    };
}

function drawNumbers(ctx, xSquares, ySquares, options, xShift = 0, yShift = 0, totalXSquares, totalYSquares) {
    const {
        squaresPerBlock,
        squareWidth,
        indent,
    } = options;
    
    const middleX = totalXSquares / 2;
    const middleY = totalYSquares / 2;
    
    ctx.fillStyle = 'black';
    for (let i = 1; i <= Math.floor(xSquares / squaresPerBlock); i++) {
        if (totalXSquares && Math.abs(i * squaresPerBlock + xShift - middleX) < 1) {
            continue;
        }
        
        ctx.fillText((i * squaresPerBlock + xShift).toString(), getSquareCoordinates(i * squaresPerBlock, 0, options).x - squareWidth / 2, indent - 10, squareWidth);
        
        if (totalYSquares)
            ctx.fillText((i * squaresPerBlock + xShift).toString(), getSquareCoordinates(i * squaresPerBlock, 0, options).x - squareWidth / 2, getSquareCoordinates(0, ySquares, options).y + indent - 10, squareWidth);
    }
    for (let i = 1; i <= Math.floor(ySquares / squaresPerBlock); i++) {
        if (totalYSquares && Math.abs(i * squaresPerBlock + yShift - middleY) < 1) {
            continue;
        }
        
        ctx.fillText((i * squaresPerBlock + yShift).toString(), 0, getSquareCoordinates(0, i * squaresPerBlock, options).y + squareWidth / 5, squareWidth);
        
        if (totalXSquares)
            ctx.fillText((i * squaresPerBlock + yShift).toString(), getSquareCoordinates(xSquares, 0, options).x + 10, getSquareCoordinates(0, i * squaresPerBlock, options).y + squareWidth / 5, squareWidth);
    }
}

function getRandomElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

function prepareCanvasImitationMask(mask, size) {
    if (!mask)
        return;
    
    const newCanvas = document.createElement('canvas');
    newCanvas.width = size;
    newCanvas.height = size;
    const ctx = newCanvas.getContext('2d');
    ctx.drawImage(mask, 0, 0, size, size); // Or at whatever offset you like
    return newCanvas;
}

function drawPointer(ctx, xSquares, ySquares, type, options) {
    const arrowSize = 30;
    const {
        x,
        y,
    } = getSquareCoordinates(xSquares, ySquares, options);
    ctx.fillStyle = 'black';
    
    if (type === 'top') {
        ctx.moveTo(x - arrowSize, 0);
        ctx.lineTo(x + arrowSize, 0);
        ctx.lineTo(x, arrowSize);
    }
    
    if (type === 'left') {
        ctx.moveTo(0, y - arrowSize);
        ctx.lineTo(0, y + arrowSize);
        ctx.lineTo(arrowSize, y);
    }
    
    if (type === 'bottom') {
        ctx.moveTo(x - arrowSize, y + arrowSize + 10);
        ctx.lineTo(x + arrowSize, y + arrowSize + 10);
        ctx.lineTo(x, y + 10);
    }
    
    if (type === 'right') {
        ctx.moveTo(x + arrowSize + 10, y - arrowSize);
        ctx.lineTo(x + arrowSize + 10, y + arrowSize);
        ctx.lineTo(x + 10, y);
    }
    
    ctx.fill();
}

export {
    drawSquareBorders,
    drawSquare,
    drawCharacterInSquare,
    getSquareCoordinates,
    drawNumbers,
    getRandomElement,
    prepareCanvasImitationMask,
    drawSquareBackground,
    drawPointer,
};