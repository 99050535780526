import {Component} from 'react';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import './ImageLoader.scss';
import ArrowButton from './ArrowButton';
import {setSourceImage} from '../reducers/images';
import {
    setStep,
    stepForward,
} from '../reducers/steps';

class ImageLoader extends Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    }
    
    onDrop(acceptedFiles) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setSourceImage(reader.result),
        );
        reader.readAsDataURL(acceptedFiles[0]);
    };
    
    setSourceImage = (image) => {
        this.props.setSourceImage(image);
        this.props.setStep('cropImage');
    };
    
    render() {
        return (
            <div>
                <Dropzone
                    onDrop={this.onDrop}
                    maxFiles={1}
                    className="dropzone"
                    multiple={false}
                    autoFocus={true}
                    accept="image/png,image/jpeg,image/gif,image/jpg"
                >
                    {({
                        getRootProps,
                        getInputProps,
                        acceptedFiles,
                    }) => (
                        <section>
                            <div {...getRootProps()} className={'drop-zone'}>
                                <input {...getInputProps()} />
                                <div className={'load-description'}>
                                    {acceptedFiles.length ? 'Replace current image' : 'Drag and drop your images here.'}
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.props.sourceImage && <ArrowButton type="right" click={this.props.stepForward}/>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sourceImage: state.images.sourceImage,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setSourceImage: (image) => dispatch(setSourceImage(image)),
        stepForward: () => dispatch(stepForward()),
        setStep: (step) => dispatch(setStep(step)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageLoader);
