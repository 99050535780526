// color map from here: https://stitchart.net/en/tablica-perevoda-nitok-muline/
// useful regex for parsing: ^(?:\w+|-)((?:,\s+(?:\w+|-))?){10}

const allColors = [{
    dmcNumber: '3713',
    anchorNumber: '1020, 1021',
    colorName: 'Salmon Very Light',
    madeiraNumber: '502',
    gammaNumber: '3010',
    kirovaNumber: '1002, 23',
    dimensionsNumber: '13067',
    domeNumber: '177',
    belkaNumber: '961',
    rgbColor: [255, 226, 226],
    hexColor: 'ffe2e2',
}, {
    dmcNumber: '761',
    anchorNumber: '24, 1021',
    colorName: 'Salmon Light',
    madeiraNumber: '404',
    gammaNumber: '3011',
    kirovaNumber: '5602',
    dimensionsNumber: '13068',
    domeNumber: '176',
    belkaNumber: '583',
    rgbColor: [255, 201, 201],
    hexColor: 'ffc9c9',
}, {
    dmcNumber: '760',
    anchorNumber: '1022',
    colorName: 'Salmon',
    madeiraNumber: '405',
    gammaNumber: '3012',
    kirovaNumber: '5604',
    dimensionsNumber: '6760, 13069',
    domeNumber: '175',
    belkaNumber: '196',
    rgbColor: [245, 173, 173],
    hexColor: 'f5adad',
}, {
    dmcNumber: '3712',
    anchorNumber: '1023, 1024',
    colorName: 'Salmon Medium',
    madeiraNumber: '0405, 0406',
    gammaNumber: '3071',
    kirovaNumber: '5606',
    domeNumber: '174',
    belkaNumber: '2451',
    rgbColor: [241, 135, 135],
    hexColor: 'f18787',
}, {
    dmcNumber: '3328',
    anchorNumber: '1024',
    colorName: 'Salmon Dark',
    madeiraNumber: '406',
    gammaNumber: '3072',
    kirovaNumber: '5608, 39',
    dimensionsNumber: '13071, 63328',
    domeNumber: '173',
    belkaNumber: '2485',
    bucillaNumber: '6171',
    rgbColor: [227, 109, 109],
    hexColor: 'e36d6d',
}, {
    dmcNumber: '347',
    anchorNumber: '1025',
    colorName: 'Salmon Very Dark',
    madeiraNumber: '407',
    gammaNumber: '62',
    dimensionsNumber: '6347, 13013',
    domeNumber: '170',
    belkaNumber: '2046',
    bucillaNumber: '3347',
    rgbColor: [191, 45, 45],
    hexColor: 'bf2d2d',
}, {
    dmcNumber: '353',
    anchorNumber: '6',
    colorName: 'Peach',
    madeiraNumber: '304',
    gammaNumber: '703',
    kirovaNumber: '1602',
    dimensionsNumber: '6031, 13006, 13306',
    domeNumber: '125',
    belkaNumber: '268',
    bucillaNumber: '353',
    rgbColor: [254, 215, 204],
    hexColor: 'fed7cc',
}, {
    dmcNumber: '352',
    anchorNumber: '9',
    colorName: 'Coral Light',
    madeiraNumber: '303',
    gammaNumber: '3073',
    kirovaNumber: '1604',
    mayFlowerNumber: '2253',
    dimensionsNumber: '6352, 13008',
    domeNumber: '124',
    belkaNumber: '1670',
    bucillaNumber: '3352, 6172',
    rgbColor: [253, 156, 151],
    hexColor: 'fd9c97',
}, {
    dmcNumber: '351',
    anchorNumber: '10, 328',
    colorName: 'Coral',
    madeiraNumber: '0214, 0301',
    gammaNumber: '0705, 0314',
    kirovaNumber: '0806',
    dimensionsNumber: '6030, 13011',
    domeNumber: '123',
    belkaNumber: '3487',
    bucillaNumber: '6030',
    rgbColor: [233, 106, 103],
    hexColor: 'e96a67',
}, {
    dmcNumber: '350',
    anchorNumber: '11',
    colorName: 'Coral Medium',
    madeiraNumber: '213',
    gammaNumber: '707',
    kirovaNumber: '0808, 28',
    mayFlowerNumber: '2019',
    dimensionsNumber: '6029, 13111',
    domeNumber: '122',
    belkaNumber: '2898',
    bucillaNumber: '350',
    rgbColor: [224, 72, 72],
    hexColor: 'e04848',
}, {
    dmcNumber: '349',
    anchorNumber: '13',
    colorName: 'Coral Dark',
    madeiraNumber: '212',
    gammaNumber: '708',
    kirovaNumber: '0810, 29',
    dimensionsNumber: '12335',
    domeNumber: '121',
    belkaNumber: '267',
    rgbColor: [210, 16, 53],
    hexColor: 'd21035',
}, {
    dmcNumber: '817',
    anchorNumber: '13',
    colorName: 'Coral Red Very Dark',
    madeiraNumber: '211',
    gammaNumber: '24',
    kirovaNumber: '0906, 30',
    domeNumber: '120',
    belkaNumber: '2281',
    rgbColor: [187, 5, 31],
    hexColor: 'bb051f',
}, {
    dmcNumber: '3708',
    anchorNumber: '31',
    colorName: 'Melon Light',
    madeiraNumber: '408',
    gammaNumber: '3074',
    kirovaNumber: '1006, 25',
    domeNumber: '136',
    belkaNumber: '127',
    rgbColor: [255, 203, 213],
    hexColor: 'ffcbd5',
}, {
    dmcNumber: '3706',
    anchorNumber: '33',
    colorName: 'Melon Medium',
    madeiraNumber: '409',
    gammaNumber: '3075',
    kirovaNumber: '1012',
    domeNumber: '134',
    belkaNumber: '754',
    rgbColor: [255, 173, 188],
    hexColor: 'ffadbc',
}, {
    dmcNumber: '3705',
    anchorNumber: '35',
    colorName: 'Melon Dark',
    madeiraNumber: '410',
    gammaNumber: '3076',
    kirovaNumber: '0808, 28',
    dimensionsNumber: '13012',
    domeNumber: '133',
    belkaNumber: '2717',
    rgbColor: [255, 121, 146],
    hexColor: 'ff7992',
}, {
    dmcNumber: '3801',
    anchorNumber: '1098',
    colorName: 'Melon Very Dark',
    madeiraNumber: '0213, 0411',
    gammaNumber: '63',
    kirovaNumber: '0810, 29',
    dimensionsNumber: '13046',
    domeNumber: '132',
    belkaNumber: '3285',
    rgbColor: [231, 73, 103],
    hexColor: 'e74967',
}, {
    dmcNumber: '666',
    anchorNumber: '46',
    colorName: 'Bright Red',
    madeiraNumber: '0210, 0411',
    gammaNumber: '0012, 0119',
    kirovaNumber: '0904',
    dimensionsNumber: '6020, 6666, 13500',
    domeNumber: '130',
    belkaNumber: '1852',
    bucillaNumber: '666',
    rgbColor: [227, 29, 66],
    hexColor: 'e31d42',
}, {
    dmcNumber: '321',
    anchorNumber: '47, 9046',
    colorName: 'Red',
    madeiraNumber: '0507, 0510',
    gammaNumber: '120',
    kirovaNumber: '0906, 30',
    dimensionsNumber: '6035, 6028, 13047',
    domeNumber: '106',
    belkaNumber: '2946',
    bucillaNumber: '6153',
    rgbColor: [199, 43, 59],
    hexColor: 'c72b3b',
}, {
    dmcNumber: '304',
    anchorNumber: '1006, 19',
    colorName: 'Red Medium',
    madeiraNumber: '0511, 0508',
    gammaNumber: '4005',
    mayFlowerNumber: '2049',
    dimensionsNumber: '11321, 13019',
    domeNumber: '105',
    belkaNumber: '1981',
    bucillaNumber: '304',
    rgbColor: [183, 31, 51],
    hexColor: 'b71f33',
}, {
    dmcNumber: '498',
    anchorNumber: '1005',
    colorName: 'Red Dark',
    madeiraNumber: '509',
    gammaNumber: '709',
    mayFlowerNumber: '2049',
    domeNumber: '104',
    belkaNumber: '3065',
    bucillaNumber: '6215',
    rgbColor: [167, 19, 43],
    hexColor: 'a7132b',
}, {
    dmcNumber: '816',
    anchorNumber: '1005',
    colorName: 'Garnet',
    madeiraNumber: '512',
    gammaNumber: '869',
    kirovaNumber: '1204, 32',
    dimensionsNumber: '6019, 13072',
    domeNumber: '103',
    belkaNumber: '1296',
    bucillaNumber: '6019',
    rgbColor: [151, 11, 35],
    hexColor: '970b23',
}, {
    dmcNumber: '815',
    anchorNumber: '43, 44',
    colorName: 'Garnet Medium',
    madeiraNumber: '0513, 2502',
    gammaNumber: '867',
    kirovaNumber: '1202, 31',
    dimensionsNumber: '13073',
    domeNumber: '102',
    belkaNumber: '338',
    bucillaNumber: '815',
    rgbColor: [135, 7, 31],
    hexColor: '87071f',
}, {
    dmcNumber: '814',
    anchorNumber: '45',
    colorName: 'Garnet Dark',
    madeiraNumber: '0514, 2608',
    gammaNumber: '0407, 0409',
    kirovaNumber: '1208, 35',
    dimensionsNumber: '6814, 11814',
    domeNumber: '101',
    belkaNumber: '2449',
    bucillaNumber: '2784, 3814',
    rgbColor: [123, 0, 27],
    hexColor: '7b001b',
}, {
    dmcNumber: '894',
    anchorNumber: '26, 27',
    colorName: 'Carnation Very Light',
    madeiraNumber: '408',
    gammaNumber: '3077',
    kirovaNumber: '1010, 26',
    dimensionsNumber: '6034, 13127',
    domeNumber: '143',
    belkaNumber: '2060',
    bucillaNumber: '6046',
    rgbColor: [255, 178, 187],
    hexColor: 'ffb2bb',
}, {
    dmcNumber: '893',
    anchorNumber: '41',
    colorName: 'Carnation Light',
    madeiraNumber: '0411, 0413',
    gammaNumber: '116',
    kirovaNumber: '1012',
    dimensionsNumber: '6033',
    domeNumber: '142',
    belkaNumber: '3594',
    rgbColor: [252, 144, 162],
    hexColor: 'fc90a2',
}, {
    dmcNumber: '892',
    anchorNumber: '28, 33',
    colorName: 'Carnation Medium',
    madeiraNumber: '411',
    gammaNumber: '3078',
    domeNumber: '141',
    belkaNumber: '2122',
    rgbColor: [255, 121, 140],
    hexColor: 'ff798c',
}, {
    dmcNumber: '891',
    anchorNumber: '35',
    colorName: 'Carnation Dark',
    madeiraNumber: '412',
    gammaNumber: '118',
    kirovaNumber: '0902',
    dimensionsNumber: '6217, 6891',
    domeNumber: '140',
    belkaNumber: '2651',
    bucillaNumber: '2612',
    rgbColor: [255, 87, 115],
    hexColor: 'ff5773',
}, {
    dmcNumber: '818',
    anchorNumber: '23, 48',
    colorName: 'Baby Pink',
    madeiraNumber: '502',
    gammaNumber: '902',
    kirovaNumber: '1102',
    mayFlowerNumber: '2042',
    dimensionsNumber: '6963',
    domeNumber: '117',
    belkaNumber: '2563',
    bucillaNumber: '818',
    rgbColor: [255, 223, 217],
    hexColor: 'ffdfd9',
}, {
    dmcNumber: '957',
    anchorNumber: '25, 50',
    colorName: 'Geranium Pale',
    madeiraNumber: '612',
    gammaNumber: '3079',
    kirovaNumber: '1008',
    dimensionsNumber: '13127',
    domeNumber: '147',
    belkaNumber: '777',
    bucillaNumber: '2642',
    rgbColor: [253, 181, 181],
    hexColor: 'fdb5b5',
}, {
    dmcNumber: '956',
    anchorNumber: '40, 41',
    colorName: 'Geranium',
    madeiraNumber: '0413, 0611',
    gammaNumber: '3080',
    dimensionsNumber: '13152',
    domeNumber: '146',
    belkaNumber: '651',
    bucillaNumber: '2643, 2781',
    rgbColor: [255, 145, 145],
    hexColor: 'ff9191',
}, {
    dmcNumber: '309',
    anchorNumber: '39, 42',
    colorName: 'Rose Dark',
    madeiraNumber: '0104, 0507',
    gammaNumber: '3090',
    dimensionsNumber: '13284',
    domeNumber: '112',
    belkaNumber: '172',
    bucillaNumber: '309',
    rgbColor: [214, 43, 91],
    hexColor: 'd62b5b',
}, {
    dmcNumber: '963',
    anchorNumber: '23, 73',
    colorName: 'Dusty Rose Ult Vy Lt',
    madeiraNumber: '502',
    gammaNumber: '201',
    kirovaNumber: '1002, 23',
    mayFlowerNumber: '2054',
    domeNumber: '163',
    belkaNumber: '373',
    bucillaNumber: '2200',
    rgbColor: [255, 215, 215],
    hexColor: 'ffd7d7',
}, {
    dmcNumber: '3716',
    anchorNumber: '25',
    colorName: 'Dusty Rose Med Vy Lt',
    madeiraNumber: '0606, 0607',
    gammaNumber: '3081',
    kirovaNumber: '1004, 24',
    dimensionsNumber: '13125, 13129',
    domeNumber: '162',
    belkaNumber: '980',
    rgbColor: [255, 189, 189],
    hexColor: 'ffbdbd',
}, {
    dmcNumber: '962',
    anchorNumber: '76',
    colorName: 'Dusty Rose Medium',
    madeiraNumber: '0505, 0609',
    gammaNumber: '3082',
    kirovaNumber: '1502',
    mayFlowerNumber: '2267',
    dimensionsNumber: '13153',
    domeNumber: '161',
    belkaNumber: '2537',
    rgbColor: [230, 138, 138],
    hexColor: 'e68a8a',
}, {
    dmcNumber: '961',
    anchorNumber: '75',
    colorName: 'Dusty Rose Dark',
    madeiraNumber: '506',
    gammaNumber: '3083',
    kirovaNumber: '1504',
    domeNumber: '160',
    belkaNumber: '25',
    rgbColor: [207, 115, 115],
    hexColor: 'cf7373',
}, {
    dmcNumber: '3833',
    anchorNumber: '26, 1023',
    colorName: 'Raspberry Light',
    madeiraNumber: '414',
    gammaNumber: '3084',
    domeNumber: '192',
    belkaNumber: '3465',
    rgbColor: [234, 134, 153],
    hexColor: 'ea8699',
}, {
    dmcNumber: '3832',
    anchorNumber: '28',
    colorName: 'Raspberry Medium',
    madeiraNumber: '506',
    gammaNumber: '3085',
    domeNumber: '191',
    belkaNumber: '2710',
    rgbColor: [219, 85, 110],
    hexColor: 'db556e',
}, {
    dmcNumber: '3831',
    anchorNumber: '29, 1006',
    colorName: 'Raspberry Dark',
    madeiraNumber: '610',
    gammaNumber: '3086',
    domeNumber: '190',
    belkaNumber: '567',
    rgbColor: [179, 47, 72],
    hexColor: 'b32f48',
}, {
    dmcNumber: '777',
    anchorNumber: '65',
    colorName: 'Raspberry Very Dark',
    madeiraNumber: '508',
    gammaNumber: '406',
    belkaNumber: '1055',
    rgbColor: [145, 53, 70],
    hexColor: '913546',
}, {
    dmcNumber: '819',
    anchorNumber: '271',
    colorName: 'Baby Pink Light',
    madeiraNumber: '501',
    gammaNumber: '25',
    dimensionsNumber: '6819, 13280',
    domeNumber: '118',
    belkaNumber: '3042',
    rgbColor: [255, 238, 235],
    hexColor: 'ffeeeb',
}, {
    dmcNumber: '3326',
    anchorNumber: '36',
    colorName: 'Rose Light',
    madeiraNumber: '0504, 0813',
    gammaNumber: '66',
    kirovaNumber: '1006, 25',
    dimensionsNumber: '13126',
    domeNumber: '115',
    belkaNumber: '2722',
    bucillaNumber: '3326',
    rgbColor: [251, 173, 180],
    hexColor: 'fbadb4',
}, {
    dmcNumber: '776',
    colorName: 'Pink Medium',
    rgbColor: [252, 176, 185],
    hexColor: 'fcb0b9',
}, {
    dmcNumber: '899',
    anchorNumber: '76',
    colorName: 'Rose Medium',
    madeiraNumber: '0414, 0609',
    gammaNumber: '3088',
    kirovaNumber: '1008',
    dimensionsNumber: '6025, 13282',
    domeNumber: '114',
    belkaNumber: '219',
    bucillaNumber: '899',
    rgbColor: [242, 118, 136],
    hexColor: 'f27688',
}, {
    dmcNumber: '335',
    anchorNumber: '38, 76',
    colorName: 'Rose',
    madeiraNumber: '506',
    gammaNumber: '3089',
    kirovaNumber: '1504',
    mayFlowerNumber: '2046',
    dimensionsNumber: '6024, 13283',
    domeNumber: '113',
    belkaNumber: '575',
    bucillaNumber: '2056',
    rgbColor: [238, 84, 110],
    hexColor: 'ee546e',
}, {
    dmcNumber: '326',
    anchorNumber: '59, 1006',
    colorName: 'Rose Very Dark',
    madeiraNumber: '508',
    gammaNumber: '64',
    dimensionsNumber: '6022, 13401',
    domeNumber: '110',
    belkaNumber: '565',
    bucillaNumber: '2057',
    rgbColor: [179, 59, 75],
    hexColor: 'b33b4b',
}, {
    dmcNumber: '151',
    anchorNumber: '73',
    colorName: 'Dusty Rose Vry Lt',
    madeiraNumber: '607',
    gammaNumber: '3236',
    belkaNumber: '171',
    rgbColor: [240, 206, 212],
    hexColor: 'f0ced4',
}, {
    dmcNumber: '3354',
    anchorNumber: '66, 74',
    colorName: 'Dusty Rose Light',
    madeiraNumber: '0606, 0607',
    gammaNumber: '0904, 3091',
    kirovaNumber: '1104',
    dimensionsNumber: '6214, 13003',
    domeNumber: '169',
    belkaNumber: '2721',
    rgbColor: [228, 166, 172],
    hexColor: 'e4a6ac',
}, {
    dmcNumber: '3733',
    anchorNumber: '75, 1023',
    colorName: 'Dusty Rose',
    madeiraNumber: '813',
    gammaNumber: '65',
    kirovaNumber: '1502',
    domeNumber: '168',
    belkaNumber: '2429',
    rgbColor: [232, 135, 155],
    hexColor: 'e8879b',
}, {
    dmcNumber: '3731',
    anchorNumber: '76',
    colorName: 'Dusty Rose Very Dark',
    madeiraNumber: '506',
    gammaNumber: '3092',
    kirovaNumber: '1506, 173',
    dimensionsNumber: '13176',
    domeNumber: '167',
    belkaNumber: '1556',
    rgbColor: [218, 103, 131],
    hexColor: 'da6783',
}, {
    dmcNumber: '3350',
    anchorNumber: '59',
    colorName: 'Dusty Rose Ultra Dark',
    madeiraNumber: '603',
    kirovaNumber: '1510',
    dimensionsNumber: '13004',
    domeNumber: '166',
    belkaNumber: '45',
    rgbColor: [188, 67, 101],
    hexColor: 'bc4365',
}, {
    dmcNumber: '150',
    anchorNumber: '59',
    colorName: 'Dusty Rose Ult Vy Dk',
    madeiraNumber: '509',
    gammaNumber: '3235',
    belkaNumber: '3602',
    rgbColor: [171, 2, 73],
    hexColor: 'ab0249',
}, {
    dmcNumber: '3689',
    anchorNumber: '49, 74',
    colorName: 'Mauve Light',
    madeiraNumber: '607',
    gammaNumber: '3093',
    kirovaNumber: '1702',
    dimensionsNumber: '13086',
    domeNumber: '184',
    belkaNumber: '2904',
    bucillaNumber: '3689',
    rgbColor: [251, 191, 194],
    hexColor: 'fbbfc2',
}, {
    dmcNumber: '3688',
    anchorNumber: '66, 68',
    colorName: 'Mauve Medium',
    madeiraNumber: '605',
    gammaNumber: '905',
    dimensionsNumber: '13087',
    domeNumber: '183',
    belkaNumber: '3609',
    bucillaNumber: '3688, 6177',
    rgbColor: [231, 169, 172],
    hexColor: 'e7a9ac',
}, {
    dmcNumber: '3687',
    anchorNumber: '68, 79',
    colorName: 'Mauve',
    madeiraNumber: '0604, 0605',
    gammaNumber: '906',
    kirovaNumber: '1508',
    dimensionsNumber: '13088',
    domeNumber: '182',
    belkaNumber: '3237',
    bucillaNumber: '3687',
    rgbColor: [201, 107, 112],
    hexColor: 'c96b70',
}, {
    dmcNumber: '3803',
    anchorNumber: '65, 1028',
    colorName: 'Mauve Dark',
    madeiraNumber: '2609',
    gammaNumber: '0907, 3094',
    kirovaNumber: '1808',
    domeNumber: '181',
    belkaNumber: '2567',
    bucillaNumber: '3803',
    rgbColor: [171, 51, 87],
    hexColor: 'ab3357',
}, {
    dmcNumber: '3685',
    anchorNumber: '1028',
    colorName: 'Mauve Very Dark',
    madeiraNumber: '0602, 2609',
    gammaNumber: '0038, 0908',
    kirovaNumber: '1208, 35',
    dimensionsNumber: '13089',
    domeNumber: '180',
    belkaNumber: '1415',
    bucillaNumber: '3685',
    rgbColor: [136, 21, 49],
    hexColor: '881531',
}, {
    dmcNumber: '605',
    anchorNumber: '74, 1094',
    colorName: 'Cranberry Very Light',
    madeiraNumber: '613',
    dimensionsNumber: '13151',
    domeNumber: '155',
    belkaNumber: '1290',
    bucillaNumber: '605',
    rgbColor: [255, 192, 205],
    hexColor: 'ffc0cd',
}, {
    dmcNumber: '604',
    anchorNumber: '50, 55',
    colorName: 'Cranberry Light',
    madeiraNumber: '0606, 0614',
    gammaNumber: '0070, 0202',
    kirovaNumber: '1104',
    domeNumber: '154',
    belkaNumber: '3130',
    bucillaNumber: '6218',
    rgbColor: [255, 176, 190],
    hexColor: 'ffb0be',
}, {
    dmcNumber: '603',
    anchorNumber: '52, 62',
    colorName: 'Cranberry',
    madeiraNumber: '701',
    gammaNumber: '204',
    kirovaNumber: '1106',
    dimensionsNumber: '13001',
    domeNumber: '153',
    belkaNumber: '2737',
    bucillaNumber: '6281',
    rgbColor: [255, 164, 190],
    hexColor: 'ffa4be',
}, {
    dmcNumber: '602',
    anchorNumber: '57, 63',
    colorName: 'Cranberry Medium',
    madeiraNumber: '0611, 0703',
    gammaNumber: '0071, 0404',
    kirovaNumber: '1108',
    dimensionsNumber: '13063',
    domeNumber: '152',
    belkaNumber: '131',
    bucillaNumber: '3063',
    rgbColor: [226, 72, 116],
    hexColor: 'e24874',
}, {
    dmcNumber: '601',
    anchorNumber: '57, 63',
    colorName: 'Cranberry Dark',
    madeiraNumber: '610',
    gammaNumber: '3104',
    kirovaNumber: '1110, 27',
    dimensionsNumber: '13128',
    domeNumber: '151',
    belkaNumber: '581',
    bucillaNumber: '6041',
    rgbColor: [209, 40, 106],
    hexColor: 'd1286a',
}, {
    dmcNumber: '600',
    anchorNumber: '59, 78',
    colorName: 'Cranberry Very Dark',
    madeiraNumber: '507',
    gammaNumber: '3105',
    kirovaNumber: '1112',
    dimensionsNumber: '13056',
    domeNumber: '150',
    belkaNumber: '417',
    rgbColor: [205, 47, 99],
    hexColor: 'cd2f63',
}, {
    dmcNumber: '3806',
    anchorNumber: '62',
    colorName: 'Cyclamen Pink Light',
    madeiraNumber: '701',
    gammaNumber: '402',
    kirovaNumber: '1406, 171',
    domeNumber: '159',
    belkaNumber: '231',
    bucillaNumber: '2210',
    rgbColor: [255, 140, 174],
    hexColor: 'ff8cae',
}, {
    dmcNumber: '3805',
    anchorNumber: '63',
    colorName: 'Cyclamen Pink',
    madeiraNumber: '702',
    gammaNumber: '69',
    kirovaNumber: '1408',
    domeNumber: '158',
    belkaNumber: '793',
    rgbColor: [243, 71, 139],
    hexColor: 'f3478b',
}, {
    dmcNumber: '3804',
    anchorNumber: '63, 68',
    colorName: 'Cyclamen Pink Dark',
    madeiraNumber: '604',
    gammaNumber: '3103',
    kirovaNumber: '1110, 27',
    domeNumber: '157',
    belkaNumber: '2725',
    rgbColor: [224, 40, 118],
    hexColor: 'e02876',
}, {
    dmcNumber: '3609',
    anchorNumber: '85, 90',
    colorName: 'Plum Ultra Light',
    madeiraNumber: '710',
    gammaNumber: '0037, 6080',
    kirovaNumber: '1702',
    dimensionsNumber: '14085, 63609',
    domeNumber: '687',
    belkaNumber: '24',
    bucillaNumber: '3609',
    rgbColor: [244, 174, 213],
    hexColor: 'f4aed5',
}, {
    dmcNumber: '3608',
    anchorNumber: '60, 86',
    colorName: 'Plum Very Light',
    madeiraNumber: '709',
    gammaNumber: '609',
    kirovaNumber: '1706',
    dimensionsNumber: '14086',
    domeNumber: '686',
    belkaNumber: '2872',
    bucillaNumber: '3608',
    rgbColor: [234, 156, 196],
    hexColor: 'ea9cc4',
}, {
    dmcNumber: '3607',
    anchorNumber: '87',
    colorName: 'Plum Light',
    madeiraNumber: '708',
    gammaNumber: '72',
    kirovaNumber: '1410, 172',
    dimensionsNumber: '14087, 63607',
    domeNumber: '685',
    belkaNumber: '1499',
    bucillaNumber: '3607',
    rgbColor: [197, 73, 137],
    hexColor: 'c54989',
}, {
    dmcNumber: '718',
    anchorNumber: '88, 89',
    colorName: 'Plum',
    madeiraNumber: '707',
    gammaNumber: '73',
    kirovaNumber: '1710',
    domeNumber: '683',
    belkaNumber: '2238',
    rgbColor: [156, 36, 98],
    hexColor: '9c2462',
}, {
    dmcNumber: '917',
    anchorNumber: '88, 89',
    colorName: 'Plum Medium',
    madeiraNumber: '706',
    gammaNumber: '31',
    kirovaNumber: '1712',
    dimensionsNumber: '6017, 14089',
    domeNumber: '682',
    belkaNumber: '2951',
    bucillaNumber: '3917',
    rgbColor: [155, 19, 89],
    hexColor: '9b1359',
}, {
    dmcNumber: '915',
    anchorNumber: '1029',
    colorName: 'Plum Dark',
    madeiraNumber: '705',
    gammaNumber: '3106',
    kirovaNumber: '1412',
    domeNumber: '680',
    belkaNumber: '167',
    rgbColor: [130, 0, 67],
    hexColor: '820043',
}, {
    dmcNumber: '225',
    anchorNumber: '1026',
    colorName: 'Shell Pink Ult Vy Lt',
    madeiraNumber: '814',
    gammaNumber: '765',
    mayFlowerNumber: '2041',
    dimensionsNumber: '13239',
    domeNumber: '667',
    belkaNumber: '1988',
    bucillaNumber: '225',
    rgbColor: [255, 223, 213],
    hexColor: 'ffdfd5',
}, {
    dmcNumber: '224',
    anchorNumber: '893, 23',
    colorName: 'Shell Pink Very Light',
    madeiraNumber: '808',
    gammaNumber: '3095, 0875',
    kirovaNumber: '5602',
    dimensionsNumber: '13240',
    domeNumber: '665',
    belkaNumber: '1354',
    bucillaNumber: '1224',
    rgbColor: [235, 183, 175],
    hexColor: 'ebb7af',
}, {
    dmcNumber: '152',
    colorName: 'Shell Pink Med Light',
    gammaNumber: '3013',
    belkaNumber: '1867',
    rgbColor: [226, 160, 153],
    hexColor: 'e2a099',
}, {
    dmcNumber: '223',
    anchorNumber: '895',
    colorName: 'Shell Pink Light',
    madeiraNumber: '812',
    gammaNumber: '3014',
    kirovaNumber: '5604',
    dimensionsNumber: '60223',
    domeNumber: '664',
    belkaNumber: '2906',
    bucillaNumber: '223',
    rgbColor: [204, 132, 124],
    hexColor: 'cc847c',
}, {
    dmcNumber: '3722',
    anchorNumber: '1027',
    colorName: 'Shell Pink Med',
    madeiraNumber: '812',
    gammaNumber: '3015',
    kirovaNumber: '5606',
    dimensionsNumber: '13241',
    domeNumber: '663',
    belkaNumber: '2540',
    rgbColor: [188, 108, 100],
    hexColor: 'bc6c64',
}, {
    dmcNumber: '3721',
    anchorNumber: '896',
    colorName: 'Shell Pink Dark',
    madeiraNumber: '810',
    gammaNumber: '3096',
    kirovaNumber: '5610',
    mayFlowerNumber: '2250',
    dimensionsNumber: '13242',
    domeNumber: '662',
    belkaNumber: '3527',
    bucillaNumber: '721',
    rgbColor: [161, 75, 81],
    hexColor: 'a14b51',
}, {
    dmcNumber: '221',
    anchorNumber: '897',
    colorName: 'Shell Pink Vy Dk',
    madeiraNumber: '811',
    gammaNumber: '865',
    dimensionsNumber: '60221',
    domeNumber: '660',
    belkaNumber: '2595',
    bucillaNumber: '6212',
    rgbColor: [136, 62, 67],
    hexColor: '883e43',
}, {
    dmcNumber: '778',
    anchorNumber: '968, 969',
    colorName: 'Antique Mauve Vy Lt',
    madeiraNumber: '808',
    gammaNumber: '876',
    kirovaNumber: '5702',
    dimensionsNumber: '13060',
    domeNumber: '656',
    belkaNumber: '333',
    rgbColor: [223, 179, 187],
    hexColor: 'dfb3bb',
}, {
    dmcNumber: '3727',
    anchorNumber: '1016, 1017',
    colorName: 'Antique Mauve Light',
    madeiraNumber: '2610',
    gammaNumber: '3016',
    kirovaNumber: '1802',
    domeNumber: '655',
    belkaNumber: '2575',
    rgbColor: [219, 169, 178],
    hexColor: 'dba9b2',
}, {
    dmcNumber: '316',
    anchorNumber: '1017',
    colorName: 'Antique Mauve Med',
    madeiraNumber: '809',
    gammaNumber: '3098',
    kirovaNumber: '1804',
    dimensionsNumber: '13081',
    domeNumber: '654',
    belkaNumber: '2847',
    rgbColor: [183, 115, 127],
    hexColor: 'b7737f',
}, {
    dmcNumber: '3726',
    anchorNumber: '1018',
    colorName: 'Antique Mauve Dark',
    madeiraNumber: '810',
    gammaNumber: '879',
    kirovaNumber: '5706',
    mayFlowerNumber: '2078',
    dimensionsNumber: '13084',
    domeNumber: '652',
    belkaNumber: '2772',
    bucillaNumber: '2741',
    rgbColor: [155, 91, 102],
    hexColor: '9b5b66',
}, {
    dmcNumber: '315',
    anchorNumber: '1019',
    colorName: 'Antique Mauve Md Dk',
    madeiraNumber: '810',
    gammaNumber: '3099',
    kirovaNumber: '5708',
    dimensionsNumber: '13082, 63726',
    domeNumber: '651',
    belkaNumber: '2011',
    rgbColor: [129, 73, 82],
    hexColor: '814952',
}, {
    dmcNumber: '3802',
    anchorNumber: '1019',
    colorName: 'Antique Mauve Vy Dk',
    madeiraNumber: '2606',
    gammaNumber: '3100',
    kirovaNumber: '1302, 36',
    domeNumber: '650',
    belkaNumber: '310',
    rgbColor: [113, 65, 73],
    hexColor: '714149',
}, {
    dmcNumber: '902',
    anchorNumber: '44, 22, 897',
    colorName: 'Garnet Very Dark',
    madeiraNumber: '0601, 2501, 2607',
    gammaNumber: '0028, 0410, 0870',
    kirovaNumber: '1304, 37',
    domeNumber: '100',
    belkaNumber: '861',
    rgbColor: [130, 38, 55],
    hexColor: '822637',
}, {
    dmcNumber: '3743',
    anchorNumber: '234, 869',
    colorName: 'Antique Violet Vy Lt',
    madeiraNumber: '2611',
    gammaNumber: '0416, 0725',
    domeNumber: '433',
    belkaNumber: '776',
    bucillaNumber: '3743',
    rgbColor: [215, 203, 211],
    hexColor: 'd7cbd3',
}, {
    dmcNumber: '3042',
    anchorNumber: '870',
    colorName: 'Antique Violet Light',
    madeiraNumber: '0807, 2612',
    gammaNumber: '417',
    dimensionsNumber: '14221',
    domeNumber: '432',
    belkaNumber: '2853',
    bucillaNumber: '3042',
    rgbColor: [183, 157, 167],
    hexColor: 'b79da7',
}, {
    dmcNumber: '3041',
    anchorNumber: '871',
    colorName: 'Antique Violet Medium',
    madeiraNumber: '806',
    gammaNumber: '3017',
    dimensionsNumber: '14223',
    domeNumber: '431',
    belkaNumber: '824',
    bucillaNumber: '2740, 3041',
    rgbColor: [149, 111, 124],
    hexColor: '956f7c',
}, {
    dmcNumber: '3740',
    anchorNumber: '873',
    colorName: 'Antique Violet Dark',
    madeiraNumber: '0806, 2614',
    gammaNumber: '3101',
    kirovaNumber: '1910',
    dimensionsNumber: '14224',
    domeNumber: '430',
    belkaNumber: '459',
    rgbColor: [120, 87, 98],
    hexColor: '785762',
}, {
    dmcNumber: '3836',
    anchorNumber: '90',
    colorName: 'Grape Light',
    gammaNumber: '68',
    domeNumber: '609',
    belkaNumber: '2637',
    rgbColor: [186, 145, 170],
    hexColor: 'ba91aa',
}, {
    dmcNumber: '3835',
    anchorNumber: '98',
    colorName: 'Grape Medium',
    domeNumber: '608',
    belkaNumber: '1512',
    bucillaNumber: '3835',
    rgbColor: [148, 96, 131],
    hexColor: '946083',
}, {
    dmcNumber: '3834',
    colorName: 'Grape Dark',
    madeiraNumber: '805',
    gammaNumber: '3102',
    domeNumber: '606',
    belkaNumber: '2806',
    bucillaNumber: '3834',
    rgbColor: [114, 55, 93],
    hexColor: '72375d',
}, {
    dmcNumber: '154',
    anchorNumber: '72, 873',
    colorName: 'Grape Very Dark',
    madeiraNumber: '2607',
    gammaNumber: '3237, 0909',
    belkaNumber: '927',
    rgbColor: [87, 36, 51],
    hexColor: '572433',
}, {
    dmcNumber: '211',
    anchorNumber: '342',
    colorName: 'Lavender Light',
    madeiraNumber: '801',
    gammaNumber: '1095',
    kirovaNumber: '2202',
    dimensionsNumber: '6210, 11263',
    domeNumber: '634',
    belkaNumber: '635',
    bucillaNumber: '6140',
    rgbColor: [227, 203, 227],
    hexColor: 'e3cbe3',
}, {
    dmcNumber: '210',
    anchorNumber: '108',
    colorName: 'Lavender Medium',
    madeiraNumber: '802',
    gammaNumber: '76',
    kirovaNumber: '2304',
    dimensionsNumber: '6138, 6139',
    domeNumber: '633',
    belkaNumber: '774',
    rgbColor: [195, 159, 195],
    hexColor: 'c39fc3',
}, {
    dmcNumber: '209',
    anchorNumber: '109',
    colorName: 'Lavender Dark',
    madeiraNumber: '803',
    gammaNumber: '3107',
    kirovaNumber: '2204',
    dimensionsNumber: '14302',
    domeNumber: '632',
    belkaNumber: '2907',
    bucillaNumber: '209',
    rgbColor: [163, 123, 167],
    hexColor: 'a37ba7',
}, {
    dmcNumber: '208',
    anchorNumber: '111, 110',
    colorName: 'Lavender Very Dark',
    madeiraNumber: '804',
    gammaNumber: '111',
    kirovaNumber: '2206, 48',
    dimensionsNumber: '14301',
    domeNumber: '631',
    belkaNumber: '2483',
    bucillaNumber: '6137',
    rgbColor: [131, 91, 139],
    hexColor: '835b8b',
}, {
    dmcNumber: '3837',
    anchorNumber: '100, 111',
    colorName: 'Lavender Ultra Dark',
    madeiraNumber: '712',
    gammaNumber: '112',
    dimensionsNumber: '14300',
    domeNumber: '630',
    belkaNumber: '1881',
    rgbColor: [108, 58, 110],
    hexColor: '6c3a6e',
}, {
    dmcNumber: '327',
    anchorNumber: '100',
    colorName: 'Violet Dark',
    madeiraNumber: '805',
    gammaNumber: '3108',
    kirovaNumber: '2004',
    dimensionsNumber: '14101',
    domeNumber: '607',
    belkaNumber: '2626',
    bucillaNumber: '327',
    rgbColor: [99, 54, 102],
    hexColor: '633666',
}, {
    dmcNumber: '153',
    anchorNumber: '95',
    colorName: 'Violet Very Light',
    gammaNumber: '3251',
    belkaNumber: '3357',
    rgbColor: [230, 204, 217],
    hexColor: 'e6ccd9',
}, {
    dmcNumber: '554',
    anchorNumber: '97',
    colorName: 'Violet Light',
    madeiraNumber: '711',
    gammaNumber: '727',
    kirovaNumber: '2106, 43',
    dimensionsNumber: '6136, 14104',
    domeNumber: '604',
    belkaNumber: '3279',
    rgbColor: [219, 179, 203],
    hexColor: 'dbb3cb',
}, {
    dmcNumber: '553',
    anchorNumber: '98',
    colorName: 'Violet',
    madeiraNumber: '712',
    gammaNumber: '74',
    kirovaNumber: '2206, 48',
    dimensionsNumber: '6135, 14097',
    domeNumber: '603',
    belkaNumber: '3177',
    bucillaNumber: '2632',
    rgbColor: [163, 99, 139],
    hexColor: 'a3638b',
}, {
    dmcNumber: '552',
    anchorNumber: '99, 100',
    colorName: 'Violet Medium',
    madeiraNumber: '713',
    gammaNumber: '77',
    kirovaNumber: '2208, 49',
    dimensionsNumber: '6137, 14092',
    domeNumber: '602',
    belkaNumber: '372',
    bucillaNumber: '552',
    rgbColor: [128, 58, 107],
    hexColor: '803a6b',
}, {
    dmcNumber: '550',
    anchorNumber: '101, 102',
    colorName: 'Violet Very Dark',
    madeiraNumber: '714',
    gammaNumber: '75',
    dimensionsNumber: '6133, 14107',
    domeNumber: '600',
    belkaNumber: '199',
    bucillaNumber: '2633',
    rgbColor: [92, 24, 78],
    hexColor: '5c184e',
}, {
    dmcNumber: '3747',
    anchorNumber: '120',
    colorName: 'Blue Violet Vy Lt',
    madeiraNumber: '901',
    gammaNumber: '3109',
    kirovaNumber: '2402',
    dimensionsNumber: '17004',
    domeNumber: '628',
    belkaNumber: '2123',
    bucillaNumber: '3747',
    rgbColor: [211, 215, 237],
    hexColor: 'd3d7ed',
}, {
    dmcNumber: '341',
    anchorNumber: '117',
    colorName: 'Blue Violet Light',
    madeiraNumber: '901',
    gammaNumber: '3047',
    dimensionsNumber: '6341, 17005',
    domeNumber: '625',
    belkaNumber: '2017',
    bucillaNumber: '341',
    rgbColor: [183, 191, 221],
    hexColor: 'b7bfdd',
}, {
    dmcNumber: '156',
    anchorNumber: '118',
    colorName: 'Blue Violet Med Lt',
    gammaNumber: '3048',
    dimensionsNumber: '17876',
    belkaNumber: '2014',
    rgbColor: [163, 174, 209],
    hexColor: 'a3aed1',
}, {
    dmcNumber: '340',
    anchorNumber: '118',
    colorName: 'Blue Violet Medium',
    madeiraNumber: '902',
    gammaNumber: '78',
    kirovaNumber: '2404',
    dimensionsNumber: '6250, 6340',
    domeNumber: '624',
    belkaNumber: '1774',
    bucillaNumber: '340',
    rgbColor: [173, 167, 199],
    hexColor: 'ada7c7',
}, {
    dmcNumber: '155',
    anchorNumber: '1030',
    colorName: 'Blue Violet Med Dark',
    madeiraNumber: '902',
    gammaNumber: '3238',
    dimensionsNumber: '14303, 17110',
    belkaNumber: '1259',
    rgbColor: [152, 145, 182],
    hexColor: '9891b6',
}, {
    dmcNumber: '3746',
    anchorNumber: '1030',
    colorName: 'Blue Violet Dark',
    gammaNumber: '3110',
    kirovaNumber: '2306',
    domeNumber: '622',
    belkaNumber: '524',
    rgbColor: [119, 107, 152],
    hexColor: '776b98',
}, {
    dmcNumber: '333',
    anchorNumber: '110, 119',
    colorName: 'Blue Violet Very Dark',
    madeiraNumber: '903',
    gammaNumber: '79',
    kirovaNumber: '2308',
    dimensionsNumber: '16265',
    domeNumber: '620',
    belkaNumber: '3547',
    bucillaNumber: '333',
    rgbColor: [92, 84, 120],
    hexColor: '5c5478',
}, {
    dmcNumber: '157',
    anchorNumber: '120',
    colorName: 'Cornflower Blue Vy Lt',
    madeiraNumber: '907',
    gammaNumber: '3239',
    belkaNumber: '3687',
    rgbColor: [187, 195, 217],
    hexColor: 'bbc3d9',
}, {
    dmcNumber: '794',
    anchorNumber: '175, 806',
    colorName: 'Cornflower Blue Light',
    madeiraNumber: '0907, 1003',
    gammaNumber: '0023, 0756',
    kirovaNumber: '2806',
    domeNumber: '615',
    belkaNumber: '2305',
    bucillaNumber: '3794',
    rgbColor: [143, 156, 193],
    hexColor: '8f9cc1',
}, {
    dmcNumber: '793',
    anchorNumber: '176, 939',
    colorName: 'Cornflower Blue Med',
    madeiraNumber: '906',
    gammaNumber: '3111',
    kirovaNumber: '2404',
    domeNumber: '614',
    belkaNumber: '371',
    bucillaNumber: '793',
    rgbColor: [112, 125, 162],
    hexColor: '707da2',
}, {
    dmcNumber: '3807',
    anchorNumber: '122',
    colorName: 'Cornflower Blue',
    madeiraNumber: '2702',
    gammaNumber: '759',
    kirovaNumber: '2406, 57',
    domeNumber: '613',
    belkaNumber: '551',
    rgbColor: [96, 103, 140],
    hexColor: '60678c',
}, {
    dmcNumber: '792',
    anchorNumber: '177, 941',
    colorName: 'Cornflower Blue Dark',
    madeiraNumber: '905',
    gammaNumber: '80',
    kirovaNumber: '2408',
    dimensionsNumber: '17150',
    domeNumber: '612',
    belkaNumber: '1056',
    bucillaNumber: '792',
    rgbColor: [85, 91, 123],
    hexColor: '555b7b',
}, {
    dmcNumber: '158',
    anchorNumber: '177',
    colorName: 'Cornflower Blu M V D',
    madeiraNumber: '2702',
    gammaNumber: '3240',
    belkaNumber: '2001',
    rgbColor: [76, 82, 110],
    hexColor: '4c526e',
}, {
    dmcNumber: '791',
    anchorNumber: '178',
    colorName: 'Cornflower Blue V D',
    madeiraNumber: '904',
    gammaNumber: '308',
    kirovaNumber: '2410',
    dimensionsNumber: '17024',
    domeNumber: '610',
    belkaNumber: '2484',
    bucillaNumber: '791',
    rgbColor: [70, 69, 99],
    hexColor: '464563',
}, {
    dmcNumber: '3840',
    anchorNumber: '117, 120',
    colorName: 'Lavender Blue Light',
    madeiraNumber: '907',
    gammaNumber: '302',
    domeNumber: '378',
    belkaNumber: '1194',
    rgbColor: [176, 192, 218],
    hexColor: 'b0c0da',
}, {
    dmcNumber: '3839',
    anchorNumber: '176',
    colorName: 'Lavender Blue Med',
    madeiraNumber: '902',
    gammaNumber: '81',
    domeNumber: '377',
    belkaNumber: '21',
    rgbColor: [123, 142, 171],
    hexColor: '7b8eab',
}, {
    dmcNumber: '3838',
    anchorNumber: '177, 940',
    colorName: 'Lavender Blue Dark',
    madeiraNumber: '905',
    gammaNumber: '3112',
    domeNumber: '376',
    belkaNumber: '2268',
    rgbColor: [92, 114, 148],
    hexColor: '5c7294',
}, {
    dmcNumber: '800',
    anchorNumber: '144, 159',
    colorName: 'Delft Blue Pale',
    madeiraNumber: '908',
    gammaNumber: '27',
    kirovaNumber: '2604, 60',
    dimensionsNumber: '6800',
    domeNumber: '319',
    belkaNumber: '1948',
    bucillaNumber: '6307',
    rgbColor: [192, 204, 222],
    hexColor: 'c0ccde',
}, {
    dmcNumber: '809',
    anchorNumber: '130, 175',
    colorName: 'Delft Blue',
    madeiraNumber: '909',
    gammaNumber: '304',
    kirovaNumber: '2606, 61',
    mayFlowerNumber: '2281',
    dimensionsNumber: '6017, 17021',
    domeNumber: '317',
    belkaNumber: '2669',
    rgbColor: [148, 168, 198],
    hexColor: '94a8c6',
}, {
    dmcNumber: '799',
    anchorNumber: '145',
    colorName: 'Delft Blue Medium',
    madeiraNumber: '910',
    gammaNumber: '3113, 0005',
    kirovaNumber: '2504',
    mayFlowerNumber: '2088',
    dimensionsNumber: '6016, 7030, 17030',
    domeNumber: '314',
    belkaNumber: '945',
    rgbColor: [116, 142, 182],
    hexColor: '748eb6',
}, {
    dmcNumber: '798',
    anchorNumber: '131',
    colorName: 'Delft Blue Dark',
    madeiraNumber: '911',
    gammaNumber: '82',
    kirovaNumber: '2610, 63',
    mayFlowerNumber: '2289',
    dimensionsNumber: '6798, 17022',
    domeNumber: '313',
    belkaNumber: '2528',
    bucillaNumber: '798',
    rgbColor: [70, 106, 142],
    hexColor: '466a8e',
}, {
    dmcNumber: '797',
    anchorNumber: '132, 139',
    colorName: 'Royal Blue',
    madeiraNumber: '912',
    gammaNumber: '306',
    dimensionsNumber: '17023',
    domeNumber: '312',
    belkaNumber: '994',
    bucillaNumber: '2604',
    rgbColor: [19, 71, 125],
    hexColor: '13477d',
}, {
    dmcNumber: '796',
    anchorNumber: '133, 134',
    colorName: 'Royal Blue Dark',
    madeiraNumber: '913',
    gammaNumber: '83',
    kirovaNumber: '2410',
    dimensionsNumber: '6214, 6796, 17100',
    domeNumber: '311',
    belkaNumber: '1062',
    bucillaNumber: '2606',
    rgbColor: [17, 65, 109],
    hexColor: '11416d',
}, {
    dmcNumber: '820',
    anchorNumber: '133, 134',
    colorName: 'Royal Blue Very Dark',
    madeiraNumber: '904',
    gammaNumber: '3114',
    kirovaNumber: '2412',
    mayFlowerNumber: '2084',
    domeNumber: '310',
    belkaNumber: '496',
    rgbColor: [14, 54, 92],
    hexColor: '0e365c',
}, {
    dmcNumber: '162',
    anchorNumber: '160',
    colorName: 'Blue Ultra Very Light',
    madeiraNumber: '908',
    gammaNumber: '3243',
    belkaNumber: '1690',
    rgbColor: [219, 236, 245],
    hexColor: 'dbecf5',
}, {
    dmcNumber: '827',
    anchorNumber: '144, 160',
    colorName: 'Blue Very Light',
    madeiraNumber: '1002',
    gammaNumber: '3115',
    kirovaNumber: '2604, 60',
    dimensionsNumber: '17159',
    domeNumber: '326',
    belkaNumber: '2427',
    rgbColor: [189, 221, 237],
    hexColor: 'bddded',
}, {
    dmcNumber: '813',
    anchorNumber: '977',
    colorName: 'Blue Light',
    madeiraNumber: '1013, 1711',
    gammaNumber: '3116',
    kirovaNumber: '2606, 61',
    domeNumber: '325',
    belkaNumber: '1251',
    bucillaNumber: '813',
    rgbColor: [161, 194, 215],
    hexColor: 'a1c2d7',
}, {
    dmcNumber: '826',
    anchorNumber: '978',
    colorName: 'Blue Medium',
    madeiraNumber: '1012',
    gammaNumber: '512',
    kirovaNumber: '2506',
    dimensionsNumber: '17180',
    domeNumber: '322',
    belkaNumber: '649',
    rgbColor: [107, 158, 191],
    hexColor: '6b9ebf',
}, {
    dmcNumber: '825',
    anchorNumber: '146, 162',
    colorName: 'Blue Dark',
    madeiraNumber: '1011',
    gammaNumber: '84',
    kirovaNumber: '2508',
    dimensionsNumber: '17181',
    domeNumber: '321',
    belkaNumber: '1305',
    rgbColor: [71, 129, 165],
    hexColor: '4781a5',
}, {
    dmcNumber: '824',
    anchorNumber: '143, 148, 164',
    colorName: 'Blue Very Dark',
    madeiraNumber: '1010',
    gammaNumber: '0003, 5135',
    dimensionsNumber: '6007, 17182',
    domeNumber: '320',
    belkaNumber: '2687',
    rgbColor: [57, 105, 135],
    hexColor: '396987',
}, {
    dmcNumber: '996',
    anchorNumber: '433, 1090',
    colorName: 'Electric Blue Medium',
    madeiraNumber: '1103',
    gammaNumber: '3121',
    dimensionsNumber: '6996',
    domeNumber: '362',
    belkaNumber: '1929',
    bucillaNumber: '996',
    rgbColor: [48, 194, 236],
    hexColor: '30c2ec',
}, {
    dmcNumber: '3843',
    anchorNumber: '433, 1089',
    colorName: 'Electric Blue',
    madeiraNumber: '1103',
    gammaNumber: '3122',
    domeNumber: '360',
    belkaNumber: '937',
    rgbColor: [20, 170, 208],
    hexColor: '14aad0',
}, {
    dmcNumber: '995',
    anchorNumber: '410',
    colorName: 'Electric Blue Dark',
    madeiraNumber: '1102',
    gammaNumber: '86',
    dimensionsNumber: '17010',
    domeNumber: '361',
    belkaNumber: '2539',
    rgbColor: [38, 150, 182],
    hexColor: '2696b6',
}, {
    dmcNumber: '3846',
    anchorNumber: '433, 1090',
    colorName: 'Turquoise Bright Light',
    gammaNumber: '3126',
    domeNumber: '358',
    belkaNumber: '424',
    rgbColor: [6, 227, 230],
    hexColor: '06e3e6',
}, {
    dmcNumber: '3845',
    anchorNumber: '433, 1089',
    colorName: 'Turquoise Bright Med',
    gammaNumber: '87',
    dimensionsNumber: '6087, 17001',
    domeNumber: '357',
    belkaNumber: '3565',
    rgbColor: [4, 196, 202],
    hexColor: '04c4ca',
}, {
    dmcNumber: '3844',
    anchorNumber: '410',
    colorName: 'Turquoise Bright Dark',
    gammaNumber: '85',
    domeNumber: '356',
    belkaNumber: '2663',
    rgbColor: [18, 174, 186],
    hexColor: '12aeba',
}, {
    dmcNumber: '159',
    colorName: 'Blue Gray Light',
    madeiraNumber: '901',
    gammaNumber: '3049, 3241',
    belkaNumber: '1096',
    rgbColor: [199, 202, 215],
    hexColor: 'c7cad7',
}, {
    dmcNumber: '160',
    anchorNumber: '806',
    colorName: 'Blue Gray Medium',
    gammaNumber: '3050',
    belkaNumber: '1098',
    rgbColor: [153, 159, 183],
    hexColor: '999fb7',
}, {
    dmcNumber: '161',
    colorName: 'Blue Gray',
    gammaNumber: '3242',
    belkaNumber: '1539',
    rgbColor: [120, 128, 164],
    hexColor: '7880a4',
}, {
    dmcNumber: '3756',
    anchorNumber: '1037',
    colorName: 'Baby Blue Ult Vy Lt',
    madeiraNumber: '2504',
    gammaNumber: '752',
    mayFlowerNumber: '2091',
    domeNumber: '329',
    belkaNumber: '3207',
    bucillaNumber: '2601, 3756',
    rgbColor: [238, 252, 252],
    hexColor: 'eefcfc',
}, {
    dmcNumber: '775',
    anchorNumber: '128, 1031',
    colorName: 'Baby Blue Very Light',
    madeiraNumber: '1001',
    gammaNumber: '753',
    kirovaNumber: '2602',
    mayFlowerNumber: '2086',
    dimensionsNumber: '17031',
    domeNumber: '328',
    belkaNumber: '3144',
    rgbColor: [217, 235, 241],
    hexColor: 'd9ebf1',
}, {
    dmcNumber: '3841',
    anchorNumber: '9159',
    colorName: 'Baby Blue Pale',
    madeiraNumber: '1001',
    gammaNumber: '301',
    domeNumber: '379',
    belkaNumber: '650',
    rgbColor: [205, 223, 237],
    hexColor: 'cddfed',
}, {
    dmcNumber: '3325',
    anchorNumber: '129',
    colorName: 'Baby Blue Light',
    madeiraNumber: '909',
    gammaNumber: '3051',
    kirovaNumber: '2804',
    dimensionsNumber: '17976',
    domeNumber: '308',
    belkaNumber: '1882',
    bucillaNumber: '3325',
    rgbColor: [184, 210, 230],
    hexColor: 'b8d2e6',
}, {
    dmcNumber: '3755',
    anchorNumber: '140',
    colorName: 'Baby Blue',
    madeiraNumber: '909',
    gammaNumber: '3117',
    kirovaNumber: '2606, 61',
    domeNumber: '307',
    belkaNumber: '1257',
    rgbColor: [147, 180, 206],
    hexColor: '93b4ce',
}, {
    dmcNumber: '334',
    anchorNumber: '977',
    colorName: 'Baby Blue Medium',
    madeiraNumber: '1003',
    gammaNumber: '757',
    kirovaNumber: '2608, 62',
    dimensionsNumber: '6005, 17977',
    domeNumber: '306',
    belkaNumber: '3348',
    bucillaNumber: '6016',
    rgbColor: [115, 159, 193],
    hexColor: '739fc1',
}, {
    dmcNumber: '322',
    anchorNumber: '978',
    colorName: 'Baby Blue Dark',
    madeiraNumber: '1004',
    gammaNumber: '3052',
    kirovaNumber: '2608, 62',
    dimensionsNumber: '6003, 17978',
    domeNumber: '305',
    belkaNumber: '108',
    bucillaNumber: '6004',
    rgbColor: [90, 143, 184],
    hexColor: '5a8fb8',
}, {
    dmcNumber: '312',
    anchorNumber: '979',
    colorName: 'Baby Blue Very Dark',
    madeiraNumber: '1005',
    gammaNumber: '3118',
    kirovaNumber: '2610, 63',
    dimensionsNumber: '17979',
    domeNumber: '304',
    belkaNumber: '1729',
    bucillaNumber: '3312',
    rgbColor: [53, 102, 139],
    hexColor: '35668b',
}, {
    dmcNumber: '803',
    colorName: 'Baby Blue Ult Vy Dk',
    dimensionsNumber: '6012',
    belkaNumber: '1997',
    rgbColor: [44, 89, 124],
    hexColor: '2c597c',
}, {
    dmcNumber: '336',
    anchorNumber: '150',
    colorName: 'Navy Blue',
    madeiraNumber: '1006, 1007',
    gammaNumber: '26',
    kirovaNumber: '2614',
    dimensionsNumber: '17981',
    domeNumber: '302',
    belkaNumber: '3542',
    rgbColor: [37, 59, 115],
    hexColor: '253b73',
}, {
    dmcNumber: '823',
    anchorNumber: '127, 152',
    colorName: 'Navy Blue Dark',
    madeiraNumber: '1008',
    kirovaNumber: '2414, 181',
    dimensionsNumber: '17982',
    domeNumber: '301',
    belkaNumber: '249',
    rgbColor: [33, 48, 99],
    hexColor: '213063',
}, {
    dmcNumber: '939',
    anchorNumber: '152',
    colorName: 'Navy Blue Very Dark',
    madeiraNumber: '1009',
    gammaNumber: '3120',
    dimensionsNumber: '6001, 17160',
    domeNumber: '300',
    belkaNumber: '117',
    bucillaNumber: '6001',
    rgbColor: [27, 40, 83],
    hexColor: '1b2853',
}, {
    dmcNumber: '3753',
    anchorNumber: '1031, 1032',
    colorName: 'Antique Blue Ult Vy Lt',
    madeiraNumber: '1001',
    gammaNumber: '967',
    kirovaNumber: '2602',
    domeNumber: '647',
    belkaNumber: '2561',
    rgbColor: [219, 226, 233],
    hexColor: 'dbe2e9',
}, {
    dmcNumber: '3752',
    anchorNumber: '343',
    colorName: 'Antique Blue Very Lt',
    madeiraNumber: '1001',
    gammaNumber: '754',
    kirovaNumber: '2802',
    mayFlowerNumber: '2278',
    dimensionsNumber: '13083, 17049',
    domeNumber: '646',
    belkaNumber: '2895',
    bucillaNumber: '3752',
    rgbColor: [199, 209, 219],
    hexColor: 'c7d1db',
}, {
    dmcNumber: '932',
    anchorNumber: '920, 1033',
    colorName: 'Antique Blue Light',
    madeiraNumber: '1710',
    gammaNumber: '755',
    kirovaNumber: '2804',
    dimensionsNumber: '6165, 6932, 17050',
    domeNumber: '644',
    belkaNumber: '1899',
    bucillaNumber: '3932',
    rgbColor: [162, 181, 198],
    hexColor: 'a2b5c6',
}, {
    dmcNumber: '931',
    anchorNumber: '921, 1034',
    colorName: 'Antique Blue Medium',
    madeiraNumber: '1711',
    gammaNumber: '970',
    kirovaNumber: '2808, 69',
    dimensionsNumber: '6164, 6931, 17051',
    domeNumber: '643',
    belkaNumber: '1357',
    bucillaNumber: '3931',
    rgbColor: [106, 133, 158],
    hexColor: '6a859e',
}, {
    dmcNumber: '930',
    anchorNumber: '1035',
    colorName: 'Antique Blue Dark',
    madeiraNumber: '1712',
    gammaNumber: '971',
    kirovaNumber: '2810, 70',
    dimensionsNumber: '6163, 6930, 17052',
    domeNumber: '641',
    belkaNumber: '1658',
    bucillaNumber: '3930',
    rgbColor: [69, 92, 113],
    hexColor: '455c71',
}, {
    dmcNumber: '3750',
    anchorNumber: '1036',
    colorName: 'Antique Blue Very Dk',
    madeiraNumber: '1712',
    gammaNumber: '972',
    kirovaNumber: '2814, 71',
    domeNumber: '640',
    belkaNumber: '132',
    rgbColor: [56, 76, 94],
    hexColor: '384c5e',
}, {
    dmcNumber: '828',
    anchorNumber: '975, 9159',
    colorName: 'Sky Blue Vy Lt',
    madeiraNumber: '1014',
    gammaNumber: '3053',
    kirovaNumber: '2802',
    dimensionsNumber: '6018, 17053',
    domeNumber: '327',
    belkaNumber: '540',
    rgbColor: [197, 232, 237],
    hexColor: 'c5e8ed',
}, {
    dmcNumber: '3761',
    anchorNumber: '928',
    colorName: 'Sky Blue Light',
    madeiraNumber: '1014',
    gammaNumber: '3124',
    kirovaNumber: '2702',
    domeNumber: '335',
    belkaNumber: '1832',
    rgbColor: [172, 216, 226],
    hexColor: 'acd8e2',
}, {
    dmcNumber: '519',
    anchorNumber: '1038',
    colorName: 'Sky Blue',
    madeiraNumber: '1105, 1002',
    gammaNumber: '3054',
    kirovaNumber: '2502',
    dimensionsNumber: '6009',
    domeNumber: '334',
    belkaNumber: '1206',
    bucillaNumber: '6009',
    rgbColor: [126, 177, 200],
    hexColor: '7eb1c8',
}, {
    dmcNumber: '518',
    anchorNumber: '1039',
    colorName: 'Wedgewood Light',
    madeiraNumber: '1106',
    gammaNumber: '3055',
    kirovaNumber: '3304',
    dimensionsNumber: '6084, 17161',
    domeNumber: '333',
    belkaNumber: '2501',
    rgbColor: [79, 147, 167],
    hexColor: '4f93a7',
}, {
    dmcNumber: '3760',
    anchorNumber: '169, 977',
    colorName: 'Wedgewood Med',
    madeiraNumber: '1107',
    gammaNumber: '3125',
    mayFlowerNumber: '2285',
    dimensionsNumber: '17169, 17975',
    domeNumber: '332',
    belkaNumber: '940',
    rgbColor: [62, 133, 162],
    hexColor: '3e85a2',
}, {
    dmcNumber: '517',
    anchorNumber: '162',
    colorName: 'Wedgewood Dark',
    madeiraNumber: '1107',
    gammaNumber: '513',
    kirovaNumber: '2508',
    dimensionsNumber: '17162',
    domeNumber: '331',
    belkaNumber: '2041',
    bucillaNumber: '2712',
    rgbColor: [59, 118, 143],
    hexColor: '3b768f',
}, {
    dmcNumber: '3842',
    anchorNumber: '162, 164',
    colorName: 'Wedgewood Vry Dk',
    madeiraNumber: '1010',
    gammaNumber: '3126',
    dimensionsNumber: '6083',
    domeNumber: '330',
    belkaNumber: '697',
    rgbColor: [50, 102, 124],
    hexColor: '32667c',
}, {
    dmcNumber: '311',
    anchorNumber: '148',
    colorName: 'Wedgewood Ult VyDk',
    madeiraNumber: '1006',
    gammaNumber: '3119',
    kirovaNumber: '2612',
    dimensionsNumber: '6002, 17980',
    domeNumber: '303',
    belkaNumber: '3184',
    bucillaNumber: '311',
    rgbColor: [28, 80, 102],
    hexColor: '1c5066',
}, {
    dmcNumber: '747',
    anchorNumber: '158',
    colorName: 'Peacock Blue Vy Lt',
    madeiraNumber: '1104',
    gammaNumber: '852',
    dimensionsNumber: '12275',
    domeNumber: '337',
    belkaNumber: '1851',
    bucillaNumber: '6011',
    rgbColor: [229, 252, 253],
    hexColor: 'e5fcfd',
}, {
    dmcNumber: '3766',
    anchorNumber: '167',
    colorName: 'Peacock Blue Light',
    madeiraNumber: '1109',
    gammaNumber: '855',
    kirovaNumber: '3102',
    domeNumber: '354',
    belkaNumber: '1520',
    bucillaNumber: '3766',
    rgbColor: [153, 207, 217],
    hexColor: '99cfd9',
}, {
    dmcNumber: '807',
    anchorNumber: '168, 1039',
    colorName: 'Peacock Blue',
    madeiraNumber: '1105',
    gammaNumber: '3127',
    kirovaNumber: '3304',
    dimensionsNumber: '17168',
    domeNumber: '353',
    belkaNumber: '2623',
    bucillaNumber: '2174',
    rgbColor: [100, 171, 186],
    hexColor: '64abba',
}, {
    dmcNumber: '806',
    colorName: 'Peacock Blue Dark',
    rgbColor: [61, 149, 165],
    hexColor: '3d95a5',
}, {
    dmcNumber: '3765',
    anchorNumber: '169, 170',
    colorName: 'Peacock Blue Vy Dk',
    madeiraNumber: '2506',
    gammaNumber: '3129',
    kirovaNumber: '3306',
    domeNumber: '350',
    belkaNumber: '3862',
    bucillaNumber: '3765',
    rgbColor: [52, 127, 140],
    hexColor: '347f8c',
}, {
    dmcNumber: '3811',
    anchorNumber: '1060, 1092',
    colorName: 'Turquoise Very Light',
    madeiraNumber: '1101',
    gammaNumber: '0853, 6035',
    kirovaNumber: '3302',
    domeNumber: '347',
    belkaNumber: '2854',
    bucillaNumber: '2711',
    rgbColor: [188, 227, 230],
    hexColor: 'bce3e6',
}, {
    dmcNumber: '598',
    anchorNumber: '167, 1062',
    colorName: 'Turquoise Light',
    madeiraNumber: '1014, 1111',
    gammaNumber: '0854, 3130',
    kirovaNumber: '3402',
    dimensionsNumber: '17167',
    domeNumber: '345',
    belkaNumber: '2458',
    rgbColor: [144, 195, 204],
    hexColor: '90c3cc',
}, {
    dmcNumber: '597',
    anchorNumber: '1064',
    colorName: 'Turquoise',
    madeiraNumber: '1110',
    gammaNumber: '3056',
    kirovaNumber: '3404',
    domeNumber: '344',
    belkaNumber: '245',
    bucillaNumber: '6280',
    rgbColor: [91, 163, 179],
    hexColor: '5ba3b3',
}, {
    dmcNumber: '3810',
    anchorNumber: '168, 1068',
    colorName: 'Turquoise Dark',
    madeiraNumber: '1108',
    gammaNumber: '0856, 3057',
    kirovaNumber: '3404',
    domeNumber: '342',
    belkaNumber: '708',
    rgbColor: [72, 142, 154],
    hexColor: '488e9a',
}, {
    dmcNumber: '3809',
    anchorNumber: '1066',
    colorName: 'Turquoise Vy Dark',
    madeiraNumber: '2506',
    gammaNumber: '857',
    kirovaNumber: '3406',
    domeNumber: '341',
    belkaNumber: '3039',
    bucillaNumber: '3809',
    rgbColor: [63, 124, 133],
    hexColor: '3f7c85',
}, {
    dmcNumber: '3808',
    anchorNumber: '1068',
    colorName: 'Turquoise Ult Vy Dk',
    madeiraNumber: '2507',
    gammaNumber: '860',
    kirovaNumber: '3408',
    domeNumber: '340',
    belkaNumber: '2793',
    bucillaNumber: '3808',
    rgbColor: [54, 105, 112],
    hexColor: '366970',
}, {
    dmcNumber: '928',
    anchorNumber: '274',
    colorName: 'Gray Green Vy Lt',
    madeiraNumber: '1709',
    gammaNumber: '661',
    kirovaNumber: '7102',
    domeNumber: '369',
    belkaNumber: '2542',
    rgbColor: [221, 227, 227],
    hexColor: 'dde3e3',
}, {
    dmcNumber: '927',
    anchorNumber: '837, 838',
    colorName: 'Gray Green Light',
    madeiraNumber: '1708',
    gammaNumber: '0062, 0663',
    kirovaNumber: '6802, 112',
    mayFlowerNumber: '2166',
    dimensionsNumber: '17225',
    domeNumber: '368',
    belkaNumber: '898',
    rgbColor: [189, 203, 203],
    hexColor: 'bdcbcb',
}, {
    dmcNumber: '926',
    anchorNumber: '850',
    colorName: 'Gray Green Med',
    madeiraNumber: '1707',
    gammaNumber: '3146, 0664',
    dimensionsNumber: '17226',
    domeNumber: '367',
    belkaNumber: '2399',
    bucillaNumber: '6187',
    rgbColor: [152, 174, 174],
    hexColor: '98aeae',
}, {
    dmcNumber: '3768',
    anchorNumber: '799, 840',
    colorName: 'Gray Green Dark',
    madeiraNumber: '2508',
    gammaNumber: '0039, 0665',
    kirovaNumber: '7110',
    dimensionsNumber: '16007',
    domeNumber: '366',
    belkaNumber: '2360',
    bucillaNumber: '3768',
    rgbColor: [101, 127, 127],
    hexColor: '657f7f',
}, {
    dmcNumber: '924',
    anchorNumber: '851',
    colorName: 'Gray Green Vy Dark',
    madeiraNumber: '1706',
    gammaNumber: '666',
    kirovaNumber: '4204',
    dimensionsNumber: '16008, 17227',
    domeNumber: '364',
    belkaNumber: '2143',
    bucillaNumber: '924',
    rgbColor: [86, 106, 106],
    hexColor: '566a6a',
}, {
    dmcNumber: '3849',
    anchorNumber: '1070',
    colorName: 'Teal Green Light',
    madeiraNumber: '1111',
    gammaNumber: '3058',
    domeNumber: '292',
    belkaNumber: '2227',
    rgbColor: [82, 179, 164],
    hexColor: '52b3a4',
}, {
    dmcNumber: '3848',
    anchorNumber: '1074',
    colorName: 'Teal Green Med',
    madeiraNumber: '1109',
    gammaNumber: '3059',
    domeNumber: '291',
    belkaNumber: '1125',
    rgbColor: [85, 147, 146],
    hexColor: '559392',
}, {
    dmcNumber: '3847',
    anchorNumber: '1076',
    colorName: 'Teal Green Dark',
    madeiraNumber: '2507',
    gammaNumber: '3131',
    domeNumber: '290',
    belkaNumber: '3638',
    rgbColor: [52, 125, 117],
    hexColor: '347d75',
}, {
    dmcNumber: '964',
    anchorNumber: '185, 1092',
    colorName: 'Sea Green Light',
    madeiraNumber: '1112',
    gammaNumber: '3132',
    kirovaNumber: '3504, 72',
    dimensionsNumber: '6091, 16180, 16181',
    domeNumber: '387',
    belkaNumber: '872',
    bucillaNumber: '6091',
    rgbColor: [169, 226, 216],
    hexColor: 'a9e2d8',
}, {
    dmcNumber: '959',
    anchorNumber: '186',
    colorName: 'Sea Green Med',
    madeiraNumber: '1113',
    gammaNumber: '605',
    kirovaNumber: '3504, 72',
    dimensionsNumber: '6158, 16183, 16185',
    domeNumber: '385',
    belkaNumber: '519',
    bucillaNumber: '6089, 6090',
    rgbColor: [89, 199, 180],
    hexColor: '59c7b4',
}, {
    dmcNumber: '958',
    anchorNumber: '187',
    colorName: 'Sea Green Dark',
    madeiraNumber: '1114',
    gammaNumber: '606',
    kirovaNumber: '3508, 74',
    dimensionsNumber: '16186',
    domeNumber: '384',
    belkaNumber: '939',
    bucillaNumber: '958',
    rgbColor: [62, 182, 161],
    hexColor: '3eb6a1',
}, {
    dmcNumber: '3812',
    anchorNumber: '188, 189',
    colorName: 'Sea Green Vy Dk',
    madeiraNumber: '2705, 2706',
    gammaNumber: '3133, 6075',
    kirovaNumber: '3512, 76',
    dimensionsNumber: '16187',
    domeNumber: '381',
    belkaNumber: '791',
    rgbColor: [47, 140, 132],
    hexColor: '2f8c84',
}, {
    dmcNumber: '3851',
    anchorNumber: '187',
    colorName: 'Green Bright Lt',
    madeiraNumber: '1202',
    gammaNumber: '3134',
    domeNumber: '383',
    belkaNumber: '1637',
    rgbColor: [73, 179, 161],
    hexColor: '49b3a1',
}, {
    dmcNumber: '943',
    anchorNumber: '188, 189',
    colorName: 'Green Bright Md',
    madeiraNumber: '1203',
    gammaNumber: '3135',
    kirovaNumber: '3510, 75',
    domeNumber: '382',
    belkaNumber: '133',
    rgbColor: [61, 147, 132],
    hexColor: '3d9384',
}, {
    dmcNumber: '3850',
    anchorNumber: '189',
    colorName: 'Green Bright Dk',
    madeiraNumber: '1203',
    gammaNumber: '3136',
    belkaNumber: '3505',
    rgbColor: [55, 132, 119],
    hexColor: '378477',
}, {
    dmcNumber: '993',
    anchorNumber: '1070',
    colorName: 'Aquamarine Vy Lt',
    madeiraNumber: '1201',
    gammaNumber: '3137',
    domeNumber: '374',
    belkaNumber: '211',
    rgbColor: [144, 192, 180],
    hexColor: '90c0b4',
}, {
    dmcNumber: '992',
    anchorNumber: '1072',
    colorName: 'Aquamarine Lt',
    madeiraNumber: '1202',
    gammaNumber: '3060',
    kirovaNumber: '4104, 82',
    domeNumber: '373',
    belkaNumber: '2252',
    bucillaNumber: '992',
    rgbColor: [111, 174, 159],
    hexColor: '6fae9f',
}, {
    dmcNumber: '3814',
    anchorNumber: '1074',
    colorName: 'Aquamarine',
    madeiraNumber: '1203',
    gammaNumber: '3138',
    domeNumber: '371',
    belkaNumber: '3740',
    bucillaNumber: '6245',
    rgbColor: [80, 139, 125],
    hexColor: '508b7d',
}, {
    dmcNumber: '991',
    anchorNumber: '1076',
    colorName: 'Aquamarine Dk',
    madeiraNumber: '1204',
    gammaNumber: '3139',
    kirovaNumber: '3608',
    dimensionsNumber: '16212',
    domeNumber: '370',
    belkaNumber: '985',
    bucillaNumber: '991',
    rgbColor: [71, 123, 110],
    hexColor: '477b6e',
}, {
    dmcNumber: '966',
    anchorNumber: '206',
    colorName: 'Jade Ultra Vy Lt',
    madeiraNumber: '1209',
    gammaNumber: '3150',
    domeNumber: '219',
    belkaNumber: '2223',
    rgbColor: [185, 215, 192],
    hexColor: 'b9d7c0',
}, {
    dmcNumber: '564',
    anchorNumber: '206',
    colorName: 'Jade Very Light',
    madeiraNumber: '1208',
    gammaNumber: '4105',
    kirovaNumber: '4102',
    dimensionsNumber: '16209',
    domeNumber: '263',
    belkaNumber: '2911',
    rgbColor: [167, 205, 175],
    hexColor: 'a7cdaf',
}, {
    dmcNumber: '563',
    anchorNumber: '208',
    colorName: 'Jade Light',
    madeiraNumber: '1207',
    gammaNumber: '3141',
    kirovaNumber: '3902, 90',
    dimensionsNumber: '16210',
    domeNumber: '262',
    belkaNumber: '1227',
    bucillaNumber: '563',
    rgbColor: [143, 192, 152],
    hexColor: '8fc098',
}, {
    dmcNumber: '562',
    anchorNumber: '210, 216',
    colorName: 'Jade Medium',
    madeiraNumber: '1206',
    gammaNumber: '3141',
    dimensionsNumber: '16213',
    domeNumber: '261',
    belkaNumber: '166',
    rgbColor: [83, 151, 106],
    hexColor: '53976a',
}, {
    dmcNumber: '505',
    anchorNumber: '210',
    colorName: 'Jade Green',
    madeiraNumber: '1205',
    gammaNumber: '3246',
    belkaNumber: '3082',
    rgbColor: [51, 131, 98],
    hexColor: '338362',
}, {
    dmcNumber: '3817',
    anchorNumber: '875',
    colorName: 'Celadon Green Lt',
    madeiraNumber: '2604',
    gammaNumber: '3061',
    domeNumber: '269',
    belkaNumber: '2565',
    rgbColor: [153, 195, 170],
    hexColor: '99c3aa',
}, {
    dmcNumber: '3816',
    anchorNumber: '876',
    colorName: 'Celadon Green',
    gammaNumber: '3062',
    domeNumber: '268',
    belkaNumber: '3249',
    rgbColor: [101, 165, 125],
    hexColor: '65a57d',
}, {
    dmcNumber: '163',
    anchorNumber: '216, 878',
    colorName: 'Celadon Green Md',
    madeiraNumber: '1703',
    gammaNumber: '3063, 0956',
    belkaNumber: '2782',
    rgbColor: [77, 131, 97],
    hexColor: '4d8361',
}, {
    dmcNumber: '3815',
    anchorNumber: '877',
    colorName: 'Celadon Green Dk',
    madeiraNumber: '1703',
    gammaNumber: '0411, 3143',
    domeNumber: '267',
    belkaNumber: '112',
    rgbColor: [71, 119, 89],
    hexColor: '477759',
}, {
    dmcNumber: '561',
    anchorNumber: '212, 878, 18',
    colorName: 'Celadon Green VD',
    madeiraNumber: '1205, 1704',
    gammaNumber: '3142, 0958',
    kirovaNumber: '3604',
    dimensionsNumber: '16211',
    domeNumber: '260',
    belkaNumber: '1508',
    rgbColor: [44, 106, 69],
    hexColor: '2c6a45',
}, {
    dmcNumber: '504',
    colorName: 'Blue Green Vy Lt',
    rgbColor: [196, 222, 204],
    hexColor: 'c4decc',
}, {
    dmcNumber: '3813',
    anchorNumber: '875',
    colorName: 'Blue Green Lt',
    madeiraNumber: '1101',
    gammaNumber: '3144',
    domeNumber: '394',
    belkaNumber: '2456',
    rgbColor: [178, 212, 189],
    hexColor: 'b2d4bd',
}, {
    dmcNumber: '503',
    anchorNumber: '875, 876',
    colorName: 'Blue Green Med',
    madeiraNumber: '1702',
    gammaNumber: '954',
    dimensionsNumber: '16879',
    domeNumber: '393',
    belkaNumber: '2118',
    bucillaNumber: '6197',
    rgbColor: [123, 172, 148],
    hexColor: '7bac94',
}, {
    dmcNumber: '502',
    anchorNumber: '876, 877',
    colorName: 'Blue Green',
    madeiraNumber: '1703',
    gammaNumber: '3145',
    dimensionsNumber: '6197, 16876',
    domeNumber: '392',
    belkaNumber: '1343',
    bucillaNumber: '502',
    rgbColor: [91, 144, 113],
    hexColor: '5b9071',
}, {
    dmcNumber: '501',
    anchorNumber: '878, 217',
    colorName: 'Blue Green Dark',
    madeiraNumber: '1704',
    gammaNumber: '957',
    kirovaNumber: '3802',
    dimensionsNumber: '6195, 16878',
    domeNumber: '391',
    belkaNumber: '397',
    bucillaNumber: '6195',
    rgbColor: [57, 111, 82],
    hexColor: '396f52',
}, {
    dmcNumber: '500',
    anchorNumber: '683, 879',
    colorName: 'Blue Green Vy Dk',
    madeiraNumber: '1705',
    gammaNumber: '906',
    kirovaNumber: '3806',
    dimensionsNumber: '16880',
    domeNumber: '390',
    belkaNumber: '1477',
    bucillaNumber: '2780',
    rgbColor: [4, 77, 51],
    hexColor: '044d33',
}, {
    dmcNumber: '955',
    anchorNumber: '206',
    colorName: 'Nile Green Light',
    madeiraNumber: '1210',
    gammaNumber: '3147',
    dimensionsNumber: '6955, 16020',
    domeNumber: '217',
    belkaNumber: '22',
    rgbColor: [162, 214, 173],
    hexColor: 'a2d6ad',
}, {
    dmcNumber: '954',
    anchorNumber: '203, 241',
    colorName: 'Nile Green',
    madeiraNumber: '1211',
    gammaNumber: '502',
    kirovaNumber: '3904',
    dimensionsNumber: '6954, 16030',
    domeNumber: '216',
    belkaNumber: '983',
    bucillaNumber: '2242',
    rgbColor: [136, 186, 145],
    hexColor: '88ba91',
}, {
    dmcNumber: '913',
    anchorNumber: '204',
    colorName: 'Nile Green Med',
    madeiraNumber: '1212',
    gammaNumber: '3148',
    kirovaNumber: '3904',
    dimensionsNumber: '16225',
    domeNumber: '215',
    belkaNumber: '473',
    rgbColor: [109, 171, 119],
    hexColor: '6dab77',
}, {
    dmcNumber: '912',
    anchorNumber: '209',
    colorName: 'Emerald Green Lt',
    madeiraNumber: '1213',
    gammaNumber: '504',
    kirovaNumber: '3906, 83',
    dimensionsNumber: '6055',
    domeNumber: '214',
    belkaNumber: '580',
    bucillaNumber: '912',
    rgbColor: [27, 157, 107],
    hexColor: '1b9d6b',
}, {
    dmcNumber: '911',
    anchorNumber: '205',
    colorName: 'Emerald Green Med',
    madeiraNumber: '1214',
    gammaNumber: '15',
    mayFlowerNumber: '2120',
    dimensionsNumber: '16205',
    domeNumber: '213',
    belkaNumber: '1457',
    bucillaNumber: '2246',
    rgbColor: [24, 144, 101],
    hexColor: '189065',
}, {
    dmcNumber: '910',
    anchorNumber: '228, 229',
    colorName: 'Emerald Green Dark',
    madeiraNumber: '1301, 1302',
    gammaNumber: '88',
    kirovaNumber: '4108, 85',
    dimensionsNumber: '16031',
    domeNumber: '212',
    belkaNumber: '1489',
    bucillaNumber: '2763',
    rgbColor: [24, 126, 86],
    hexColor: '187e56',
}, {
    dmcNumber: '909',
    anchorNumber: '230, 923',
    colorName: 'Emerald Green Vy Dk',
    madeiraNumber: '1303',
    gammaNumber: '13',
    kirovaNumber: '4110, 86',
    mayFlowerNumber: '2121',
    domeNumber: '211',
    belkaNumber: '2620',
    rgbColor: [21, 111, 73],
    hexColor: '156f49',
}, {
    dmcNumber: '3818',
    anchorNumber: '211, 923',
    colorName: 'Emerald Grn Ult V Dk',
    madeiraNumber: '1205, 2704',
    gammaNumber: '0414, 3149',
    kirovaNumber: '4110, 86',
    domeNumber: '210',
    belkaNumber: '2482',
    rgbColor: [17, 90, 59],
    hexColor: '115a3b',
}, {
    dmcNumber: '369',
    anchorNumber: '1043',
    colorName: 'Pistachio Green Vy Lt',
    madeiraNumber: '1309',
    gammaNumber: '715',
    kirovaNumber: '4602, 178',
    dimensionsNumber: '6052, 16015, 16016',
    domeNumber: '249',
    belkaNumber: '1302',
    bucillaNumber: '369, 2240',
    rgbColor: [215, 237, 204],
    hexColor: 'd7edcc',
}, {
    dmcNumber: '368',
    anchorNumber: '214',
    colorName: 'Pistachio Green Lt',
    madeiraNumber: '1310',
    gammaNumber: '716',
    dimensionsNumber: '6051, 6368',
    domeNumber: '248',
    belkaNumber: '1725',
    bucillaNumber: '2782',
    rgbColor: [166, 194, 152],
    hexColor: 'a6c298',
}, {
    dmcNumber: '320',
    anchorNumber: '215',
    colorName: 'Pistachio Green Med',
    madeiraNumber: '1311',
    gammaNumber: '3151',
    dimensionsNumber: '6050, 16017',
    domeNumber: '247',
    belkaNumber: '265',
    bucillaNumber: '2783',
    rgbColor: [105, 136, 90],
    hexColor: '69885a',
}, {
    dmcNumber: '367',
    anchorNumber: '217',
    colorName: 'Pistachio Green Dk',
    madeiraNumber: '1312',
    gammaNumber: '89',
    mayFlowerNumber: '2296',
    dimensionsNumber: '6049, 6264, 6367, 16018',
    domeNumber: '246',
    belkaNumber: '1629',
    rgbColor: [97, 122, 82],
    hexColor: '617a52',
}, {
    dmcNumber: '319',
    anchorNumber: '218',
    colorName: 'Pistachio Grn Vy Dk',
    madeiraNumber: '1313',
    gammaNumber: '719',
    kirovaNumber: '3704',
    dimensionsNumber: '16246',
    domeNumber: '231',
    belkaNumber: '2164',
    bucillaNumber: '6048',
    rgbColor: [32, 95, 46],
    hexColor: '205f2e',
}, {
    dmcNumber: '890',
    anchorNumber: '218, 683',
    colorName: 'Pistachio Grn Ult V D',
    madeiraNumber: '1314',
    gammaNumber: '3152',
    kirovaNumber: '4202, 95',
    dimensionsNumber: '6048, 16021',
    domeNumber: '230',
    belkaNumber: '2989',
    rgbColor: [23, 73, 35],
    hexColor: '174923',
}, {
    dmcNumber: '164',
    anchorNumber: '240',
    colorName: 'Forest Green Lt',
    madeiraNumber: '1209',
    gammaNumber: '3252',
    dimensionsNumber: '16008',
    belkaNumber: '2700',
    rgbColor: [200, 216, 184],
    hexColor: 'c8d8b8',
}, {
    dmcNumber: '989',
    anchorNumber: '241, 242',
    colorName: 'Forest Green',
    madeiraNumber: '1401',
    gammaNumber: '33',
    kirovaNumber: '4006, 93',
    mayFlowerNumber: '2131',
    domeNumber: '243',
    belkaNumber: '2926',
    bucillaNumber: '989',
    rgbColor: [141, 166, 117],
    hexColor: '8da675',
}, {
    dmcNumber: '988',
    anchorNumber: '243',
    colorName: 'Forest Green Med',
    madeiraNumber: '1402',
    gammaNumber: '3153',
    kirovaNumber: '4006, 93',
    domeNumber: '242',
    belkaNumber: '1487',
    rgbColor: [115, 139, 91],
    hexColor: '738b5b',
}, {
    dmcNumber: '987',
    anchorNumber: '244',
    colorName: 'Forest Green Dk',
    madeiraNumber: '1403',
    gammaNumber: '718',
    kirovaNumber: '4010',
    dimensionsNumber: '6061, 16268',
    domeNumber: '241',
    belkaNumber: '2085',
    rgbColor: [88, 113, 65],
    hexColor: '587141',
}, {
    dmcNumber: '986',
    anchorNumber: '246',
    colorName: 'Forest Green Vy Dk',
    madeiraNumber: '1312, 1404',
    gammaNumber: '3154',
    kirovaNumber: '3912',
    dimensionsNumber: '6325',
    domeNumber: '240',
    belkaNumber: '387',
    bucillaNumber: '986',
    rgbColor: [64, 82, 48],
    hexColor: '405230',
}, {
    dmcNumber: '772',
    anchorNumber: '259',
    colorName: 'Yellow Green Vy Lt',
    madeiraNumber: '1604',
    gammaNumber: '500',
    kirovaNumber: '4002',
    dimensionsNumber: '16250',
    domeNumber: '289',
    belkaNumber: '1868',
    bucillaNumber: '772',
    rgbColor: [228, 236, 212],
    hexColor: 'e4ecd4',
}, {
    dmcNumber: '3348',
    anchorNumber: '254, 264',
    colorName: 'Yellow Green Lt',
    madeiraNumber: '1409, 1604',
    gammaNumber: '6115',
    kirovaNumber: '4004, 101',
    mayFlowerNumber: '2136',
    dimensionsNumber: '6068',
    domeNumber: '238',
    belkaNumber: '997',
    rgbColor: [204, 217, 177],
    hexColor: 'ccd9b1',
}, {
    dmcNumber: '3347',
    anchorNumber: '266',
    colorName: 'Yellow Green Med',
    madeiraNumber: '1408',
    gammaNumber: '614',
    kirovaNumber: '4604, 87',
    dimensionsNumber: '6063',
    domeNumber: '237',
    belkaNumber: '363',
    bucillaNumber: '6068',
    rgbColor: [113, 147, 92],
    hexColor: '71935c',
}, {
    dmcNumber: '3346',
    anchorNumber: '267',
    colorName: 'Hunter Green',
    madeiraNumber: '1407',
    gammaNumber: '3155',
    kirovaNumber: '4608, 89',
    dimensionsNumber: '6067',
    domeNumber: '236',
    belkaNumber: '2120',
    bucillaNumber: '6067',
    rgbColor: [64, 106, 58],
    hexColor: '406a3a',
}, {
    dmcNumber: '3345',
    anchorNumber: '268',
    colorName: 'Hunter Green Dk',
    madeiraNumber: '1406',
    gammaNumber: '3156',
    domeNumber: '235',
    belkaNumber: '2705',
    bucillaNumber: '2623',
    rgbColor: [27, 89, 21],
    hexColor: '1b5915',
}, {
    dmcNumber: '895',
    anchorNumber: '1044',
    colorName: 'Hunter Green Vy Dk',
    madeiraNumber: '1405, 1314',
    gammaNumber: '720',
    domeNumber: '234',
    belkaNumber: '871',
    rgbColor: [27, 83, 0],
    hexColor: '1b5300',
}, {
    dmcNumber: '704',
    anchorNumber: '256',
    colorName: 'Chartreuse Bright',
    madeiraNumber: '1308',
    gammaNumber: '207',
    mayFlowerNumber: '2137',
    dimensionsNumber: '6060, 6078',
    domeNumber: '225',
    belkaNumber: '1023',
    bucillaNumber: '2681',
    rgbColor: [158, 207, 52],
    hexColor: '9ecf34',
}, {
    dmcNumber: '703',
    anchorNumber: '238',
    colorName: 'Chartreuse',
    madeiraNumber: '1307',
    gammaNumber: '0209, 3157',
    mayFlowerNumber: '2124',
    dimensionsNumber: '16238',
    domeNumber: '224',
    belkaNumber: '379',
    rgbColor: [123, 181, 71],
    hexColor: '7bb547',
}, {
    dmcNumber: '702',
    anchorNumber: '226',
    colorName: 'Kelly Green',
    madeiraNumber: '1306',
    gammaNumber: '0319, 0210',
    kirovaNumber: '3908, 84',
    mayFlowerNumber: '2123',
    dimensionsNumber: '6059, 16239',
    domeNumber: '223',
    belkaNumber: '1443',
    bucillaNumber: '6060, 6077',
    rgbColor: [71, 167, 47],
    hexColor: '47a72f',
}, {
    dmcNumber: '701',
    anchorNumber: '227',
    colorName: 'Green Light',
    madeiraNumber: '1305',
    gammaNumber: '321',
    kirovaNumber: '3910, 94',
    dimensionsNumber: '16226, 16227',
    domeNumber: '222',
    belkaNumber: '520',
    rgbColor: [63, 143, 41],
    hexColor: '3f8f29',
}, {
    dmcNumber: '700',
    anchorNumber: '228, 229',
    colorName: 'Green Bright',
    madeiraNumber: '1304',
    gammaNumber: '14',
    kirovaNumber: '4108, 85',
    dimensionsNumber: '6058, 16227',
    domeNumber: '221',
    belkaNumber: '1181',
    rgbColor: [7, 115, 27],
    hexColor: '07731b',
}, {
    dmcNumber: '699',
    anchorNumber: '923',
    colorName: 'Green',
    madeiraNumber: '2704',
    gammaNumber: '3158',
    kirovaNumber: '4110, 86',
    dimensionsNumber: '6053, 16228',
    domeNumber: '220',
    belkaNumber: '1086',
    bucillaNumber: '6057',
    rgbColor: [5, 101, 23],
    hexColor: '056517',
}, {
    dmcNumber: '907',
    anchorNumber: '255',
    colorName: 'Parrot Green Lt',
    madeiraNumber: '1410',
    gammaNumber: '3159',
    kirovaNumber: '4706',
    mayFlowerNumber: '2125',
    dimensionsNumber: '6064, 16001',
    domeNumber: '253',
    belkaNumber: '2564',
    rgbColor: [199, 230, 102],
    hexColor: 'c7e666',
}, {
    dmcNumber: '906',
    anchorNumber: '256',
    colorName: 'Parrot Green Md',
    madeiraNumber: '1411',
    gammaNumber: '3160',
    mayFlowerNumber: '2138',
    dimensionsNumber: '16256',
    domeNumber: '252',
    belkaNumber: '2054',
    rgbColor: [127, 179, 53],
    hexColor: '7fb335',
}, {
    dmcNumber: '905',
    anchorNumber: '257',
    colorName: 'Parrot Green Dk',
    madeiraNumber: '1412',
    gammaNumber: '30',
    kirovaNumber: '4010',
    dimensionsNumber: '6062',
    domeNumber: '251',
    belkaNumber: '187',
    rgbColor: [98, 138, 40],
    hexColor: '628a28',
}, {
    dmcNumber: '904',
    anchorNumber: '258',
    colorName: 'Parrot Green V Dk',
    madeiraNumber: '1413',
    gammaNumber: '29',
    kirovaNumber: '3912',
    mayFlowerNumber: '2134',
    dimensionsNumber: '16267',
    domeNumber: '250',
    belkaNumber: '1307',
    rgbColor: [85, 120, 34],
    hexColor: '557822',
}, {
    dmcNumber: '472',
    anchorNumber: '254, 253',
    colorName: 'Avocado Grn U Lt',
    madeiraNumber: '1414',
    gammaNumber: '816',
    kirovaNumber: '4702',
    dimensionsNumber: '6069, 16253',
    domeNumber: '208',
    belkaNumber: '186',
    bucillaNumber: '2621, 6074',
    rgbColor: [216, 228, 152],
    hexColor: 'd8e498',
}, {
    dmcNumber: '471',
    anchorNumber: '266',
    colorName: 'Avocado Grn V Lt',
    madeiraNumber: '1501',
    gammaNumber: '8',
    kirovaNumber: '4806',
    dimensionsNumber: '16264',
    domeNumber: '207',
    belkaNumber: '3265',
    bucillaNumber: '2469',
    rgbColor: [174, 191, 121],
    hexColor: 'aebf79',
}, {
    dmcNumber: '470',
    anchorNumber: '267',
    colorName: 'Avocado Grn Lt',
    madeiraNumber: '1502',
    gammaNumber: '3161',
    kirovaNumber: '4504',
    dimensionsNumber: '16010',
    domeNumber: '206',
    belkaNumber: '2172',
    bucillaNumber: '2622',
    rgbColor: [148, 171, 79],
    hexColor: '94ab4f',
}, {
    dmcNumber: '469',
    anchorNumber: '267',
    colorName: 'Avocado Green',
    madeiraNumber: '1504',
    gammaNumber: '90',
    kirovaNumber: '4608, 89',
    dimensionsNumber: '16261',
    domeNumber: '205',
    belkaNumber: '1233',
    rgbColor: [114, 132, 60],
    hexColor: '72843c',
}, {
    dmcNumber: '937',
    anchorNumber: '268',
    colorName: 'Avocado Green Md',
    madeiraNumber: '1507',
    gammaNumber: '821',
    kirovaNumber: '4610, 179',
    dimensionsNumber: '16268',
    domeNumber: '203',
    belkaNumber: '805',
    rgbColor: [98, 113, 51],
    hexColor: '627133',
}, {
    dmcNumber: '936',
    anchorNumber: '269, 846',
    colorName: 'Avocado Grn V Dk',
    madeiraNumber: '1505',
    gammaNumber: '3162',
    kirovaNumber: '4508',
    mayFlowerNumber: '2146',
    dimensionsNumber: '6181, 16269',
    domeNumber: '202',
    belkaNumber: '1704',
    bucillaNumber: '6316',
    rgbColor: [76, 88, 38],
    hexColor: '4c5826',
}, {
    dmcNumber: '935',
    anchorNumber: '263, 861',
    colorName: 'Avocado Green Dk',
    madeiraNumber: '1508',
    gammaNumber: '3163',
    kirovaNumber: '4510',
    domeNumber: '201',
    belkaNumber: '309',
    rgbColor: [66, 77, 33],
    hexColor: '424d21',
}, {
    dmcNumber: '934',
    anchorNumber: '862, 1506',
    colorName: 'Avocado Grn Black',
    madeiraNumber: '1506, 1810',
    gammaNumber: '0155, 3164',
    kirovaNumber: '4308',
    dimensionsNumber: '16270',
    domeNumber: '200',
    belkaNumber: '2816',
    bucillaNumber: '2775',
    rgbColor: [49, 57, 25],
    hexColor: '313919',
}, {
    dmcNumber: '523',
    anchorNumber: '859',
    colorName: 'Fern Green Lt',
    madeiraNumber: '1512',
    gammaNumber: '3166',
    kirovaNumber: '4304',
    domeNumber: '273',
    belkaNumber: '2263',
    bucillaNumber: '6183',
    rgbColor: [171, 177, 151],
    hexColor: 'abb197',
}, {
    dmcNumber: '3053',
    anchorNumber: '261, 858',
    colorName: 'Green Gray',
    madeiraNumber: '1510',
    gammaNumber: '91',
    kirovaNumber: '4304',
    dimensionsNumber: '16315',
    domeNumber: '279',
    belkaNumber: '2357',
    rgbColor: [156, 164, 130],
    hexColor: '9ca482',
}, {
    dmcNumber: '3052',
    anchorNumber: '262, 859',
    colorName: 'Green Gray Md',
    madeiraNumber: '1513',
    gammaNumber: '3064',
    kirovaNumber: '4404',
    domeNumber: '278',
    belkaNumber: '482',
    rgbColor: [136, 146, 104],
    hexColor: '889268',
}, {
    dmcNumber: '3051',
    anchorNumber: '681',
    colorName: 'Green Gray Dk',
    madeiraNumber: '1508',
    gammaNumber: '35',
    kirovaNumber: '4610, 179',
    domeNumber: '277',
    belkaNumber: '277',
    bucillaNumber: '6181',
    rgbColor: [95, 102, 72],
    hexColor: '5f6648',
}, {
    dmcNumber: '524',
    anchorNumber: '858',
    colorName: 'Fern Green Vy Lt',
    madeiraNumber: '1511',
    gammaNumber: '3165',
    domeNumber: '274',
    belkaNumber: '1139',
    bucillaNumber: '524',
    rgbColor: [196, 205, 172],
    hexColor: 'c4cdac',
}, {
    dmcNumber: '522',
    anchorNumber: '860',
    colorName: 'Fern Green',
    madeiraNumber: '1512',
    gammaNumber: '3065',
    kirovaNumber: '4304',
    dimensionsNumber: '16316',
    domeNumber: '272',
    belkaNumber: '2110',
    bucillaNumber: '522',
    rgbColor: [150, 158, 126],
    hexColor: '969e7e',
}, {
    dmcNumber: '520',
    anchorNumber: '861, 862',
    colorName: 'Fern Green Dark',
    madeiraNumber: '2603',
    gammaNumber: '3167',
    kirovaNumber: '4306',
    mayFlowerNumber: '2297',
    domeNumber: '270',
    belkaNumber: '860',
    rgbColor: [102, 109, 79],
    hexColor: '666d4f',
}, {
    dmcNumber: '3364',
    anchorNumber: '260',
    colorName: 'Pine Green',
    madeiraNumber: '1509',
    gammaNumber: '3168',
    kirovaNumber: '3604',
    dimensionsNumber: '16266',
    domeNumber: '282',
    belkaNumber: '2644',
    rgbColor: [131, 151, 95],
    hexColor: '83975f',
}, {
    dmcNumber: '3363',
    anchorNumber: '262, 263',
    colorName: 'Pine Green Md',
    madeiraNumber: '1602',
    gammaNumber: '36',
    kirovaNumber: '4606, 88',
    dimensionsNumber: '16019, 16317',
    domeNumber: '281',
    belkaNumber: '552',
    bucillaNumber: '6182',
    rgbColor: [114, 130, 86],
    hexColor: '728256',
}, {
    dmcNumber: '3362',
    anchorNumber: '236',
    colorName: 'Pine Green Dk',
    madeiraNumber: '1601, 2603',
    gammaNumber: '823',
    kirovaNumber: '3702',
    dimensionsNumber: '16318',
    domeNumber: '280',
    belkaNumber: '2632',
    rgbColor: [94, 107, 71],
    hexColor: '5e6b47',
}, {
    dmcNumber: '165',
    anchorNumber: '802',
    colorName: 'Moss Green Vy Lt',
    madeiraNumber: '1414',
    gammaNumber: '3244',
    belkaNumber: '1140',
    rgbColor: [239, 244, 164],
    hexColor: 'eff4a4',
}, {
    dmcNumber: '3819',
    anchorNumber: '278',
    colorName: 'Moss Green Lt',
    madeiraNumber: '2303',
    gammaNumber: '817',
    kirovaNumber: '4802',
    mayFlowerNumber: '2157',
    domeNumber: '259',
    belkaNumber: '2774',
    rgbColor: [224, 232, 104],
    hexColor: 'e0e868',
}, {
    dmcNumber: '166',
    anchorNumber: '265, 278',
    colorName: 'Moss Green Md Lt',
    madeiraNumber: '1308, 2703',
    gammaNumber: '0818, 3245',
    belkaNumber: '2005',
    rgbColor: [192, 200, 64],
    hexColor: 'c0c840',
}, {
    dmcNumber: '581',
    anchorNumber: '280',
    colorName: 'Moss Green',
    madeiraNumber: '1502',
    gammaNumber: '3169',
    kirovaNumber: '4502',
    domeNumber: '257',
    belkaNumber: '200',
    rgbColor: [167, 174, 56],
    hexColor: 'a7ae38',
}, {
    dmcNumber: '580',
    anchorNumber: '281, 924',
    colorName: 'Moss Green Dk',
    madeiraNumber: '1503',
    gammaNumber: '3170, 0820',
    domeNumber: '256',
    belkaNumber: '819',
    rgbColor: [136, 141, 51],
    hexColor: '888d33',
}, {
    dmcNumber: '734',
    anchorNumber: '279',
    colorName: 'Olive Green Lt',
    madeiraNumber: '1610',
    gammaNumber: '0017, 0653',
    kirovaNumber: '5002',
    domeNumber: '867',
    belkaNumber: '2568',
    rgbColor: [199, 192, 119],
    hexColor: 'c7c077',
}, {
    dmcNumber: '733',
    anchorNumber: '280',
    colorName: 'Olive Green Md',
    madeiraNumber: '1611',
    gammaNumber: '10',
    domeNumber: '866',
    belkaNumber: '786',
    rgbColor: [188, 179, 76],
    hexColor: 'bcb34c',
}, {
    dmcNumber: '732',
    anchorNumber: '281',
    colorName: 'Olive Green',
    madeiraNumber: '1612',
    gammaNumber: '3171',
    domeNumber: '862',
    belkaNumber: '812',
    rgbColor: [148, 140, 54],
    hexColor: '948c36',
}, {
    dmcNumber: '731',
    colorName: 'Olive Green Dk',
    rgbColor: [147, 139, 55],
    hexColor: '938b37',
}, {
    dmcNumber: '730',
    anchorNumber: '845, 924',
    colorName: 'Olive Green V Dk',
    madeiraNumber: '1614',
    gammaNumber: '3173',
    kirovaNumber: '4506',
    dimensionsNumber: '16845',
    domeNumber: '860',
    belkaNumber: '611',
    rgbColor: [130, 123, 48],
    hexColor: '827b30',
}, {
    dmcNumber: '3013',
    anchorNumber: '842, 853',
    colorName: 'Khaki Green Lt',
    madeiraNumber: '1511',
    gammaNumber: '3066',
    mayFlowerNumber: '2154',
    dimensionsNumber: '16842',
    domeNumber: '287',
    belkaNumber: '2618',
    bucillaNumber: '3013',
    rgbColor: [185, 185, 130],
    hexColor: 'b9b982',
}, {
    dmcNumber: '3012',
    anchorNumber: '844',
    colorName: 'Khaki Green Md',
    madeiraNumber: '1605',
    gammaNumber: '3067',
    dimensionsNumber: '16843',
    domeNumber: '286',
    belkaNumber: '2294',
    bucillaNumber: '3012',
    rgbColor: [166, 167, 93],
    hexColor: 'a6a75d',
}, {
    dmcNumber: '3011',
    anchorNumber: '845, 846',
    colorName: 'Khaki Green Dk',
    madeiraNumber: '1507',
    gammaNumber: '3174',
    kirovaNumber: '5104',
    domeNumber: '285',
    belkaNumber: '1141',
    rgbColor: [137, 138, 88],
    hexColor: '898a58',
}, {
    dmcNumber: '372',
    anchorNumber: '853',
    colorName: 'Mustard Lt',
    madeiraNumber: '2110',
    gammaNumber: '92',
    domeNumber: '823',
    belkaNumber: '123',
    rgbColor: [204, 183, 132],
    hexColor: 'ccb784',
}, {
    dmcNumber: '371',
    anchorNumber: '854, 855',
    colorName: 'Mustard',
    madeiraNumber: '2111',
    gammaNumber: '3175',
    domeNumber: '821',
    belkaNumber: '2040',
    rgbColor: [191, 166, 113],
    hexColor: 'bfa671',
}, {
    dmcNumber: '370',
    anchorNumber: '855, 856',
    colorName: 'Mustard Medium',
    madeiraNumber: '2112, 2110',
    gammaNumber: '3068',
    kirovaNumber: '5202',
    domeNumber: '820',
    belkaNumber: '3469',
    rgbColor: [184, 157, 100],
    hexColor: 'b89d64',
}, {
    dmcNumber: '834',
    anchorNumber: '874, 945',
    colorName: 'Golden Olive Vy Lt',
    madeiraNumber: '2206',
    gammaNumber: '3069',
    domeNumber: '858',
    belkaNumber: '2683',
    rgbColor: [219, 190, 127],
    hexColor: 'dbbe7f',
}, {
    dmcNumber: '833',
    anchorNumber: '907',
    colorName: 'Golden Olive Lt',
    madeiraNumber: '2202',
    gammaNumber: '3176',
    kirovaNumber: '5002',
    mayFlowerNumber: '2159',
    domeNumber: '857',
    belkaNumber: '1073',
    rgbColor: [200, 171, 108],
    hexColor: 'c8ab6c',
}, {
    dmcNumber: '832',
    anchorNumber: '907',
    colorName: 'Golden Olive',
    madeiraNumber: '2202',
    gammaNumber: '654',
    kirovaNumber: '5302, 126',
    domeNumber: '856',
    belkaNumber: '2686',
    rgbColor: [189, 155, 81],
    hexColor: 'bd9b51',
}, {
    dmcNumber: '831',
    anchorNumber: '277',
    colorName: 'Golden Olive Md',
    madeiraNumber: '2201',
    kirovaNumber: '5202',
    domeNumber: '854',
    belkaNumber: '2547',
    rgbColor: [170, 143, 86],
    hexColor: 'aa8f56',
}, {
    dmcNumber: '830',
    anchorNumber: '277, 889',
    colorName: 'Golden Olive Dk',
    madeiraNumber: '2114, 2112',
    gammaNumber: '3177, 0655',
    kirovaNumber: '5304',
    domeNumber: '853',
    belkaNumber: '682',
    rgbColor: [141, 120, 75],
    hexColor: '8d784b',
}, {
    dmcNumber: '829',
    anchorNumber: '906',
    colorName: 'Golden Olive Vy Dk',
    madeiraNumber: '2113, 2114',
    gammaNumber: '3178',
    kirovaNumber: '5306',
    domeNumber: '850',
    belkaNumber: '1777',
    rgbColor: [126, 107, 66],
    hexColor: '7e6b42',
}, {
    dmcNumber: '613',
    anchorNumber: '831',
    colorName: 'Drab Brown V Lt',
    madeiraNumber: '1902, 2109',
    gammaNumber: '3179',
    kirovaNumber: '6602',
    domeNumber: '848',
    belkaNumber: '3024',
    rgbColor: [220, 196, 170],
    hexColor: 'dcc4aa',
}, {
    dmcNumber: '612',
    anchorNumber: '832',
    colorName: 'Drab Brown Lt',
    madeiraNumber: '2108',
    gammaNumber: '3108',
    domeNumber: '845',
    belkaNumber: '2401',
    bucillaNumber: '3612',
    rgbColor: [188, 154, 120],
    hexColor: 'bc9a78',
}, {
    dmcNumber: '611',
    anchorNumber: '898',
    colorName: 'Drab Brown',
    madeiraNumber: '1903, 2107',
    gammaNumber: '917',
    kirovaNumber: '6610',
    domeNumber: '843',
    belkaNumber: '870',
    bucillaNumber: '3611',
    rgbColor: [150, 118, 86],
    hexColor: '967656',
}, {
    dmcNumber: '610',
    anchorNumber: '898',
    colorName: 'Drab Brown Dk',
    madeiraNumber: '2106',
    gammaNumber: '3181',
    kirovaNumber: '6610',
    dimensionsNumber: '15889',
    domeNumber: '840',
    belkaNumber: '764',
    bucillaNumber: '3610',
    rgbColor: [121, 96, 71],
    hexColor: '796047',
}, {
    dmcNumber: '3047',
    anchorNumber: '852, 853',
    colorName: 'Yellow Beige Lt',
    madeiraNumber: '2205',
    gammaNumber: '95',
    dimensionsNumber: '12300',
    domeNumber: '839',
    belkaNumber: '467',
    bucillaNumber: '3047',
    rgbColor: [231, 214, 193],
    hexColor: 'e7d6c1',
}, {
    dmcNumber: '3046',
    anchorNumber: '887',
    colorName: 'Yellow Beige Md',
    madeiraNumber: '2206',
    gammaNumber: '93',
    dimensionsNumber: '12410',
    domeNumber: '838',
    belkaNumber: '3016',
    bucillaNumber: '3046',
    rgbColor: [216, 188, 154],
    hexColor: 'd8bc9a',
}, {
    dmcNumber: '3045',
    anchorNumber: '888',
    colorName: 'Yellow Beige Dk',
    madeiraNumber: '2103',
    gammaNumber: '3018',
    dimensionsNumber: '12412',
    domeNumber: '836',
    belkaNumber: '1164',
    bucillaNumber: '3045',
    rgbColor: [188, 150, 106],
    hexColor: 'bc966a',
}, {
    dmcNumber: '167',
    anchorNumber: '375',
    colorName: 'Yellow Beige V Dk',
    madeiraNumber: '2104',
    gammaNumber: '3253',
    belkaNumber: '120',
    rgbColor: [167, 124, 73],
    hexColor: 'a77c49',
}, {
    dmcNumber: '746',
    anchorNumber: '275',
    colorName: 'Off White',
    madeiraNumber: '101',
    gammaNumber: '3187',
    mayFlowerNumber: '2001',
    dimensionsNumber: '6746, 15387',
    domeNumber: '538',
    belkaNumber: '2848',
    bucillaNumber: '746',
    rgbColor: [252, 252, 238],
    hexColor: 'fcfcee',
}, {
    dmcNumber: '677',
    anchorNumber: '886',
    colorName: 'Old Gold Vy Lt',
    madeiraNumber: '2207',
    gammaNumber: '96',
    kirovaNumber: '0502, 4',
    domeNumber: '535',
    belkaNumber: '2229',
    bucillaNumber: '677',
    rgbColor: [245, 236, 203],
    hexColor: 'f5eccb',
}, {
    dmcNumber: '422',
    anchorNumber: '943',
    colorName: 'Hazelnut Brown Lt',
    madeiraNumber: '2102',
    kirovaNumber: '5502',
    mayFlowerNumber: '2202',
    domeNumber: '835',
    belkaNumber: '1982',
    rgbColor: [198, 159, 123],
    hexColor: 'c69f7b',
}, {
    dmcNumber: '3828',
    anchorNumber: '373',
    colorName: 'Hazelnut Brown',
    madeiraNumber: '2109',
    gammaNumber: '3182',
    domeNumber: '834',
    belkaNumber: '1171',
    rgbColor: [183, 139, 97],
    hexColor: 'b78b61',
}, {
    dmcNumber: '420',
    anchorNumber: '374, 375',
    colorName: 'Hazelnut Brown Dk',
    madeiraNumber: '2104, 2105',
    gammaNumber: '3108',
    mayFlowerNumber: '2205',
    dimensionsNumber: '15374',
    domeNumber: '833',
    belkaNumber: '173',
    bucillaNumber: '6159',
    rgbColor: [160, 112, 66],
    hexColor: 'a07042',
}, {
    dmcNumber: '869',
    anchorNumber: '944',
    colorName: 'Hazelnut Brown V Dk',
    madeiraNumber: '2105, 2113',
    gammaNumber: '3183',
    kirovaNumber: '5306',
    domeNumber: '830',
    belkaNumber: '2410',
    bucillaNumber: '869',
    rgbColor: [131, 94, 57],
    hexColor: '835e39',
}, {
    dmcNumber: '728',
    anchorNumber: '306',
    colorName: 'Topaz',
    madeiraNumber: '2509',
    gammaNumber: '3248',
    belkaNumber: '1752',
    rgbColor: [228, 180, 104],
    hexColor: 'e4b468',
}, {
    dmcNumber: '783',
    anchorNumber: '307, 890',
    colorName: 'Topaz Medium',
    madeiraNumber: '2204, 2514',
    gammaNumber: '0097, 0214',
    kirovaNumber: '0406, 175',
    mayFlowerNumber: '2217',
    dimensionsNumber: '6113, 15307',
    domeNumber: '553',
    belkaNumber: '668',
    bucillaNumber: '2593',
    rgbColor: [206, 145, 36],
    hexColor: 'ce9124',
}, {
    dmcNumber: '782',
    anchorNumber: '308',
    colorName: 'Topaz Dark',
    madeiraNumber: '2210',
    gammaNumber: '3185',
    kirovaNumber: '5506, 127',
    mayFlowerNumber: '2197',
    domeNumber: '552',
    belkaNumber: '134',
    rgbColor: [174, 119, 32],
    hexColor: 'ae7720',
}, {
    dmcNumber: '781',
    colorName: 'Topaz Very Dark',
    rgbColor: [162, 109, 32],
    hexColor: 'a26d20',
}, {
    dmcNumber: '780',
    anchorNumber: '310',
    colorName: 'Topaz Ultra Vy Dk',
    madeiraNumber: '2214',
    gammaNumber: '3186, 0216',
    kirovaNumber: '5908, 123',
    dimensionsNumber: '15308, 15309',
    domeNumber: '550',
    belkaNumber: '1610',
    rgbColor: [148, 99, 26],
    hexColor: '94631a',
}, {
    dmcNumber: '676',
    anchorNumber: '361, 891',
    colorName: 'Old Gold Lt',
    madeiraNumber: '2208',
    gammaNumber: '2125',
    kirovaNumber: '0402',
    mayFlowerNumber: '2240',
    dimensionsNumber: '12305, 12874',
    domeNumber: '534',
    belkaNumber: '489',
    bucillaNumber: '3676',
    rgbColor: [229, 206, 151],
    hexColor: 'e5ce97',
}, {
    dmcNumber: '729',
    anchorNumber: '890',
    colorName: 'Old Gold Medium',
    madeiraNumber: '2204, 2209',
    gammaNumber: '3019',
    kirovaNumber: '5504',
    mayFlowerNumber: '2212',
    dimensionsNumber: '12875',
    domeNumber: '532',
    belkaNumber: '2586',
    bucillaNumber: '6228',
    rgbColor: [208, 165, 62],
    hexColor: 'd0a53e',
}, {
    dmcNumber: '680',
    anchorNumber: '901',
    colorName: 'Old Gold Dark',
    madeiraNumber: '2210',
    gammaNumber: '3188',
    kirovaNumber: '5004, 125',
    dimensionsNumber: '12876',
    domeNumber: '531',
    belkaNumber: '2472',
    bucillaNumber: '6227',
    rgbColor: [188, 141, 14],
    hexColor: 'bc8d0e',
}, {
    dmcNumber: '3829',
    anchorNumber: '901',
    colorName: 'Old Gold Vy Dark',
    madeiraNumber: '2210',
    gammaNumber: '3189',
    kirovaNumber: '5004, 125',
    domeNumber: '530',
    belkaNumber: '557',
    rgbColor: [169, 130, 4],
    hexColor: 'a98204',
}, {
    dmcNumber: '3822',
    anchorNumber: '295',
    colorName: 'Straw Light',
    madeiraNumber: '112',
    gammaNumber: '99',
    kirovaNumber: '0302',
    mayFlowerNumber: '2214',
    domeNumber: '543',
    belkaNumber: '2378',
    bucillaNumber: '3822',
    rgbColor: [246, 220, 152],
    hexColor: 'f6dc98',
}, {
    dmcNumber: '3821',
    anchorNumber: '305',
    colorName: 'Straw',
    madeiraNumber: '2510',
    gammaNumber: '3190',
    kirovaNumber: '0304',
    mayFlowerNumber: '2239',
    domeNumber: '542',
    belkaNumber: '1528',
    rgbColor: [243, 206, 117],
    hexColor: 'f3ce75',
}, {
    dmcNumber: '3820',
    anchorNumber: '305, 907',
    colorName: 'Straw Dark',
    madeiraNumber: '2509',
    gammaNumber: '3020',
    kirovaNumber: '0404, 174',
    domeNumber: '541',
    belkaNumber: '2289',
    bucillaNumber: '3820',
    rgbColor: [223, 182, 95],
    hexColor: 'dfb65f',
}, {
    dmcNumber: '3852',
    anchorNumber: '306, 307',
    colorName: 'Straw Very Dark',
    madeiraNumber: '2203',
    gammaNumber: '3191',
    domeNumber: '540',
    belkaNumber: '156',
    rgbColor: [205, 157, 55],
    hexColor: 'cd9d37',
}, {
    dmcNumber: '445',
    anchorNumber: '288, 292, 802',
    colorName: 'Lemon Light',
    madeiraNumber: '0103, 0102',
    gammaNumber: '0041, 5140, 0514',
    kirovaNumber: '0202, 2',
    dimensionsNumber: '6132, 12288',
    domeNumber: '523',
    belkaNumber: '94',
    bucillaNumber: '6129',
    rgbColor: [255, 251, 139],
    hexColor: 'fffb8b',
}, {
    dmcNumber: '307',
    anchorNumber: '289',
    colorName: 'Lemon',
    madeiraNumber: '104',
    gammaNumber: '3192',
    kirovaNumber: '0206, 3',
    dimensionsNumber: '6131, 12290',
    domeNumber: '522',
    belkaNumber: '2794',
    rgbColor: [253, 237, 84],
    hexColor: 'fded54',
}, {
    dmcNumber: '973',
    anchorNumber: '297, 298',
    colorName: 'Canary Bright',
    madeiraNumber: '0105, 0106',
    gammaNumber: '0042, 5165',
    mayFlowerNumber: '2004',
    domeNumber: '521',
    belkaNumber: '648',
    bucillaNumber: '2662',
    rgbColor: [255, 227, 0],
    hexColor: 'ffe300',
}, {
    dmcNumber: '444',
    anchorNumber: '290, 297',
    colorName: 'Lemon Dark',
    madeiraNumber: '106',
    gammaNumber: '516',
    dimensionsNumber: '6130',
    domeNumber: '520',
    belkaNumber: '1835',
    rgbColor: [255, 214, 0],
    hexColor: 'ffd600',
}, {
    dmcNumber: '3078',
    anchorNumber: '292',
    colorName: 'Golden Yellow Vy Lt',
    madeiraNumber: '102',
    gammaNumber: '101',
    kirovaNumber: '0202, 2',
    dimensionsNumber: '6123',
    domeNumber: '524',
    belkaNumber: '1869',
    rgbColor: [253, 249, 205],
    hexColor: 'fdf9cd',
}, {
    dmcNumber: '727',
    anchorNumber: '293',
    colorName: 'Topaz Vy Lt',
    madeiraNumber: '0102, 0110',
    gammaNumber: '16',
    kirovaNumber: '0204',
    dimensionsNumber: '12292',
    domeNumber: '529',
    belkaNumber: '428',
    rgbColor: [255, 241, 175],
    hexColor: 'fff1af',
}, {
    dmcNumber: '726',
    anchorNumber: '297, 305',
    colorName: 'Topaz Light',
    madeiraNumber: '108',
    gammaNumber: '3193',
    dimensionsNumber: '6128, 12295',
    domeNumber: '528',
    belkaNumber: '124',
    bucillaNumber: '726',
    rgbColor: [253, 215, 85],
    hexColor: 'fdd755',
}, {
    dmcNumber: '725',
    anchorNumber: '305, 306',
    colorName: 'Topaz Med Lt',
    madeiraNumber: '0106, 0109',
    gammaNumber: '3194',
    kirovaNumber: '0506, 6',
    dimensionsNumber: '6127, 12298',
    domeNumber: '527',
    belkaNumber: '759',
    bucillaNumber: '725',
    rgbColor: [255, 200, 64],
    hexColor: 'ffc840',
}, {
    dmcNumber: '972',
    anchorNumber: '298',
    colorName: 'Canary Deep',
    madeiraNumber: '0107, 2513',
    gammaNumber: '3197',
    kirovaNumber: '0508, 7',
    mayFlowerNumber: '2241',
    domeNumber: '518',
    belkaNumber: '647',
    rgbColor: [255, 181, 21],
    hexColor: 'ffb515',
}, {
    dmcNumber: '745',
    anchorNumber: '300',
    colorName: 'Yellow Pale Light',
    madeiraNumber: '112',
    gammaNumber: '21',
    kirovaNumber: '0502, 4',
    dimensionsNumber: '6122, 12296',
    domeNumber: '508',
    belkaNumber: '1247',
    bucillaNumber: '2572',
    rgbColor: [255, 233, 173],
    hexColor: 'ffe9ad',
}, {
    dmcNumber: '744',
    anchorNumber: '301',
    colorName: 'Yellow Pale',
    madeiraNumber: '109',
    gammaNumber: '20',
    kirovaNumber: '0504, 5',
    dimensionsNumber: '6121, 12293',
    domeNumber: '507',
    belkaNumber: '97',
    bucillaNumber: '6122',
    rgbColor: [255, 231, 147],
    hexColor: 'ffe793',
}, {
    dmcNumber: '743',
    anchorNumber: '302',
    colorName: 'Yellow Med',
    madeiraNumber: '113',
    gammaNumber: '104',
    kirovaNumber: '0506, 6',
    mayFlowerNumber: '2006',
    dimensionsNumber: '12302',
    domeNumber: '506',
    belkaNumber: '2317',
    bucillaNumber: '6121',
    rgbColor: [254, 211, 118],
    hexColor: 'fed376',
}, {
    dmcNumber: '742',
    anchorNumber: '303',
    colorName: 'Tangerine Light',
    madeiraNumber: '114',
    gammaNumber: '19',
    kirovaNumber: '0510, 8',
    dimensionsNumber: '6126, 12303',
    domeNumber: '505',
    belkaNumber: '2617',
    bucillaNumber: '2576, 6126',
    rgbColor: [255, 191, 87],
    hexColor: 'ffbf57',
}, {
    dmcNumber: '741',
    anchorNumber: '314',
    colorName: 'Tangerine Med',
    madeiraNumber: '0201, 0114, 0203',
    gammaNumber: '0105, 3195',
    kirovaNumber: '0606, 16',
    dimensionsNumber: '6119, 12314',
    domeNumber: '504',
    belkaNumber: '1943',
    bucillaNumber: '2664',
    rgbColor: [255, 163, 43],
    hexColor: 'ffa32b',
}, {
    dmcNumber: '740',
    anchorNumber: '202',
    colorName: 'Tangerine',
    madeiraNumber: '971',
    gammaNumber: '316',
    kirovaNumber: '3198',
    mayFlowerNumber: '0608, 17',
    domeNumber: '6118, 6125',
    belkaNumber: '516',
    rgbColor: [255, 139, 0],
    hexColor: 'ff8b00',
}, {
    dmcNumber: '970',
    anchorNumber: '330',
    colorName: 'Pumpkin Light',
    madeiraNumber: '204',
    gammaNumber: '3199',
    kirovaNumber: '0708',
    dimensionsNumber: '12327',
    domeNumber: '515',
    belkaNumber: '81',
    rgbColor: [247, 139, 19],
    hexColor: 'f78b13',
}, {
    dmcNumber: '971',
    colorName: 'Pumpkin',
    rgbColor: [246, 127, 0],
    hexColor: 'f67f00',
}, {
    dmcNumber: '947',
    anchorNumber: '330',
    colorName: 'Burnt Orange',
    madeiraNumber: '205',
    gammaNumber: '3200',
    kirovaNumber: '0710',
    dimensionsNumber: '6117, 12330',
    domeNumber: '513',
    belkaNumber: '1179',
    bucillaNumber: '2665',
    rgbColor: [255, 123, 77],
    hexColor: 'ff7b4d',
}, {
    dmcNumber: '946',
    anchorNumber: '332',
    colorName: 'Burnt Orange Med',
    madeiraNumber: '0206, 0207',
    gammaNumber: '44',
    kirovaNumber: '0712, 22',
    domeNumber: '512',
    belkaNumber: '594',
    bucillaNumber: '2666',
    rgbColor: [235, 99, 7],
    hexColor: 'eb6307',
}, {
    dmcNumber: '900',
    anchorNumber: '326',
    colorName: 'Burnt Orange Dark',
    madeiraNumber: '208',
    gammaNumber: '3201',
    domeNumber: '510',
    belkaNumber: '148',
    bucillaNumber: '900',
    rgbColor: [209, 88, 7],
    hexColor: 'd15807',
}, {
    dmcNumber: '967',
    anchorNumber: '1012',
    colorName: 'Apricot Very Light',
    gammaNumber: '310',
    belkaNumber: '2603',
    rgbColor: [255, 222, 213],
    hexColor: 'ffded5',
}, {
    dmcNumber: '3824',
    anchorNumber: '8, 9',
    colorName: 'Apricot Light',
    madeiraNumber: '0304, 2503',
    gammaNumber: '45',
    kirovaNumber: '0702, 18',
    mayFlowerNumber: '2023',
    domeNumber: '788',
    belkaNumber: '1866',
    bucillaNumber: '6031',
    rgbColor: [254, 205, 194],
    hexColor: 'fecdc2',
}, {
    dmcNumber: '3341',
    anchorNumber: '328',
    colorName: 'Apricot',
    madeiraNumber: '302',
    gammaNumber: '312',
    kirovaNumber: '0802, 19',
    dimensionsNumber: '13332',
    domeNumber: '787',
    belkaNumber: '2049',
    rgbColor: [252, 171, 152],
    hexColor: 'fcab98',
}, {
    dmcNumber: '3340',
    anchorNumber: '328, 329',
    colorName: 'Apricot Med',
    madeiraNumber: '301',
    gammaNumber: '3202',
    kirovaNumber: '0706',
    domeNumber: '786',
    belkaNumber: '3038',
    rgbColor: [255, 131, 111],
    hexColor: 'ff836f',
}, {
    dmcNumber: '608',
    anchorNumber: '332',
    colorName: 'Burnt Orange Bright',
    madeiraNumber: '205',
    gammaNumber: '315',
    kirovaNumber: '0712, 22',
    dimensionsNumber: '12332',
    domeNumber: '500',
    belkaNumber: '2982',
    rgbColor: [253, 93, 53],
    hexColor: 'fd5d35',
}, {
    dmcNumber: '606',
    anchorNumber: '334, 335',
    colorName: 'Orange?Red Bright',
    madeiraNumber: '209',
    gammaNumber: '11',
    kirovaNumber: '0810, 29',
    dimensionsNumber: '6116, 12334',
    domeNumber: '195',
    belkaNumber: '2204',
    rgbColor: [250, 50, 3],
    hexColor: 'fa3203',
}, {
    dmcNumber: '951',
    anchorNumber: '1010',
    colorName: 'Tawny Light',
    madeiraNumber: '2308',
    gammaNumber: '3021',
    dimensionsNumber: '13335',
    domeNumber: '758',
    belkaNumber: '2145',
    bucillaNumber: '951',
    rgbColor: [255, 226, 207],
    hexColor: 'ffe2cf',
}, {
    dmcNumber: '3856',
    anchorNumber: '366, 1010',
    colorName: 'Mahogany Ult Vy Lt',
    madeiraNumber: '2309',
    gammaNumber: '47',
    dimensionsNumber: '15345',
    domeNumber: '737',
    belkaNumber: '2262',
    bucillaNumber: '6224',
    rgbColor: [255, 211, 181],
    hexColor: 'ffd3b5',
}, {
    dmcNumber: '722',
    anchorNumber: '323',
    colorName: 'Orange Spice Light',
    madeiraNumber: '307',
    gammaNumber: '3204',
    dimensionsNumber: '6722, 12323',
    domeNumber: '782',
    belkaNumber: '463',
    bucillaNumber: '3722',
    rgbColor: [247, 151, 111],
    hexColor: 'f7976f',
}, {
    dmcNumber: '721',
    anchorNumber: '324, 925',
    colorName: 'Orange Spice Med',
    madeiraNumber: '0308, 0311',
    gammaNumber: '108',
    kirovaNumber: '1606',
    dimensionsNumber: '12324',
    domeNumber: '781',
    belkaNumber: '554',
    bucillaNumber: '3721',
    rgbColor: [242, 120, 66],
    hexColor: 'f27842',
}, {
    dmcNumber: '720',
    anchorNumber: '326',
    colorName: 'Orange Spice Dark',
    madeiraNumber: '309',
    gammaNumber: '109',
    kirovaNumber: '1608',
    dimensionsNumber: '6720, 12322',
    domeNumber: '780',
    belkaNumber: '23',
    bucillaNumber: '3720',
    rgbColor: [229, 92, 31],
    hexColor: 'e55c1f',
}, {
    dmcNumber: '3825',
    anchorNumber: '323',
    colorName: 'Pumpkin Pale',
    madeiraNumber: '307',
    gammaNumber: '3203',
    kirovaNumber: '0604, 15',
    domeNumber: '783',
    belkaNumber: '1018',
    rgbColor: [253, 189, 150],
    hexColor: 'fdbd96',
}, {
    dmcNumber: '922',
    anchorNumber: '1003',
    colorName: 'Copper Light',
    madeiraNumber: '310',
    gammaNumber: '46',
    kirovaNumber: '5408',
    mayFlowerNumber: '2029',
    dimensionsNumber: '6110',
    domeNumber: '744',
    belkaNumber: '3012',
    rgbColor: [226, 115, 35],
    hexColor: 'e27323',
}, {
    dmcNumber: '921',
    anchorNumber: '884, 1004',
    colorName: 'Copper',
    madeiraNumber: '2306',
    gammaNumber: '3205',
    kirovaNumber: '1608',
    domeNumber: '743',
    belkaNumber: '1686',
    rgbColor: [198, 98, 24],
    hexColor: 'c66218',
}, {
    dmcNumber: '920',
    anchorNumber: '1004',
    colorName: 'Copper Med',
    madeiraNumber: '312',
    gammaNumber: '32',
    kirovaNumber: '1614',
    mayFlowerNumber: '2030',
    dimensionsNumber: '13337',
    domeNumber: '742',
    belkaNumber: '1466',
    bucillaNumber: '2614',
    rgbColor: [172, 84, 20],
    hexColor: 'ac5414',
}, {
    dmcNumber: '919',
    anchorNumber: '340',
    colorName: 'Red Copper',
    madeiraNumber: '313',
    gammaNumber: '3206',
    kirovaNumber: '1614',
    dimensionsNumber: '6919, 12326',
    domeNumber: '741',
    belkaNumber: '512',
    bucillaNumber: '2615',
    rgbColor: [166, 69, 16],
    hexColor: 'a64510',
}, {
    dmcNumber: '918',
    anchorNumber: '341',
    colorName: 'Red Copper Dark',
    madeiraNumber: '314',
    gammaNumber: '771',
    kirovaNumber: '5610',
    dimensionsNumber: '13340',
    domeNumber: '740',
    belkaNumber: '1644',
    rgbColor: [130, 52, 10],
    hexColor: '82340a',
}, {
    dmcNumber: '3770',
    anchorNumber: '1009',
    colorName: 'Tawny Vy Light',
    madeiraNumber: '2314',
    gammaNumber: '40',
    kirovaNumber: '0102',
    mayFlowerNumber: '2025',
    dimensionsNumber: '13334',
    domeNumber: '759',
    belkaNumber: '3714',
    bucillaNumber: '6098, 6328',
    rgbColor: [255, 238, 227],
    hexColor: 'ffeee3',
}, {
    dmcNumber: '945',
    anchorNumber: '881',
    colorName: 'Tawny',
    madeiraNumber: '1910, 2313',
    gammaNumber: '924',
    kirovaNumber: '5902',
    dimensionsNumber: '6945',
    domeNumber: '757',
    belkaNumber: '933',
    bucillaNumber: '3945',
    rgbColor: [251, 213, 187],
    hexColor: 'fbd5bb',
}, {
    dmcNumber: '402',
    anchorNumber: '347, 1047',
    colorName: 'Mahogany Vy Lt',
    madeiraNumber: '2307, 2301',
    gammaNumber: '3207',
    kirovaNumber: '5404',
    dimensionsNumber: '6402',
    domeNumber: '736',
    belkaNumber: '1936',
    bucillaNumber: '3402',
    rgbColor: [247, 167, 119],
    hexColor: 'f7a777',
}, {
    dmcNumber: '3776',
    anchorNumber: '1048',
    colorName: 'Mahogany Light',
    madeiraNumber: '2011',
    gammaNumber: '48',
    kirovaNumber: '5406',
    dimensionsNumber: '13336',
    domeNumber: '734',
    belkaNumber: '3139',
    bucillaNumber: '2613',
    rgbColor: [207, 121, 57],
    hexColor: 'cf7939',
}, {
    dmcNumber: '301',
    anchorNumber: '1049',
    colorName: 'Mahogany Med',
    madeiraNumber: '2306',
    gammaNumber: '3022',
    kirovaNumber: '5808, 118',
    dimensionsNumber: '6109',
    domeNumber: '733',
    belkaNumber: '1836',
    bucillaNumber: '6109',
    rgbColor: [179, 95, 43],
    hexColor: 'b35f2b',
}, {
    dmcNumber: '400',
    anchorNumber: '351',
    colorName: 'Mahogany Dark',
    madeiraNumber: '2304, 2305',
    gammaNumber: '3023',
    kirovaNumber: '5810',
    dimensionsNumber: '12238, 15349',
    domeNumber: '732',
    belkaNumber: '470',
    rgbColor: [143, 67, 15],
    hexColor: '8f430f',
}, {
    dmcNumber: '300',
    anchorNumber: '352, 359',
    colorName: 'Mahogany Vy Dk',
    madeiraNumber: '2304, 2005, 2602',
    gammaNumber: '0002, 0930, 0219',
    kirovaNumber: '5812, 161',
    dimensionsNumber: '6108',
    domeNumber: '730',
    belkaNumber: '3473',
    bucillaNumber: '2568',
    rgbColor: [111, 47, 0],
    hexColor: '6f2f00',
}, {
    dmcNumber: '3823',
    anchorNumber: '386',
    colorName: 'Yellow Ultra Pale',
    madeiraNumber: '2205, 2511, 2512',
    gammaNumber: '43',
    dimensionsNumber: '12386',
    domeNumber: '509',
    belkaNumber: '998',
    rgbColor: [255, 253, 227],
    hexColor: 'fffde3',
}, {
    dmcNumber: '3855',
    anchorNumber: '311',
    colorName: 'Autumn Gold Lt',
    madeiraNumber: '2012',
    gammaNumber: '3208',
    domeNumber: '749',
    belkaNumber: '1896',
    bucillaNumber: '2514',
    rgbColor: [250, 211, 150],
    hexColor: 'fad396',
}, {
    dmcNumber: '3854',
    anchorNumber: '311, 313',
    colorName: 'Autumn Gold Med',
    madeiraNumber: '2307',
    gammaNumber: '3209',
    mayFlowerNumber: '2221',
    domeNumber: '748',
    belkaNumber: '419',
    bucillaNumber: '6202',
    rgbColor: [242, 175, 104],
    hexColor: 'f2af68',
}, {
    dmcNumber: '3853',
    anchorNumber: '1003',
    colorName: 'Autumn Gold Dk',
    madeiraNumber: '311',
    gammaNumber: '107',
    domeNumber: '746',
    belkaNumber: '3356',
    rgbColor: [242, 151, 70],
    hexColor: 'f29746',
}, {
    dmcNumber: '3827',
    anchorNumber: '311, 1002',
    colorName: 'Golden Brown Pale',
    madeiraNumber: '2013',
    gammaNumber: '50',
    kirovaNumber: '5402',
    dimensionsNumber: '15350',
    domeNumber: '766',
    belkaNumber: '2224',
    bucillaNumber: '3827',
    rgbColor: [247, 187, 119],
    hexColor: 'f7bb77',
}, {
    dmcNumber: '977',
    anchorNumber: '1002',
    colorName: 'Golden Brown Light',
    madeiraNumber: '2302',
    gammaNumber: '3201',
    kirovaNumber: '5402',
    dimensionsNumber: '6977, 12306, 15351',
    domeNumber: '765',
    belkaNumber: '1306',
    bucillaNumber: '2670',
    rgbColor: [220, 156, 86],
    hexColor: 'dc9c56',
}, {
    dmcNumber: '976',
    anchorNumber: '1001',
    colorName: 'Golden Brown Med',
    madeiraNumber: '2302',
    gammaNumber: '49',
    dimensionsNumber: '6976, 12308, 15365',
    domeNumber: '764',
    belkaNumber: '51',
    rgbColor: [194, 129, 66],
    hexColor: 'c28142',
}, {
    dmcNumber: '3826',
    anchorNumber: '365, 1049',
    colorName: 'Golden Brown',
    gammaNumber: '926',
    kirovaNumber: '5806, 117',
    domeNumber: '763',
    belkaNumber: '1375',
    bucillaNumber: '6223',
    rgbColor: [173, 114, 57],
    hexColor: 'ad7239',
}, {
    dmcNumber: '975',
    anchorNumber: '355, 371',
    colorName: 'Golden Brown Dk',
    madeiraNumber: '2303, 2602',
    gammaNumber: '928',
    kirovaNumber: '5810',
    dimensionsNumber: '6975, 15356',
    domeNumber: '760',
    belkaNumber: '699',
    bucillaNumber: '2671, 2672',
    rgbColor: [145, 79, 18],
    hexColor: '914f12',
}, {
    dmcNumber: '948',
    anchorNumber: '1011',
    colorName: 'Peach Very Light',
    madeiraNumber: '306',
    gammaNumber: '0309, 3024',
    mayFlowerNumber: '2251',
    dimensionsNumber: '12331',
    domeNumber: '128',
    belkaNumber: '2708',
    bucillaNumber: '3948',
    rgbColor: [254, 231, 218],
    hexColor: 'fee7da',
}, {
    dmcNumber: '754',
    anchorNumber: '336, 1012',
    colorName: 'Peach Light',
    madeiraNumber: '305',
    gammaNumber: '3025',
    kirovaNumber: '1602',
    mayFlowerNumber: '2024',
    dimensionsNumber: '6754',
    domeNumber: '126',
    belkaNumber: '369',
    bucillaNumber: '754',
    rgbColor: [247, 203, 191],
    hexColor: 'f7cbbf',
}, {
    dmcNumber: '3771',
    colorName: 'Terra Cotta Ult Vy Lt',
    madeiraNumber: '2605',
    gammaNumber: '3026',
    belkaNumber: '2536',
    rgbColor: [244, 187, 169],
    hexColor: 'f4bba9',
}, {
    dmcNumber: '758',
    anchorNumber: '868, 9575',
    colorName: 'Terra Cotta Vy Lt',
    madeiraNumber: '403',
    gammaNumber: '51',
    kirovaNumber: '1602',
    mayFlowerNumber: '2033',
    dimensionsNumber: '6042, 6758',
    domeNumber: '776',
    belkaNumber: '3173',
    bucillaNumber: '758',
    rgbColor: [238, 170, 155],
    hexColor: 'eeaa9b',
}, {
    dmcNumber: '3778',
    anchorNumber: '1013',
    colorName: 'Terra Cotta Light',
    madeiraNumber: '0403, 2310',
    gammaNumber: '767',
    kirovaNumber: '5604',
    dimensionsNumber: '12337',
    domeNumber: '774',
    belkaNumber: '301',
    bucillaNumber: '3778',
    rgbColor: [217, 137, 120],
    hexColor: 'd98978',
}, {
    dmcNumber: '356',
    anchorNumber: '338, 5975',
    colorName: 'Terra Cotta Med',
    madeiraNumber: '402',
    gammaNumber: '768',
    kirovaNumber: '5606',
    dimensionsNumber: '12339',
    domeNumber: '773',
    belkaNumber: '1347',
    bucillaNumber: '3356',
    rgbColor: [197, 106, 91],
    hexColor: 'c56a5b',
}, {
    dmcNumber: '3830',
    anchorNumber: '5975',
    colorName: 'Terra Cotta',
    madeiraNumber: '401',
    gammaNumber: '3027',
    kirovaNumber: '5608, 39',
    mayFlowerNumber: '2035',
    dimensionsNumber: '12975',
    domeNumber: '772',
    belkaNumber: '2511',
    bucillaNumber: '3830',
    rgbColor: [185, 85, 68],
    hexColor: 'b95544',
}, {
    dmcNumber: '355',
    anchorNumber: '1014',
    colorName: 'Terra Cotta Dark',
    madeiraNumber: '401',
    gammaNumber: '3211',
    kirovaNumber: '5610',
    dimensionsNumber: '12338',
    domeNumber: '771',
    belkaNumber: '2394',
    rgbColor: [152, 68, 54],
    hexColor: '984436',
}, {
    dmcNumber: '3777',
    anchorNumber: '1015',
    colorName: 'Terra Cotta Vy Dk',
    madeiraNumber: '2502',
    gammaNumber: '3212',
    domeNumber: '770',
    belkaNumber: '2493',
    rgbColor: [134, 48, 34],
    hexColor: '863022',
}, {
    dmcNumber: '3779',
    anchorNumber: '868, 1012',
    colorName: 'Rosewood Ult Vy Lt',
    madeiraNumber: '0305, 2605',
    gammaNumber: '3213',
    dimensionsNumber: '13868',
    domeNumber: '777',
    belkaNumber: '549',
    rgbColor: [248, 202, 200],
    hexColor: 'f8cac8',
}, {
    dmcNumber: '3859',
    anchorNumber: '914, 1007',
    colorName: 'Rosewood Light',
    madeiraNumber: '2312',
    gammaNumber: '3028',
    domeNumber: '792',
    belkaNumber: '2993',
    rgbColor: [186, 139, 124],
    hexColor: 'ba8b7c',
}, {
    dmcNumber: '3858',
    anchorNumber: '936, 1007',
    colorName: 'Rosewood Med',
    madeiraNumber: '810',
    gammaNumber: '3029',
    domeNumber: '791',
    belkaNumber: '3349',
    rgbColor: [150, 74, 63],
    hexColor: '964a3f',
}, {
    dmcNumber: '3857',
    anchorNumber: '936',
    colorName: 'Rosewood Dark',
    madeiraNumber: '2311',
    gammaNumber: '772',
    domeNumber: '790',
    belkaNumber: '1815',
    rgbColor: [104, 37, 26],
    hexColor: '68251a',
}, {
    dmcNumber: '3774',
    anchorNumber: '778, 800',
    colorName: 'Desert Sand Vy Lt',
    madeiraNumber: '1909',
    gammaNumber: '3214',
    mayFlowerNumber: '2227',
    dimensionsNumber: '6336',
    domeNumber: '756',
    belkaNumber: '1422',
    rgbColor: [243, 225, 215],
    hexColor: 'f3e1d7',
}, {
    dmcNumber: '950',
    anchorNumber: '4146',
    colorName: 'Desert Sand Light',
    madeiraNumber: '2309',
    gammaNumber: '3030',
    mayFlowerNumber: '2255',
    domeNumber: '755',
    belkaNumber: '1598',
    bucillaNumber: '3950',
    rgbColor: [238, 211, 196],
    hexColor: 'eed3c4',
}, {
    dmcNumber: '3064',
    anchorNumber: '883, 914',
    colorName: 'Desert Sand',
    gammaNumber: '925',
    kirovaNumber: '5804, 116',
    dimensionsNumber: '13883',
    domeNumber: '753',
    belkaNumber: '834',
    bucillaNumber: '2560',
    rgbColor: [196, 142, 112],
    hexColor: 'c48e70',
}, {
    dmcNumber: '407',
    colorName: 'Desert Sand Med',
    madeiraNumber: '3773',
    gammaNumber: '1008',
    kirovaNumber: '52',
    mayFlowerNumber: '6302',
    belkaNumber: '754',
    rgbColor: [187, 129, 97],
    hexColor: 'bb8161',
}, {
    dmcNumber: '3773',
    colorName: 'Desert Sand Dark',
    rgbColor: [182, 117, 82],
    hexColor: 'b67552',
}, {
    dmcNumber: '3772',
    anchorNumber: '1007',
    colorName: 'Desert Sand Vy Dk',
    madeiraNumber: '2601',
    gammaNumber: '3215',
    kirovaNumber: '6304',
    dimensionsNumber: '15579',
    domeNumber: '751',
    belkaNumber: '2251',
    bucillaNumber: '3772',
    rgbColor: [160, 108, 80],
    hexColor: 'a06c50',
}, {
    dmcNumber: '632',
    anchorNumber: '936',
    colorName: 'Desert Sand Ult Vy Dk',
    madeiraNumber: '2311',
    gammaNumber: '3216',
    dimensionsNumber: '15936',
    domeNumber: '750',
    belkaNumber: '2376',
    bucillaNumber: '632',
    rgbColor: [135, 85, 57],
    hexColor: '875539',
}, {
    dmcNumber: '453',
    anchorNumber: '231',
    colorName: 'Shell Gray Light',
    madeiraNumber: '1806, 1803',
    gammaNumber: '670',
    dimensionsNumber: '18231',
    domeNumber: '442',
    belkaNumber: '39',
    bucillaNumber: '3400, 6238',
    rgbColor: [215, 206, 203],
    hexColor: 'd7cecb',
}, {
    dmcNumber: '452',
    anchorNumber: '232, 233',
    colorName: 'Shell Gray Med',
    madeiraNumber: '1807, 1803',
    gammaNumber: '0672, 0067, 0052',
    kirovaNumber: '6402',
    dimensionsNumber: '18252',
    domeNumber: '441',
    belkaNumber: '1538',
    rgbColor: [192, 179, 174],
    hexColor: 'c0b3ae',
}, {
    dmcNumber: '451',
    anchorNumber: '233',
    colorName: 'Shell Gray Dark',
    madeiraNumber: '1808',
    gammaNumber: '674',
    kirovaNumber: '6406',
    domeNumber: '440',
    belkaNumber: '1469',
    bucillaNumber: '2690',
    rgbColor: [145, 123, 115],
    hexColor: '917b73',
}, {
    dmcNumber: '3861',
    anchorNumber: '378, 677',
    colorName: 'Cocoa Light',
    madeiraNumber: '1912',
    gammaNumber: '3217',
    domeNumber: '451',
    belkaNumber: '334',
    rgbColor: [166, 136, 129],
    hexColor: 'a68881',
}, {
    dmcNumber: '3860',
    anchorNumber: '379',
    colorName: 'Cocoa',
    madeiraNumber: '806',
    gammaNumber: '3032',
    domeNumber: '450',
    belkaNumber: '451',
    rgbColor: [125, 93, 87],
    hexColor: '7d5d57',
}, {
    dmcNumber: '779',
    colorName: 'Cocoa Dark',
    gammaNumber: '3249',
    belkaNumber: '218',
    rgbColor: [98, 75, 69],
    hexColor: '624b45',
}, {
    dmcNumber: '712',
    anchorNumber: '390, 926',
    colorName: 'Cream',
    madeiraNumber: '2101',
    gammaNumber: '3036',
    mayFlowerNumber: '2186',
    dimensionsNumber: '6155',
    domeNumber: '716',
    belkaNumber: '908',
    rgbColor: [255, 251, 239],
    hexColor: 'fffbef',
}, {
    dmcNumber: '739',
    anchorNumber: '276, 366',
    colorName: 'Tan Ult Vy Lt',
    madeiraNumber: '2001, 2404',
    gammaNumber: '0018, 0802',
    kirovaNumber: '0103, 115',
    dimensionsNumber: '6739, 15369, 15372',
    domeNumber: '713',
    belkaNumber: '823',
    bucillaNumber: '739',
    rgbColor: [248, 228, 200],
    hexColor: 'f8e4c8',
}, {
    dmcNumber: '738',
    anchorNumber: '372, 942',
    colorName: 'Tan Very Light',
    madeiraNumber: '2012, 2013',
    gammaNumber: '0098, 0803',
    kirovaNumber: '5902',
    dimensionsNumber: '6738, 15375',
    domeNumber: '712',
    belkaNumber: '2810',
    bucillaNumber: '3354, 5375',
    rgbColor: [236, 204, 158],
    hexColor: 'eccc9e',
}, {
    dmcNumber: '437',
    anchorNumber: '362',
    colorName: 'Tan Light',
    madeiraNumber: '2012',
    gammaNumber: '54',
    kirovaNumber: '5802',
    mayFlowerNumber: '2199',
    dimensionsNumber: '15942',
    domeNumber: '711',
    belkaNumber: '110',
    rgbColor: [228, 187, 142],
    hexColor: 'e4bb8e',
}, {
    dmcNumber: '436',
    anchorNumber: '1045',
    colorName: 'Tan',
    madeiraNumber: '2011',
    gammaNumber: '3218',
    kirovaNumber: '5504',
    dimensionsNumber: '6096, 15347, 15943',
    domeNumber: '710',
    belkaNumber: '532',
    rgbColor: [203, 144, 81],
    hexColor: 'cb9051',
}, {
    dmcNumber: '435',
    anchorNumber: '1046',
    colorName: 'Brown Very Light',
    madeiraNumber: '2010',
    gammaNumber: '7',
    kirovaNumber: '5508, 128',
    mayFlowerNumber: '2312',
    dimensionsNumber: '15371',
    domeNumber: '709',
    belkaNumber: '2991',
    bucillaNumber: '435',
    rgbColor: [184, 119, 72],
    hexColor: 'b87748',
}, {
    dmcNumber: '434',
    anchorNumber: '310',
    colorName: 'Brown Light',
    madeiraNumber: '2009',
    gammaNumber: '22',
    kirovaNumber: '3908, 84',
    dimensionsNumber: '6095, 15000',
    domeNumber: '708',
    belkaNumber: '2731',
    bucillaNumber: '434',
    rgbColor: [152, 94, 51],
    hexColor: '985e33',
}, {
    dmcNumber: '433',
    anchorNumber: '358, 352, 371',
    colorName: 'Brown Med',
    madeiraNumber: '2008',
    gammaNumber: '0217, 0809, 0929',
    mayFlowerNumber: '2314',
    dimensionsNumber: '15470, 15471',
    domeNumber: '707',
    belkaNumber: '1639',
    bucillaNumber: '433',
    rgbColor: [122, 69, 31],
    hexColor: '7a451f',
}, {
    dmcNumber: '801',
    anchorNumber: '359',
    colorName: 'Coffee Brown Dk',
    madeiraNumber: '2007',
    gammaNumber: '0055, 0657',
    kirovaNumber: '6012',
    mayFlowerNumber: '2315',
    dimensionsNumber: '6093, 15475',
    domeNumber: '706',
    belkaNumber: '698',
    bucillaNumber: '6199',
    rgbColor: [101, 57, 25],
    hexColor: '653919',
}, {
    dmcNumber: '898',
    anchorNumber: '360',
    colorName: 'Coffee Brown Vy Dk',
    madeiraNumber: '1914, 2007',
    gammaNumber: '220',
    kirovaNumber: '6512',
    mayFlowerNumber: '2195',
    dimensionsNumber: '15476',
    domeNumber: '705',
    belkaNumber: '162',
    bucillaNumber: '898',
    rgbColor: [73, 42, 19],
    hexColor: '492a13',
}, {
    dmcNumber: '938',
    anchorNumber: '381',
    colorName: 'Coffee Brown Ult Dk',
    madeiraNumber: '2005',
    gammaNumber: '812',
    kirovaNumber: '5710, 180',
    dimensionsNumber: '6092, 15381, 15477',
    domeNumber: '704',
    belkaNumber: '2558',
    rgbColor: [54, 31, 14],
    hexColor: '361f0e',
}, {
    dmcNumber: '3371',
    anchorNumber: '382',
    colorName: 'Black Brown',
    madeiraNumber: '2004',
    gammaNumber: '3219',
    dimensionsNumber: '15478',
    domeNumber: '700',
    belkaNumber: '582',
    bucillaNumber: '2673',
    rgbColor: [30, 17, 8],
    hexColor: '1e1108',
}, {
    dmcNumber: '543',
    anchorNumber: '933',
    colorName: 'Beige Brown Ult Vy Lt',
    madeiraNumber: '1909',
    gammaNumber: '3033',
    dimensionsNumber: '15533',
    domeNumber: '727',
    belkaNumber: '2970',
    rgbColor: [242, 227, 206],
    hexColor: 'f2e3ce',
}, {
    dmcNumber: '3864',
    anchorNumber: '376, 1080',
    colorName: 'Mocha Beige Light',
    madeiraNumber: '1906, 1910',
    gammaNumber: '0056, 3034',
    dimensionsNumber: '15376',
    domeNumber: '798',
    belkaNumber: '384',
    rgbColor: [203, 182, 156],
    hexColor: 'cbb69c',
}, {
    dmcNumber: '3863',
    anchorNumber: '379',
    colorName: 'Mocha Beige Med',
    madeiraNumber: '1912',
    gammaNumber: '3035',
    dimensionsNumber: '15379',
    domeNumber: '797',
    belkaNumber: '2459',
    rgbColor: [164, 131, 92],
    hexColor: 'a4835c',
}, {
    dmcNumber: '3862',
    anchorNumber: '377, 944',
    colorName: 'Mocha Beige Dark',
    madeiraNumber: '1913',
    gammaNumber: '808',
    domeNumber: '796',
    belkaNumber: '2042',
    rgbColor: [138, 110, 78],
    hexColor: '8a6e4e',
}, {
    dmcNumber: '3031',
    anchorNumber: '360, 905',
    colorName: 'Mocha Brown Vy Dk',
    madeiraNumber: '2003',
    gammaNumber: '3224',
    dimensionsNumber: '15472, 63031',
    domeNumber: '800',
    belkaNumber: '342',
    rgbColor: [75, 60, 42],
    hexColor: '4b3c2a',
}, {
    dmcNumber: 'B5200',
    anchorNumber: '1',
    colorName: 'Snow White',
    madeiraNumber: '2401',
    kirovaNumber: '0101, 1',
    dimensionsNumber: '5200, 11002',
    domeNumber: '80, 83',
    belkaNumber: '867',
    rgbColor: [255, 255, 255],
    hexColor: 'ffffff',
}, {
    dmcNumber: 'White',
    colorName: 'White',
    rgbColor: [252, 251, 248],
    hexColor: 'fcfbf8',
}, {
    dmcNumber: '3865',
    anchorNumber: '2, 926',
    colorName: 'Winter White',
    madeiraNumber: '2402',
    gammaNumber: '3250',
    domeNumber: '82',
    belkaNumber: '2170',
    rgbColor: [249, 247, 241],
    hexColor: 'f9f7f1',
}, {
    dmcNumber: 'Ecru',
    anchorNumber: '387',
    colorName: 'Ecru',
    madeiraNumber: 'Ecru, 2404',
    gammaNumber: '1',
    kirovaNumber: '0102',
    dimensionsNumber: '6098, 6115',
    domeNumber: '717',
    belkaNumber: '3',
    bucillaNumber: '2751',
    rgbColor: [240, 234, 218],
    hexColor: 'f0eada',
}, {
    dmcNumber: '822',
    anchorNumber: '390',
    colorName: 'Beige Gray Light',
    gammaNumber: '3225',
    mayFlowerNumber: '2201',
    dimensionsNumber: '15830',
    domeNumber: '818',
    belkaNumber: '1485',
    bucillaNumber: '822',
    rgbColor: [231, 226, 211],
    hexColor: 'e7e2d3',
}, {
    dmcNumber: '644',
    anchorNumber: '391, 830',
    colorName: 'Beige Gray Med',
    madeiraNumber: '1814',
    gammaNumber: '3247',
    kirovaNumber: '6602',
    domeNumber: '816',
    belkaNumber: '1664',
    rgbColor: [221, 216, 203],
    hexColor: 'ddd8cb',
}, {
    dmcNumber: '642',
    anchorNumber: '392',
    colorName: 'Beige Gray Dark',
    madeiraNumber: '1903',
    gammaNumber: '59',
    kirovaNumber: '6604',
    dimensionsNumber: '15832',
    domeNumber: '813',
    belkaNumber: '393',
    bucillaNumber: '642',
    rgbColor: [164, 152, 120],
    hexColor: 'a49878',
}, {
    dmcNumber: '640',
    anchorNumber: '393',
    colorName: 'Beige Gray Vy Dk',
    madeiraNumber: '1813',
    gammaNumber: '3037',
    kirovaNumber: '6008',
    mayFlowerNumber: '2182',
    dimensionsNumber: '6103',
    domeNumber: '812',
    belkaNumber: '1986',
    bucillaNumber: '2754',
    rgbColor: [133, 123, 97],
    hexColor: '857b61',
}, {
    dmcNumber: '3787',
    anchorNumber: '273',
    colorName: 'Brown Gray Dark',
    gammaNumber: '3227',
    kirovaNumber: '6808',
    domeNumber: '422',
    belkaNumber: '1442',
    bucillaNumber: '3787',
    rgbColor: [98, 93, 80],
    hexColor: '625d50',
}, {
    dmcNumber: '3021',
    anchorNumber: '905',
    colorName: 'Brown Gray Vy Dk',
    madeiraNumber: '1811, 1904',
    gammaNumber: '3228',
    kirovaNumber: '5204',
    dimensionsNumber: '15395',
    domeNumber: '420',
    belkaNumber: '1028',
    rgbColor: [79, 75, 65],
    hexColor: '4f4b41',
}, {
    dmcNumber: '3024',
    anchorNumber: '388, 397',
    colorName: 'Brown Gray Vy Lt',
    madeiraNumber: '1814, 1901',
    gammaNumber: '3038',
    kirovaNumber: '6002',
    domeNumber: '427',
    belkaNumber: '2015',
    bucillaNumber: '6185',
    rgbColor: [235, 234, 231],
    hexColor: 'ebeae7',
}, {
    dmcNumber: '3023',
    anchorNumber: '899, 1040',
    colorName: 'Brown Gray Light',
    madeiraNumber: '1902',
    gammaNumber: '3039',
    kirovaNumber: '6606',
    dimensionsNumber: '6104',
    domeNumber: '426',
    belkaNumber: '2570',
    bucillaNumber: '3023',
    rgbColor: [177, 170, 151],
    hexColor: 'b1aa97',
}, {
    dmcNumber: '3022',
    anchorNumber: '8581',
    colorName: 'Brown Gray Med',
    madeiraNumber: '1812',
    gammaNumber: '3040',
    kirovaNumber: '6704, 177',
    domeNumber: '425',
    belkaNumber: '790',
    rgbColor: [142, 144, 120],
    hexColor: '8e9078',
}, {
    dmcNumber: '535',
    anchorNumber: '401, 1041',
    colorName: 'Ash Gray Vy Lt',
    madeiraNumber: '1809, 1714',
    gammaNumber: '3240',
    dimensionsNumber: '18400',
    domeNumber: '467',
    belkaNumber: '2598',
    bucillaNumber: '3535',
    rgbColor: [99, 100, 88],
    hexColor: '636458',
}, {
    dmcNumber: '3033',
    anchorNumber: '391',
    colorName: 'Mocha Brown Vy Lt',
    madeiraNumber: '1907, 1908, 2001',
    gammaNumber: '9',
    kirovaNumber: '0103, 115',
    domeNumber: '808',
    belkaNumber: '31',
    bucillaNumber: '2752, 3033',
    rgbColor: [227, 216, 204],
    hexColor: 'e3d8cc',
}, {
    dmcNumber: '3782',
    anchorNumber: '899, 1050',
    colorName: 'Mocha Brown Lt',
    madeiraNumber: '1902, 1907',
    gammaNumber: '0915, 3041',
    kirovaNumber: '6002',
    domeNumber: '806',
    belkaNumber: '1756',
    bucillaNumber: '3782',
    rgbColor: [210, 188, 166],
    hexColor: 'd2bca6',
}, {
    dmcNumber: '3032',
    anchorNumber: '903',
    colorName: 'Mocha Brown Med',
    madeiraNumber: '2002',
    gammaNumber: '3226',
    kirovaNumber: '6004',
    domeNumber: '804',
    belkaNumber: '241',
    rgbColor: [179, 159, 139],
    hexColor: 'b39f8b',
}, {
    dmcNumber: '3790',
    anchorNumber: '393, 889',
    colorName: 'Beige Gray Ult Dk',
    madeiraNumber: '1903, 1905',
    gammaNumber: '0058, 0919',
    dimensionsNumber: '15393',
    domeNumber: '810',
    belkaNumber: '2752',
    bucillaNumber: '3790',
    rgbColor: [127, 106, 85],
    hexColor: '7f6a55',
}, {
    dmcNumber: '3781',
    anchorNumber: '904, 1050',
    colorName: 'Mocha Brown Dk',
    madeiraNumber: '2106',
    gammaNumber: '3223',
    kirovaNumber: '5204',
    domeNumber: '801',
    belkaNumber: '1118',
    bucillaNumber: '3781',
    rgbColor: [107, 87, 67],
    hexColor: '6b5743',
}, {
    dmcNumber: '3866',
    anchorNumber: '386, 926',
    colorName: 'Mocha Brn Ult Vy Lt',
    madeiraNumber: '1908',
    gammaNumber: '0034, 3042',
    belkaNumber: '3211',
    rgbColor: [250, 246, 240],
    hexColor: 'faf6f0',
}, {
    dmcNumber: '842',
    anchorNumber: '1080',
    colorName: 'Beige Brown Vy Lt',
    madeiraNumber: '1907, 1910',
    gammaNumber: '3220',
    dimensionsNumber: '6842, 15388, 15576, 15933',
    domeNumber: '725',
    belkaNumber: '1254',
    bucillaNumber: '842, 6104',
    rgbColor: [209, 186, 161],
    hexColor: 'd1baa1',
}, {
    dmcNumber: '841',
    anchorNumber: '1082',
    colorName: 'Beige Brown Lt',
    madeiraNumber: '1911',
    gammaNumber: '57',
    kirovaNumber: '6504',
    dimensionsNumber: '6101',
    domeNumber: '724',
    belkaNumber: '464',
    bucillaNumber: '841, 2757',
    rgbColor: [182, 155, 126],
    hexColor: 'b69b7e',
}, {
    dmcNumber: '840',
    anchorNumber: '1084',
    colorName: 'Beige Brown Med',
    madeiraNumber: '1912',
    gammaNumber: '3221',
    kirovaNumber: '6106',
    dimensionsNumber: '6100, 15898',
    domeNumber: '723',
    belkaNumber: '111',
    bucillaNumber: '6303',
    rgbColor: [154, 124, 92],
    hexColor: '9a7c5c',
}, {
    dmcNumber: '839',
    anchorNumber: '1086',
    colorName: 'Beige Brown Dk',
    madeiraNumber: '1913',
    gammaNumber: '3222',
    kirovaNumber: '6510',
    dimensionsNumber: '6099, 15360',
    domeNumber: '721',
    belkaNumber: '354',
    bucillaNumber: '839',
    rgbColor: [103, 85, 65],
    hexColor: '675541',
}, {
    dmcNumber: '838',
    anchorNumber: '1088',
    colorName: 'Beige Brown Vy Dk',
    madeiraNumber: '1914',
    gammaNumber: '811',
    kirovaNumber: '5710, 180',
    domeNumber: '720',
    belkaNumber: '1706',
    bucillaNumber: '838',
    rgbColor: [89, 73, 55],
    hexColor: '594937',
}, {
    dmcNumber: '3072',
    anchorNumber: '847',
    colorName: 'Beaver Gray Vy Lt',
    madeiraNumber: '1805',
    gammaNumber: '3043',
    mayFlowerNumber: '2167',
    dimensionsNumber: '16005',
    domeNumber: '417',
    belkaNumber: '185',
    rgbColor: [230, 232, 232],
    hexColor: 'e6e8e8',
}, {
    dmcNumber: '648',
    anchorNumber: '900',
    colorName: 'Beaver Gray Lt',
    madeiraNumber: '1814',
    gammaNumber: '3044',
    dimensionsNumber: '18390',
    domeNumber: '413',
    belkaNumber: '50',
    bucillaNumber: '2691',
    rgbColor: [188, 180, 172],
    hexColor: 'bcb4ac',
}, {
    dmcNumber: '647',
    anchorNumber: '1040',
    colorName: 'Beaver Gray Med',
    madeiraNumber: '1813',
    gammaNumber: '152',
    kirovaNumber: '6604',
    dimensionsNumber: '18900',
    domeNumber: '412',
    belkaNumber: '445',
    bucillaNumber: '647',
    rgbColor: [176, 166, 156],
    hexColor: 'b0a69c',
}, {
    dmcNumber: '646',
    anchorNumber: '8581',
    colorName: 'Beaver Gray Dk',
    madeiraNumber: '1812',
    gammaNumber: '60',
    kirovaNumber: '6708',
    domeNumber: '411',
    belkaNumber: '317',
    bucillaNumber: '3646',
    rgbColor: [135, 125, 115],
    hexColor: '877d73',
}, {
    dmcNumber: '645',
    anchorNumber: '273',
    colorName: 'Beaver Gray Vy Dk',
    madeiraNumber: '1811',
    gammaNumber: '3229',
    kirovaNumber: '6806',
    mayFlowerNumber: '2178',
    dimensionsNumber: '18500',
    domeNumber: '410',
    belkaNumber: '773',
    rgbColor: [110, 101, 92],
    hexColor: '6e655c',
}, {
    dmcNumber: '844',
    anchorNumber: '1041',
    colorName: 'Beaver Gray Ult Dk',
    madeiraNumber: '1810',
    gammaNumber: '154',
    kirovaNumber: '6808',
    dimensionsNumber: '18501',
    domeNumber: '463',
    belkaNumber: '1659',
    bucillaNumber: '844',
    rgbColor: [72, 72, 72],
    hexColor: '484848',
}, {
    dmcNumber: '762',
    anchorNumber: '234',
    colorName: 'Pearl Gray Vy Lt',
    madeiraNumber: '1804',
    gammaNumber: '3231',
    dimensionsNumber: '6082, 18510',
    domeNumber: '408',
    belkaNumber: '1081',
    bucillaNumber: '762',
    rgbColor: [236, 236, 236],
    hexColor: 'ececec',
}, {
    dmcNumber: '415',
    anchorNumber: '398',
    colorName: 'Pearl Gray',
    gammaNumber: '3255',
    kirovaNumber: '7002, 152',
    dimensionsNumber: '6081, 18398',
    domeNumber: '406',
    belkaNumber: '287',
    bucillaNumber: '415',
    rgbColor: [211, 211, 214],
    hexColor: 'd3d3d6',
}, {
    dmcNumber: '318',
    anchorNumber: '399',
    colorName: 'Steel Gray Lt',
    madeiraNumber: '1802',
    gammaNumber: '0004, 3256, 3257',
    kirovaNumber: '7004, 153',
    dimensionsNumber: '18511',
    domeNumber: '405',
    belkaNumber: '2058',
    bucillaNumber: '6263',
    rgbColor: [171, 171, 171],
    hexColor: 'ababab',
}, {
    dmcNumber: '414',
    anchorNumber: '235',
    colorName: 'Steel Gray Dk',
    madeiraNumber: '1801',
    gammaNumber: '61',
    kirovaNumber: '7006',
    mayFlowerNumber: '2273',
    dimensionsNumber: '6080, 18513',
    domeNumber: '403',
    belkaNumber: '1596',
    bucillaNumber: '2692',
    rgbColor: [140, 140, 140],
    hexColor: '8c8c8c',
}, {
    dmcNumber: '168',
    colorName: 'Pewter Very Light',
    gammaNumber: '3045',
    belkaNumber: '26',
    rgbColor: [209, 209, 209],
    hexColor: 'd1d1d1',
}, {
    dmcNumber: '169',
    colorName: 'Pewter Light',
    gammaNumber: '3043',
    belkaNumber: '1940',
    rgbColor: [132, 132, 132],
    hexColor: '848484',
}, {
    dmcNumber: '317',
    anchorNumber: '400',
    colorName: 'Pewter Gray',
    madeiraNumber: '1714',
    gammaNumber: '3232, 3258',
    kirovaNumber: '7008',
    mayFlowerNumber: '2271',
    dimensionsNumber: '6097, 18512',
    domeNumber: '402',
    belkaNumber: '3056',
    rgbColor: [108, 108, 108],
    hexColor: '6c6c6c',
}, {
    dmcNumber: '413',
    anchorNumber: '1041, 236, 401',
    colorName: 'Pewter Gray Dark',
    madeiraNumber: '1714',
    gammaNumber: '3259, 3233',
    kirovaNumber: '7206',
    mayFlowerNumber: '2272',
    dimensionsNumber: '6079, 18514',
    domeNumber: '401',
    belkaNumber: '2097',
    bucillaNumber: '3413',
    rgbColor: [86, 86, 86],
    hexColor: '565656',
}, {
    dmcNumber: '3799',
    anchorNumber: '236, 401',
    colorName: 'Pewter Gray Vy Dk',
    madeiraNumber: '1713',
    gammaNumber: '3070, 3260',
    kirovaNumber: '7210, 155',
    dimensionsNumber: '18519',
    domeNumber: '400',
    belkaNumber: '3274',
    bucillaNumber: '3799',
    rgbColor: [66, 66, 66],
    hexColor: '424242',
}, {
    dmcNumber: '310',
    anchorNumber: '403',
    colorName: 'Black',
    madeiraNumber: '2400, black',
    gammaNumber: 'black',
    kirovaNumber: '7214, 156',
    dimensionsNumber: '6150, 18403',
    domeNumber: 'black',
    belkaNumber: '274',
    bucillaNumber: '2001',
    rgbColor: [0, 0, 0],
    hexColor: '000000',
}];

const toLabel = ([r, g, b]) => ((b << 16) | (g << 8) | r) >>> 0;

export {
    allColors,
    toLabel,
};
