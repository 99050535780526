import resampleImage from './re-sample-image';

async function loadAndResampleImage(croppedImage) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    const img = new Image();
    
    const promise = new Promise((res) => img.addEventListener('load', async function () {
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        
        height = height * (500 / width);
        width = 500;
        await resampleImage({
            canvas,
            height,
            width,
        });
        
        res({
            imageData: ctx.getImageData(0, 0, width, height).data,
            width,
            height,
        });
    }, false));
    
    img.src = croppedImage;
    
    return promise;
}

export default loadAndResampleImage;
