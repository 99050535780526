import {
    put,
    select,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects';

import {
    setResampledImage,
    setSquares,
    setColorsMap,
    setSquaresInProgress,
    setCanvasImitationMasks,
} from '../reducers/images';
import loadAndResampleImage from '../features/load-and-resample-image';
import imitationImage1 from './canvas-masks/square-mask-1.png';
import imitationImage2 from './canvas-masks/square-mask-2.png';
import imitationImage3 from './canvas-masks/square-mask-3.png';
import imitationImage4 from './canvas-masks/square-mask-4.png';

// https://www.photopea.com/
async function initCanvasImitationMask(src) {
    const img = new Image();
    const promise = new Promise((res) => {
        img.onload = function () {
           res(img);
        };
    });
    img.src = src;
    return await promise;
}

function* initSquares() {
    yield put(setSquaresInProgress(true));
    const squares = yield select(state => state.images.squares);
    const reCrop = yield select(state => state.images.reCrop);
    const reFilter = yield select(state => state.images.reFilter);
    
    if (!squares || reCrop || reFilter) {
        const croppedImage = yield select(state => state.images.croppedImage);
        const filteredImage = yield select(state => state.images.filteredImage);
        const resampledImage = yield loadAndResampleImage(filteredImage || croppedImage);
        yield put(setResampledImage(resampledImage));
        yield put(setCanvasImitationMasks([
            yield initCanvasImitationMask(imitationImage1),
            yield initCanvasImitationMask(imitationImage2),
            yield initCanvasImitationMask(imitationImage3),
            yield initCanvasImitationMask(imitationImage4),
        ]));
        yield reCalculateSquares();
    }
    yield put(setSquaresInProgress(false));
}

let worker;
function* reCalculateSquares() {
    yield put(setSquaresInProgress(true));
    const {
        imageData,
        width,
        height,
        colorOptions,
        userActions,
    } = yield select(state => ({
        imageData: state.images.resampledImage.imageData,
        width: state.images.resampledImage.width,
        height: state.images.resampledImage.height,
        colorOptions: state.images.colorOptions,
        userActions: state.images.userActions,
    }));
    
    worker && worker.terminate();
    worker = new Worker(new URL('../features/re-calculate-squares-worker.js', import.meta.url));
    worker.postMessage({
        imageData,
        width,
        height,
        colorOptions,
        userActions,
    });
    const {squares, colorsMap} = yield new Promise(res => worker.onmessage = ({data: answer}) => {
        res(answer);
    });
    yield put(setSquares(squares));
    yield put(setColorsMap(colorsMap));
    yield put(setSquaresInProgress(false));
}

function* mySaga() {
    yield takeLatest('INIT_SQUARES', initSquares);
    yield takeEvery('CALCULATE_SQUARES', reCalculateSquares);
}

export default mySaga;
