import './ArrowButton.scss';

function arrowButton({
    type,
    click,
}) {
    return <span onClick={click} className={`arrow-${type}`}/>;
}

export default arrowButton;
