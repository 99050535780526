import VPTree from 'mnemonist/vp-tree';
import {curry} from 'ramda';
import {distance} from 'image-q';
import {
    allColors,
    toLabel,
} from './colors';

const getNearestColor = curry((vpTree, color) => {
    const [{item: closestColor}] = vpTree.nearestNeighbors(1, color);
    
    return closestColor;
});

const getColorDetails = (colors) => {
    const colorsMap = {};
    
    for (const color of colors)
        colorsMap[toLabel(color.rgbColor)] = color;
    
    return (color) => colorsMap[toLabel(color)];
};

const createNewPaletteWithoutColor = (rgbColors, colors, getDistance) => ([r, g, b]) => {
    const newRgbColors = rgbColors.filter(([r1, g1, b1]) => r !== r1 && g !== g1 && b !== b1);
    const newVpTree = VPTree.from(newRgbColors, getDistance);
    
    return {
        rgbColors: newRgbColors,
        getColorDetails: getColorDetails(colors),
        withoutColor: createNewPaletteWithoutColor(newRgbColors, colors, getDistance),
        getNearestColor: getNearestColor(newVpTree),
    }
};

const isGray = ([r, g, b]) => Math.abs(r - g) < 10 && Math.abs(g - b) < 10 && Math.abs(b - r) < 10;

const init = (options) => {
    const {
        paletteColors,
        colorMap = 'dmc',
        getDistance,
        colorSchema,
    } = options;
    
    let colors;
    let rgbColors;
    
    if (paletteColors) {
        rgbColors = paletteColors;
    } else {
        colors = allColors
            .filter((color) => Boolean(color[`${colorMap}Number`]));
        
        rgbColors = colors.map(({rgbColor}) => rgbColor);
    }
    
    if (colorSchema === 'shadesOfGray')
        rgbColors = rgbColors.filter(isGray);
    
    const vpTree = VPTree.from(rgbColors, getDistance);
    
    return {
        rgbColors,
        ...colors && {
            getColorDetails: getColorDetails(colors),
            withoutColor: createNewPaletteWithoutColor(rgbColors, colors, getDistance),
        },
        getNearestColor: getNearestColor(vpTree),
    };
};

function getDistanceCalculator(colorDistanceFormula) {
    switch (colorDistanceFormula) {
        case 'cie94-graphic-arts':
            return new distance.CIE94GraphicArts();
        case 'cie94-textiles':
            return new distance.CIE94Textiles();
        case 'ciede2000':
            return new distance.CIEDE2000();
        case 'color-metric':
            return new distance.CMetric();
        case 'euclidean':
            return new distance.Euclidean();
        case 'euclidean-bt709':
            return new distance.EuclideanBT709();
        case 'euclidean-bt709-noalpha':
            return new distance.EuclideanBT709NoAlpha();
        case 'manhattan':
            return new distance.Manhattan();
        case 'manhattan-bt709':
            return new distance.ManhattanBT709();
        case 'manhattan-nommyde':
            return new distance.ManhattanNommyde();
        case 'pngquant':
            return new distance.PNGQuant();
        default:
            throw new Error(`Unknown colorDistanceFormula ${colorDistanceFormula}`);
    }
}

function buildRgbDistanceCalculator(colorDistanceFormula) {
    const distanceCalculator = getDistanceCalculator(colorDistanceFormula);
    
    return (a, b) => distanceCalculator.calculateRaw(a[0], a[1], a[2], 1, b[0], b[1], b[2], 1);
}

export {
    init,
    toLabel,
    buildRgbDistanceCalculator,
};
