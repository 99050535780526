import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import stepReducer from '../reducers/steps';
import imageReducer from '../reducers/images';
import historyReducer from '../reducers/history';
import mySaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const historyActions = [
    'steps/setStep',
    'steps/stepForward',
    'steps/stepBack',
    'images/setColorOptions',
    'images/setImitateCanvas',
    'images/setCanvasDensity',
    'images/removeColor',
    'images/undoRemoveColor',
    'images/setColoOptionChar',
];

const saveHistory = store => next => action => {
    if (historyActions.includes(action.type) && !action.history)
        store.dispatch({
            type: 'history/setAction',
            payload: {
                action,
                currentState: store.getState(),
            },
        });
    
    return next(action);
};

const asyncDispatchMiddleware = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];
    
    function flushQueue() {
        actionQueue.forEach(a => store.dispatch(a)); // flush queue
        actionQueue = [];
    }
    
    function asyncDispatch(asyncAction) {
        actionQueue = actionQueue.concat([asyncAction]);
        
        if (syncActivityFinished) {
            flushQueue();
        }
    }
    
    const actionWithAsyncDispatch =
        Object.assign({}, action, {asyncDispatch});
    
    next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();
};

export default configureStore({
    reducer: {
        steps: stepReducer,
        images: imageReducer,
        history: historyReducer,
    },
    middleware: [sagaMiddleware, asyncDispatchMiddleware, saveHistory],
});

sagaMiddleware.run(mySaga);
