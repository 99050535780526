import Hermite from '@ns0m/hermite-resize';

const hermite = new Hermite();


async function resampleImageWithHermiteFilter(canvas, width, height, resize) {
    return new Promise((res) => hermite.resample(canvas, width, height, resize, res));
}

export default resampleImageWithHermiteFilter;
