import {createSlice} from '@reduxjs/toolkit';

export const stepsSlice = createSlice({
    name: 'steps',
    initialState: {
        activeStep: 'loadImage',
        steps: [{
            label: 'Load Image',
            key: 'loadImage',
        }, {
            label: 'Crop Image',
            key: 'cropImage',
            disabled: true,
        }, {
            label: 'Apply Filters',
            key: 'applyFilters',
            disabled: true,
        }, {
            label: 'Setup Colors',
            key: 'setupColors',
            disabled: true,
        }, {
            label: 'Generate Schema',
            key: 'generateSchema',
            disabled: true,
        }],
    },
    reducers: {
        setStep: (state, action) => {
            state.activeStep = action.payload;
            if (action.payload === 'cropImage') {
                state.steps = state.steps.map(step => Object.assign(step, {
                    disabled: false,
                }));
            }
        },
        stepForward: (state) => {
            const stepIndex = state.steps.findIndex(({key}) => key === state.activeStep);
            
            state.activeStep = state.steps[stepIndex + 1].key;
        },
        stepBack: (state) => {
            const stepIndex = state.steps.findIndex(({key}) => key === state.activeStep);
            
            state.activeStep = state.steps[stepIndex - 1].key;
        },
    },
});

export const {
    setStep,
    stepForward,
    stepBack,
} = stepsSlice.actions;

export default stepsSlice.reducer;
