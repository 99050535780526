import {Component} from 'react';
import './Stepper.css';
import {setStep} from '../reducers/steps';
import {connect} from 'react-redux';

class StepperComponent extends Component {
    onClick(key, disabled) {
        if (disabled)
            return;
        
        this.props.setStep(key);
    }
    
    render() {
        return (
            <div className="stepper-wrapper fixed-bottom">
                {this.props.steps.map(({
                    label,
                    key,
                    disabled,
                }, index) => (
                    <div className={`stepper-item completed ${key === this.props.activeStep
                        ? 'active'
                        : ''} ${disabled
                        ? 'disabled'
                        : ''}`} onClick={this.onClick.bind(this, key, disabled)}
                         key={key}>
                        <div className="needle"/>
                        <div className="step-counter">{/*{index + 1}*/}</div>
                        <div className="step-name">{label}</div>
                    </div>
                ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activeStep: state.steps.activeStep,
    steps: state.steps.steps,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setStep: (step) => dispatch(setStep(step)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepperComponent);
